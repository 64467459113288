










import { Component, Vue } from 'vue-property-decorator'

import { mainLogos } from '@/data/main-logos'
import { devLogos } from '@/data/dev-logos'
import AutoSuggest from '@/components/common/AutoSuggest.vue'

const allApps = [
  ...mainLogos,
  ...devLogos,
]

@Component({
  components: {
    AutoSuggest,
  },
})
export default class ExpertiseSelector extends Vue {
  public filterItems(search: string): any {
    if (!search) {
      return []
    }

    const re = new RegExp(search.replace(/[^a-z0-9\s]+/, ''), 'i')
    return allApps.filter((item) => item.name.match(re))
  }
}
