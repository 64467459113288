



























import { Component } from "vue-property-decorator";
import ItemMenu from "@/components/common/ItemMenu.vue";
import { Contact, CompanyUserProfile } from "@/models";
import View from "../../View";
import MemberAvatar from "../team/MemberAvatar.vue";

@Component({
  props: {
    contact: {
      type: Object,
      required: true,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItemMenu,
    MemberAvatar,
  },
})
export default class GridContact extends View {
  public contact: Contact;

  get extraMenuItems() {
    return this.isAdmin ? [{ action: "remove", label: "Remove" }] : [];
  }

  get profile() {
    if (this.contact.profileId) {
      return CompanyUserProfile.find(this.contact.profileId);
    }
    return null;
  }

  public handleAction(cmdType: string) {
    switch (cmdType) {
      case "remove":
        Contact.api().$delete({
          params: {
            id: this.contact.contactId,
          },
        });
        break;
      case "edit":
        this.$router.push({
          name: "contact-edit",
          params: { id: `${this.contact.contactId}` },
        });
        break;
    }
  }
}
