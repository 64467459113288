







import { Component } from 'vue-property-decorator'
import View from '../../View'

@Component({})
export default class AddToSlack extends View {
  get loading() {
    return this.$store.state.slack.loading
  }

  public async connect() {
    const data = await this.$store.dispatch('slack/install')
    if (data && data.url) {
      window.location = data.url
    }
    // this.$emit('connected')
  }
}
