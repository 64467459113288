import { Vue } from 'vue-property-decorator'
import { Setting, User, CompanyUserProfile, TextBlock, Company, Visibility, TextBlockPageRef } from './models'
import authService from './services/AuthServices'

export default class View extends Vue {
  get isLogged() {
    return authService.isAuthenticated()
  }

  get isAdmin() {
    return this.$store.getters['auth/isAdmin']
  }

  get isCandidate() {
    return this.$store.getters['auth/isCandidate']
  }

  get flags() {
    return this.$store.state.ui.flags
  }

  get modal() {
    return this.$store.state.ui.modal
  }

  get mobilePreview() {
    return this.$store.state.ui.mobilePreview
  }

  get helpbox() {
    return this.$store.state.ui.helpbox
  }

  get selectedIndex() {
    return this.$store.state.ui.selectedIndex
  }

  get teamAdd() {
    return this.$store.state.ui.teamAdd
  }

  get user() {
    return User.find(this.$store.state.auth.userId) as User
  }

  get isInternal() {
    const { user } = this
    const isDebug = this.$store.state.ui.flags.debug

    return isDebug && user && user.email.match(/uelco\.co$/)
  }

  get ownProfile() {
    return CompanyUserProfile.find(this.$store.state.auth.profileId)
  }

  get roleSwitch() {
    return this.$store.state.ui.roleSwitch
  }

  get company(): Company {
    return Company.query()
      .with('textBlocks')
      .with('contacts')
      .first() as Company
  }

  public textBlocksByRef(pageRef: TextBlockPageRef) {
    const query = TextBlock.query()
      .where('pageRef', pageRef)
      .where('isDeleted', false)
      .orderBy('order', 'desc')

    if (this.roleSwitch !== 'admin') {
      query.where('visibility', (vis: Visibility) => vis.indexOf(this.roleSwitch) !== -1)
    }

    return query.get()
  }

  public goBack(forceUrl?: string) {
    if (forceUrl) {
      return this.$router.push(forceUrl)
    }

    if (window.history.length > 2) {
      this.$router.back()
    } else {
      const backUrl = this.$route.meta && this.$route.meta.backRoute
      this.$router.push(backUrl || '/')
    }
  }

  public getSetting(key: string): Setting {
    return Setting.query().where('key', key).first() as Setting
  }

  public getSettingValue(key: string, defaultValue: any = '') {
    const setting = this.getSetting(key)
    return setting ? setting.value : defaultValue
  }

  public async setSettingValue(key: string, value: any = '') {
    await Setting.api().$update({
      params: {
        key,
        companyId: this.company.companyId,
      },
      data: {
        value,
      },
    })
  }
}
