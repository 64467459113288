


















































import { Component, Prop } from 'vue-property-decorator'
import RoleSwitch from '@/components/common/RoleSwitch.vue'
import MenuFooter from '@/components/preview/MenuFooter.vue'
import LogoHeader from '@/components/preview/LogoHeader.vue'
import AppHome from '@/components/preview/AppHome.vue'
import AppTeam from '@/components/preview/AppTeam.vue'
import AppCompany from '@/components/preview/AppCompany.vue'
import AppDocs from '@/components/preview/AppDocs.vue'
import AppApps from '@/components/preview/AppApps.vue'
import AppTodo from '@/components/preview/AppTodo.vue'

import MobileAppHowto from '@/components/preview/MobileAppHowto.vue'
import MobileMemberPage from '@/components/preview/MobileMemberPage.vue'

import InvitePreview from '@/components/preview/InvitePreview.vue'

import View from '@/View'
import { CompanyUserProfile } from '@/models'

@Component({
  components: {
    MenuFooter,
    RoleSwitch,
    AppApps,
    AppDocs,
    AppCompany,
    AppHome,
    AppTeam,
    AppTodo,
    MobileAppHowto,
    MobileMemberPage,
    LogoHeader,
    InvitePreview,
  },
})
export default class MobilePreview extends View {
  @Prop({ type: Boolean, default: true })
  public showRoleSwitch!: boolean

  @Prop({ type: Boolean, default: true })
  public showToggle!: boolean

  @Prop({ type: Boolean, default: true })
  public showMenuFooter!: boolean

  @Prop({ type: Boolean, default: true })
  public showSettingsLink!: boolean

  get mobilePreviewPage() {
    return this.$store.state.ui.mobilePreviewPage
  }

  get profile() {
    const userId = this.$store.state.ui.mobilePreviewProfile
    return userId && CompanyUserProfile.find(userId)
  }

  get linkColor() {
    return this.getSetting('link-color')
  }

  public togglePreview() {
    this.$store.commit('ui/toggleMobilePreview', !this.mobilePreview)
  }

  public changePage(newPage: string) {
    this.$store.commit('ui/setMobilePreviewPage', newPage)
  }

  public mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'ui/setFocusedSection') {
        const { type, id } = mutation.payload
        if (type && id) {
          const elm = document.getElementById(`${type}-${id}`)
          const previewContainer = document.getElementById('previewContainer')
          if (elm && previewContainer) {
            previewContainer.scrollTop = elm.offsetTop - 10
          }
        }
      }
    })
  }
}
