import AppLayout from '@/components/layout/App.vue'
import EditLayout from '@/components/layout/Edit.vue'
import MemberAvatar from '@/components/team/MemberAvatar.vue'
import XStyle from '@/components/common/XStyle.vue'

// Register common components that should be available everywhere
export default (Vue: any) => {
  Vue.component('AppLayout', AppLayout)
  Vue.component('EditLayout', EditLayout)
  Vue.component('MemberAvatar', MemberAvatar)
  Vue.component('XStyle', XStyle)
}
