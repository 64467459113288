






import { Component } from 'vue-property-decorator'
import View from '@/View'
import templateVars from '@/filters/TemplateVars'

@Component
export default class InvitePreview extends View {
  get profile() {
    const { mobilePreviewParams = {} } = this.$store.state.ui
    return mobilePreviewParams.profile
  }

  get template() {
    const { mobilePreviewParams = {} } = this.$store.state.ui
    return mobilePreviewParams.template
  }

  get parsedTemplate() {
    return templateVars(this.template.body, {
      profile: this.profile,
    })
  }
}
