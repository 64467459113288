








import { Component } from 'vue-property-decorator'
import MobileGridMember from '@/components/preview/MobileGridMember.vue'
import View from '../../View'
import { CompanyUserProfile } from '@/models'

@Component({
  components: {
    MobileGridMember,
  },
})
export default class PreviewAppTeam extends View {
  get inviteeProfile(): CompanyUserProfile | null {
    const profile = this.$store.state.ui.mobilePreviewProfile
    if (typeof profile === 'string') {
      return CompanyUserProfile.find(profile)
    }
    return null
  }

  get members() {
    const { inviteeProfile } = this

    const query = CompanyUserProfile.query()
      .where('isActive', true)

    // candidates should only see themselves and not other candidates
    if (inviteeProfile && inviteeProfile.role === 'candidate') {
      query.where((row: CompanyUserProfile) =>
        row.role !== 'candidate' || row.companyUserProfileId === inviteeProfile.companyUserProfileId)
    }

    return query.all()
  }
}
