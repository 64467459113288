












import { Component } from 'vue-property-decorator'
import View from '../View'

@Component
export default class LoginCheck extends View {

  get authError() {
    return this.$store.getters['auth/authError']
  }

  public created() {
    this.checkToken()
  }

  public async checkToken() {
    const redirectTo = localStorage && localStorage.getItem('redirect')
    const { query } = this.$route
    await this.$store.dispatch('auth/loginCheck', query)

    if (this.isLogged) {
      this.$store.commit('ui/setInitialLoadDone', false)
      this.$router.push(redirectTo || '/welcome')
    }
  }
}
