
import { Component, Vue } from 'vue-property-decorator'

const KEY_ESC = 27

const SHORTCUTS_ENABLED = true

@Component({})
export default class Shortcuts extends Vue {
  public keysCombo: string

  public goTo(url: string) {
    this.keysCombo = ''
    this.$router.push(url)
  }

  public mounted() {
    if (SHORTCUTS_ENABLED) {
      document.addEventListener('keypress', this.handleKeypress)
      document.addEventListener('keyup', this.handleKeyup)
    }
  }

  public render() {
    return ''
  }

  public beforeDestroy() {
    document.removeEventListener('keypress', this.handleKeypress)
    document.removeEventListener('keyup', this.handleKeyup)
  }

  get ui() {
    return this.$store.state.ui
  }

  get urlToKeyMap(): { [key: string]: string } {
    return {
      KeyC: '/company',
      KeyM: '/members',
      KeyD: '/documents',
      KeyA: '/apps',
      KeyP: '/profile',
      KeyS: '/settings',
    }
  }

  public handleKeyup(event: KeyboardEvent) {
    if (this.isEdit()) {
      return
    }

    if (event.keyCode === KEY_ESC) {
      this.$store.commit('ui/toggleModal', false)
      this.$store.commit('ui/toggleHelpbox', false)
    }
  }

  public handleKeypress(event: KeyboardEvent) {
    if (this.isEdit()) {
      return
    }

    // GoTo shortcuts
    if (this.keysCombo === 'KeyG') {
      const url = this.urlToKeyMap[event.code]
      if (url) {
        return this.goTo(this.urlToKeyMap[event.code])
      }
    }

    switch (event.code) {
    case 'KeyN':
    case 'KeyC':
      this.$store.commit('ui/toggleModal', !this.ui.modal)
      break

      // Disable hiding of mobile preview for now
      // case 'KeyP':
      // case 'KeyM':
      //   this.$store.commit('ui/toggleMobilePreview', !this.ui.mobilePreview)
      //   break

    case 'KeyH':
    case 'Slash':
      this.$store.commit('ui/toggleHelpbox', !this.ui.helpbox)
      break

    case 'KeyJ':
      this.$store.commit('ui/setSelectedIndex', this.ui.selectedIndex + 1)
      break

    case 'KeyK':
      this.$store.commit(
        'ui/setSelectedIndex',
        Math.max(-1, this.ui.selectedIndex - 1),
      )
      break

    case 'KeyG':
    case 'KeyB':
      this.keysCombo = event.code
      break

    case 'KeyD':
      if (this.keysCombo === 'KeyB') {
        this.$store.commit('ui/toggleFlag', 'debug')
      }
      break

    case 'Digit1':
    case 'Digit2':
    case 'Digit3':
      if (event.altKey) {
        const keyToRoleMap = {
          Digit1: 'candidate',
          Digit2: 'hire',
          Digit3: 'member',
        }
        this.$store.commit('ui/setRoleSwitch', keyToRoleMap[event.code])
      }
      break

    default:
      this.keysCombo = ''
      break
    }
  }

  private isEdit() {
    const elm = document && document.activeElement
    const contentEditable = elm && elm.attributes.getNamedItem('contenteditable')
    return (
      elm && (
        elm.nodeName === 'INPUT' ||
        elm.nodeName === 'TEXTAREA' ||
        (contentEditable && contentEditable.value === 'true')
      )
    )
  }
}
