








import { Component } from 'vue-property-decorator'
import MobileGridDocument from '@/components/preview/MobileGridDocument.vue'
import View from '../../View'
import { Document, Visibility } from '@/models'

@Component({
  components: {
    MobileGridDocument,
  },
})
export default class PreviewAppDocs extends View {

  get documents() {
    const query = Document.query()
      .orderBy('name', 'desc')

    if (this.roleSwitch) {
      query.where('visibility', (vis: Visibility) => vis.indexOf(this.roleSwitch) !== -1)
    }

    query.where('isDeleted', false)

    return query.get()
  }
}
