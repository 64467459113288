















































import { Component } from 'vue-property-decorator'
import View from '@/View'
import MemberAvatar from '../team/MemberAvatar.vue'

@Component({
  components: {
    MemberAvatar,
  }
})
export default class MobileMemberPage extends View {
  get profile() {
    const { mobilePreviewParams = {} } = this.$store.state.ui
    return mobilePreviewParams.member
  }

  public closeProfile() {
    this.$store.commit('ui/setMobilePreviewPage', 'AppTeam', {})
  }
}
