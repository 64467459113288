







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class FieldErrors extends Vue {
  @Prop()
  public field!: string;

  @Prop()
  public errors!: any;

  get error() {
    return this.errors && this.errors && this.errors[this.field]
  }
}
