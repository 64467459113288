export default [
  // Communication
  {
    priority: 1,
    name: 'Slack',
    category: 'Communication',
    url: 'https://slack.com/',
    companyLink: 'https://${company}.slack.com',
    logoUrl: 'slack.svg',
    logoIcon: 'react-icons/lib/fa/slack',
    description: 'You will be invited',
  },
  {
    priority: 1,
    name: 'Google Suite',
    category: 'Communication',
    url: 'https://gsuite.google.com/together/',
    companyLink: '?',
    logoUrl: '',
    logoIcon: 'react-icons/lib/fa/google',
    description: 'You will have access to Mail, Calendar, Shared drive',
  },
  {
    priority: 0,
    name: 'Zoom',
    category: 'Communication',
    url: 'https://zoom.us/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: 'Video Conferences',
  },
  {
    priority: 0,
    name: 'Appear.in',
    category: 'Communication',
    url: 'https://appear.in/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: 'Video Conferences',
  },
  // Project management
  {
    priority: 1,
    name: 'Trello',
    category: 'Project management',
    url: 'https://trello.com/',
    companyLink: 'https://trello.com/${company}',
    logoUrl: 'trello.svg',
    logoIcon: 'react-icons/lib/fa/trello',
    description: 'We manage our kanban boards here',
  },

  {
    priority: 0,
    name: 'Asana',
    category: 'Project management',
    url: 'https://asana.com/',
    companyLink: '',
    logoUrl: 'asana.svg',
    logoIcon: '',
    description: '',
  },


  {
    priority: 1,
    name: 'JIRA',
    category: 'Project management',
    url: 'https://jira.atlassian.com/',
    companyLink: 'https://jira.${companyDomain}',
    logoUrl: 'jira.svg',
    logoIcon: '',
    description: '',
  },
  {
    priority: 0,
    name: 'Basecamp',
    category: 'Project management',
    url: 'https://basecamp.com/',
    companyLink: '?',
    logoUrl: 'invision.svg',
    logoIcon: 'basecamp.svg',
    description: 'Project management',
  },
  {
    priority: 0,
    name: 'Wrike',
    category: 'Project management',
    url: 'https://www.wrike.com/',
    companyLink: '',
    logoUrl: 'wrike.svg',
    logoIcon: '',
    description: '',
  },
  // Design
  {
    priority: 1,
    name: 'InVision',
    category: 'Design',
    url: 'https://invision.com/',
    companyLink: '',
    logoUrl: 'invision.svg',
    logoIcon: '',
    description: '',
  },



  // Development
  {
    priority: 0,
    name: 'Gitlab',
    category: 'Dev tools',
    url: 'https://gitlab.com/',
    companyLink: '',
    logoUrl: 'gitlab.svg',
    logoIcon: '',
    description: '',
  },
  {
    priority: 1,
    name: 'Github',
    category: 'Development',
    url: 'https://github.com/',
    companyLink: '',
    logoUrl: 'github.svg',
    logoIcon: '',
    description: '',
  },
  // Security
  {
    priority: 1,
    name: 'LastPass',
    category: 'Security',
    url: 'https://www.lastpass.com/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: 'We share all our passwords securely with LastPass',
  },
  {
    priority: 1,
    name: '1Password',
    category: 'Security',
    url: 'https://1password.com/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: '',
  },
  {
    priority: 0,
    name: 'RescueTime',
    category: 'Productivity',
    url: 'https://www.rescuetime.com/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: 'Time tracking',
  },

  {
    priority: 0,
    name: 'Zeplin',
    category: 'Dev tools, UX/UI',
    url: 'https://zeplin.io/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: '',
  },
  {
    priority: 0,
    name: 'Codeship',
    category: 'Dev tools',
    url: 'https://codeship.com/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: 'CI/CD',
  },
  {
    priority: 0,
    name: 'Jenkins',
    category: 'Dev tools, Automation',
    url: 'https://jenkins.io/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: '',
  },
  // Marketing
  {
    priority: 0,
    name: 'Adwords',
    category: 'Marketing',
    url: 'https://adwords.google.com/home',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: '',
  },
  {
    priority: 0,
    name: 'Google Analytics',
    category: 'Analytics',
    url: 'https://analytics.google.com/analytics/web/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: '',
  },
  {
    priority: 1,
    name: 'Dropbox',
    category: 'Communication',
    url: 'https://dropbox.com/',
    companyLink: '',
    logoUrl: 'dropbox.svg',
    logoIcon: '',
    description: '',
  },
  {
    priority: 0,
    name: 'Evernote',
    category: 'Communication, Project management',
    url: 'https://evernote.com/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: '',
  },
  {
    priority: 1,
    name: 'Salesforce',
    category: 'CRM',
    url: 'https://www.salesforce.com/',
    companyLink: '',
    logoUrl: '',
    logoIcon: '',
    description: '',
  },
  {
    priority: 0,
    name: 'MailChimp',
    category: 'Marketing',
    url: 'https://mailchimp.com/',
    companyLink: '',
    logoUrl: 'mailchimp.svg',
    logoIcon: '',
    description: '',
  },
  {
    priority: 0,
    name: 'Abstract',
    category: 'Design',
    url: 'https://www.abstract.com/',
    companyLink: '',
    logoUrl: 'abstract.svg',
    logoIcon: '',
    description: 'Version control for design files',
  },
  {
    priority: 0,
    name: 'Sketch',
    category: 'Design',
    url: 'https://www.sketchapp.com/',
    companyLink: '',
    logoUrl: 'sketch.svg',
    logoIcon: '',
    description: 'Product design tool',
  },

  // {
  //   priority: 0,
  //   name: 'Zendesk',
  //   category: 'Customer Support',
  //   url: 'https://www.zendesk.com/',
  //   companyLink: '',
  //   logoUrl: '',
  //   logoIcon: '',
  //   description: '',
  // },
  // {
  //   priority: 0,
  //   name: 'Freshdesk',
  //   category: 'Customer Support',
  //   url: 'https://freshdesk.com/',
  //   companyLink: '',
  //   logoUrl: '',
  //   logoIcon: '',
  //   description: '',
  // },
  // {
  //   priority: 0,
  //   name: 'Zenefits',
  //   category: 'HR',
  //   url: 'https://www.zenefits.com/',
  //   companyLink: '',
  //   logoUrl: '',
  //   logoIcon: '',
  //   description: '',
  // },
  // {
  //   priority: 0,
  //   name: 'Intercom',
  //   category: 'Customer Support',
  //   url: 'https://www.intercom.com/',
  //   companyLink: '',
  //   logoUrl: '',
  //   logoIcon: '',
  //   description: '',
  // },
  // {
  //   priority: 0,
  //   name: 'BambooHR',
  //   category: 'HR',
  //   url: 'https://www.bamboohr.com/',
  //   companyLink: '',
  //   logoUrl: '',
  //   logoIcon: '',
  //   description: '',
  // },
  // {
  //   priority: 0,
  //   name: 'Talent.io',
  //   category: 'HR',
  //   url: 'https://www.talent.io/',
  //   companyLink: '',
  //   logoUrl: '',
  //   logoIcon: '',
  //   description: '',
  // },
  // {
  //   priority: 0,
  //   name: 'Namely',
  //   category: 'HR',
  //   url: 'https://www.namely.com/',
  //   companyLink: '',
  //   logoUrl: '',
  //   logoIcon: '',
  //   description: '',
  // },
  // {
  //   priority: 0,
  //   name: 'Skype',
  //   category: 'Communication',
  //   url: '',
  //   companyLink: '',
  //   logoUrl: '',
  //   logoIcon: '',
  //   description: '',
  // },
  // {
  //   priority: 0,
  //   name: 'Drift',
  //   category: 'Customer Support',
  //   url: 'https://www.drift.com/',
  //   companyLink: '',
  //   logoUrl: '',
  //   logoIcon: '',
  //   description: '',
  // },
  // {
  //   priority: 0,
  //   name: 'Highrise CRM',
  //   category: 'CRM',
  //   url: 'https://highrisehq.com/',
  //   companyLink: '',
  //   logoUrl: '',
  //   logoIcon: '',
  //   description: '',
  // },
]
