










































import { Component } from 'vue-property-decorator'
import AdminActions from '@/components/common/AdminActions.vue'
import FileUpload from '@/components/document/FileUpload.vue'
import FieldErrors from '@/components/form/FieldErrors.vue'
import { Document, Role } from '../../models'
import View from '../../View'
import SubscriptionLimit from '../../components/common/SubscriptionLimit.vue'
import { ApiError } from '../../api'

@Component({
  components: {
    AdminActions,
    FileUpload,
    FieldErrors,
    SubscriptionLimit,
  },
})
export default class DocumentEdit extends View {
  get documentId() {
    return this.$route.params.id
  }

  get roles() {
    return {
      candidate: 'Candidates',
      hire: 'New hires',
      member: 'Existing members',
    }
  }

  public document: Document
  public errors: any

  public data() {
    return {
      document: new Document(),
      errors: {},
    }
  }

  public async created() {
    const rec = Document.find(this.documentId) as Document
    if (this.documentId) {
      this.document = new Document(rec || {})
    }

    this.$store.commit('team/setSubscriptionLimit', {
      resource: 'docs',
      value: false
    })
  }

  public async handleSave() {
    try {
      await this.document.$persist()
      this.goBack('/documents')
    } catch (err) {
      if (err instanceof ApiError && err.statusCode === 402) {
        this.$store.commit('team/setSubscriptionLimit', {
          resource: 'docs',
          value: true
        })
      }
      this.errors = err?.details?.errors
    }
  }

  public isVisibleTo(role: Role) {
    return this.document.visibility.indexOf(role) !== -1
  }

  public toggleVisibility(role: Role) {
    const visibility = [...this.document.visibility]
    const idx = visibility.indexOf(role)
    if (idx > -1) {
      visibility.splice(idx, 1)
    } else {
      visibility.push(role)
    }
    this.document.visibility = visibility
  }

  public handleUpload(url: URL, file: File) {
    this.document.name = Document.humanizeName(file.name)
    this.document.mimeType = file.type
    this.document.fileSize = file.size
    this.document.link = `${url.origin}${url.pathname}`
  }
}
