




















import { Component } from "vue-property-decorator";
import { Contact } from "@/models/contact.ts";
import View from "../../View";

@Component({
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
})
export default class MobileGridContact extends View {
  public contact: Contact;
}
