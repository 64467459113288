

























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class EntityValueDebug extends Vue {
  @Prop()
  public value!: any

  @Prop({ type: Number, default: 140})
  public trimLength!: number

  public isArray(value: any) {
    return Array.isArray(value)
  }

  public isObject(value: any) {
    return value !== null && typeof value === 'object' && Object.keys(value).length > 0
  }

  public someFields(record: any) {
    const fields: any[] = []
    Object.keys(record).forEach((key: string) => {
      if (key.charAt(0) !== '$' &&
        typeof key === 'string' &&
        record[key]
      ) {
        fields.push({ key, value: record[key] })
      }
    })
    return fields
  }
}
