import faker from '@/faker'
import { Visibility, rolesList } from './types'
import { BaseModel } from './base-model'

export class Document extends BaseModel {
  public static entity = 'documents'

  public static primaryKey = 'documentId'

  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/documents')
      },
      $get ({ params }: any) {
        return (this as any).get(`/documents/${params.id}`)
      },
      $create ({ data }: any) {
        return (this as any).post('/documents', data)
      },
      $delete ({ params }: any) {
        return (this as any).delete(`/documents/${params.id}`, {
          delete: params.id
        })
      },
      $update ({ params, data }: any) {
        return (this as any).put(`/documents/${params.id}`, data)
      }
    }
  }

  public static fields() {
    return {
      documentId: this.attr(undefined),
      companyId: this.attr(''),
      name: this.attr(''),
      link: this.attr(''),
      fileSize: this.attr(''),
      type: this.attr('document'), // todo: is it needed?
      mimeType: this.attr(''),
      createdAt: this.attr(null),
      visibility: this.attr([]),

      isDeleted: this.attr(false),
    }
  }

  public static humanizeName(name: string) {
    const ucFirst = (str: string) => `${str.charAt(0).toUpperCase()}${str.substr(1)}`
    const [title] = name.split('.')
    return title.split(/[-_]/).map(ucFirst).join(' ')
  }

  public documentId: string
  public companyId: string
  public name: string
  public link: string
  public fileSize: number
  public type: string
  public mimeType: string
  public createdAt: Date
  public visibility: Visibility

  public isDeleted: boolean

  public isDownloadable() {
    const uplRe = /\/uelco-uploads\//i
    const docRe = /\.(pdf|xls|doc|txt)$/
    return this.link.match(uplRe) || this.link.match(docRe)
  }
}

export const genPdfDocument = (companyId: string): Document =>
  new Document({
    companyId,
    name: Document.humanizeName(faker.system.commonFileName('pdf', 'application/x-pdf')),
    mimeType: 'application/x-pdf',
    link: 'https://www.uelco.co/assets/images/document.pdf',
    fileSize: `${Number(faker.random.number()).toLocaleString()} Kb`,
    createdAt: faker.date.recent(),
    visibility: [...rolesList],
  })

export const genImageDocument = (): Document =>
  new Document({
    name: faker.system.commonFileName('jpg', 'image/jpeg'),
    mimeType: 'image/jpeg',
    link: 'https://placeimg.com/640/480/any',
    fileSize: `${Number(faker.random.number()).toLocaleString()} Kb`,
    createdAt: faker.date.recent(),
    visibility: [...rolesList],
  })
