import faker from '@/faker'
import { Visibility, Role, rolesList } from '.'
import { BaseModel } from './base-model'
import { TextBlockPageRef } from './types'

export class TextBlock extends BaseModel {
  public static entity = 'textBlocks'

  public static primaryKey = 'textBlockId'

  public static methodConf = {
    http: {
      url: '/text-blocks',
    },
  }

  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/text-blocks')
      },
      $get ({ params }: any) {
        return (this as any).get(`/text-blocks/${params.id}`)
      },
      $create ({ data }: any) {
        return (this as any).post('/text-blocks', data)
      },
      $delete ({ params }: any) {
        return (this as any).delete(`/text-blocks/${params.id}`, {
          delete: params.id
        })
      },
      $update ({ params, data }: any) {
        return (this as any).put(`/text-blocks/${params.id}`, data)
      }
    }
  }

  public static fields() {
    return {
      textBlockId: this.attr(undefined),
      companyId: this.attr(''),
      title: this.attr(''),
      description: this.attr(''),
      image: this.attr(''),
      hasImage: this.attr(false),
      visibility: this.attr(''),
      order: this.attr(0),
      pageRef: this.attr('company'),
      isDeleted: this.attr(false),
    }
  }


  public static moveUp(item: TextBlock, role: Role, pageRef: TextBlockPageRef = 'company') {
    return this.swapNearest(
      item,
      role,
      pageRef,
      (order: number) => order > item.order,
      'asc',
    )
  }

  public static moveDown(item: TextBlock, role: Role, pageRef: TextBlockPageRef = 'company') {
    return this.swapNearest(
      item,
      role,
      pageRef,
      (order: number) => order < item.order,
      'desc',
    )
  }

  public static clone(textBlock: TextBlock) {
    return new TextBlock({
      companyId: textBlock.companyId,
      title: textBlock.title,
      description: textBlock.description,
      order: textBlock.order,
      image: textBlock.image,
      hasImage: textBlock.hasImage,
      pageRef: textBlock.pageRef,
      visibility: [...textBlock.visibility],
      isDeleted: false,
    })
  }

  public static async allUp(role: Role, pageRef: TextBlockPageRef, startFrom: number) {
    const blocksToUpdate = await TextBlock.query()
      .where('pageRef', pageRef)
      .where('isDeleted', false)
      .where('order', (value: number) => value >= startFrom)
      .where('visibility', (vis: Visibility) => vis.indexOf(role) !== -1)
      .all()

    await Promise.all(blocksToUpdate.map(async (textBlock) => {
      textBlock.order += 1
      await textBlock.$persist()
    }))
  }

  public static async allDown(role: Role, pageRef: TextBlockPageRef, startFrom: number) {
    const blocksToUpdate = await TextBlock.query()
      .where('pageRef', pageRef)
      .where('isDeleted', false)
      .where('order', (value: number) => value <= startFrom)
      .where('visibility', (vis: Visibility) => vis.indexOf(role) !== -1)
      .all()

    await Promise.all(blocksToUpdate.map(async (textBlock) => {
      textBlock.order -= 1
      await textBlock.$persist()
    }))
  }

  private static async swapNearest(
    item: TextBlock,
    role: Role,
    pageRef: TextBlockPageRef,
    predicat: (order: number) => boolean,
    sortOrder: 'asc' | 'desc',
  ) {
    const nearestBlock = (await TextBlock.query()
      .where('order', predicat)
      .where('pageRef', pageRef)
      .where('isDeleted', false)
      .where('visibility', (vis: Visibility) => vis.indexOf(role) !== -1)
      .orderBy('order', sortOrder)
      .limit(1)
      .first()) as TextBlock

    if (nearestBlock) {
      const itemOrder = item.order
      item.order = nearestBlock.order
      nearestBlock.order = itemOrder
      await nearestBlock.$persist()
      await item.$persist()
    }
  }

  public textBlockId: string
  public companyId?: string
  public title: string
  public description: string
  public image: string
  public hasImage: boolean
  public visibility: Visibility
  public order: number
  public pageRef: TextBlockPageRef

  public isDeleted: boolean

  public $title: string
  public $description: string

  public removeRole(role: Role) {
    const idx = this.visibility.indexOf(role)
    if (idx !== -1) {
      this.visibility.splice(idx, 1)
    }
  }

  public isEmpty() {
    const re = /^\s*$/
    if (this.title.match(re) && this.description.match(re)) {
      return true
    }
    return false
  }
}

export const genTextBlock = (companyId: string, pageRef = 'company') =>
  new TextBlock({
    textBlockId: faker.random.uuid(),
    companyId: companyId || faker.random.uuid(),
    title: faker.lorem.words(),
    description: faker.lorem.paragraph(),
    hasImage: faker.random.boolean(),
    visibility: [faker.random.arrayElement(rolesList)],
    order: faker.random.number(50),
    pageRef,
  })
