import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { IRootState } from '@/store/root'
import { User, getMockUser, Role, Company, CompanyUserProfile } from '@/models'
import authService from '@/services/AuthServices'
import { clearBrowserStorage } from '@/services/StorageService'
import { apiCall } from '@/api'

export interface IAuthState {
  authError: any
  authorized: boolean
  userId: string | null
  profileId: string | null
  companyId: string | null
  role: Role | null
  isAdmin: boolean
  sessionExpired: boolean
}

export const getDefaultState = (): IAuthState => ({
  authError: null,
  companyId: null,
  authorized: true,
  userId: null,
  profileId: null,
  role: 'member',
  isAdmin: false,
  sessionExpired: false,
})

export const getters: GetterTree<IAuthState, IRootState> = {
  isLogged: (state) => state.authorized,
  userId: (state) => state.userId,
  isMember: (state) => state.role === 'member',
  isAdmin: (state) => state.isAdmin,
  isHire: (state) => state.role === 'hire',
  isCandidate: (state) => state.role === 'candidate',
  authError: (state) => state.authError,
  companyId: (state) => state.companyId,
  isExpired: (state) => state.sessionExpired,
}

export const mutations: MutationTree<IAuthState> = {
  fakeLogin(state) {
    state.authorized = true
    const user = getMockUser()
    user.isAdmin = true // TODO should come from api
    state.isAdmin = true
    User.create({
      data: [user],
      insertOrUpdate: ['profiles'],
    })
    state.userId = user.userId
    state.profileId = user.profile && user.profile.companyUserProfileId
    state.role = user.profile ? user.profile.role : 'candidate'
  },
  login(state: IAuthState, payload: { userId: string, companyId: string, profileId: string }) {
    state.authorized = true
    state.userId = payload.userId
    state.companyId = payload.companyId
    state.profileId = payload.profileId
  },
  logout(state) {
    state.authorized = false
    state.userId = null
    state.role = null
  },
  setRole(state, role: Role) {
    state.role = role
  },
  setIsAdmin(state, isAdmin: boolean) {
    state.isAdmin = isAdmin
  },
  setAuthError(state, authError: any) {
    state.authError = authError
  },
  setSessionExpired(state) {
    state.sessionExpired = true
  },
}

export const actions: ActionTree<IAuthState, IRootState> = {
  async login({ commit }, payload: { email: string }) {
    const res = await apiCall('/user/login', 'POST', payload)
    return res
  },
  async loginCheck({ commit }, payload: { token: string, userId: string }) {
    commit('setAuthError', null)
    try {
      const {
        user, accessToken, company, profile,
      } = await apiCall('/user/login-check', 'POST', payload)
      authService.setSession(accessToken, user)

      User.insert({ data: [user] })
      Company.insert({ data: [company] })
      CompanyUserProfile.insert({ data: [profile] })

      commit('login', {
        userId: user.userId,
        profileId: profile && profile.companyUserProfileId,
        companyId: company.companyId,
      })
      commit('setRole', profile.role)
      commit('setIsAdmin', company.ownerId === user.userId)
    } catch (err) {
      commit('setAuthError', err.message)
    }
  },
  async logout({ commit }) {
    await apiCall('/user/logout', 'GET')
    commit('logout')
    authService.logout()
    clearBrowserStorage()
  },

  async sendFeedback(ctx, message: any) {
    await apiCall('/help/feedback', 'POST', { message })
  },
}

export const moduleAuth: Module<IAuthState, IRootState> = {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
  actions,
}
