



















import { Component } from 'vue-property-decorator'
import MobileView from '@/components/preview/MobileView.vue'
import GridApp from '@/components/app/GridApp.vue'
import { App } from '../../models'
import View from '../../View'

@Component({
  components: {
    MobileView,
    GridApp,
  },
})
export default class Apps extends View {
  public searchApp = ''

  get hasApps() {
    return this.apps.length > 0
  }

  get apps() {
    return App.query()
      .where('isDeleted', false)
      .orderBy('name')
      .get()
  }

  get companyId() {
    return this.$store.state.auth.companyId
  }

  public async mounted() {
    this.$store.dispatch('app/loadCatalog')
    this.$store.commit('ui/setMobilePreviewPage', 'AppApps')
    await this.$store.dispatch('app/load', this.companyId)
  }
}
