export type Role = 'candidate' | 'hire' | 'member'

export type Visibility = Role[]

export type InvitationStatus =
  | 'none'
  | 'invite_sent'
  | 'invite_accepted'
  | 'invite_expired'

export const rolesList = ['candidate', 'hire', 'member']

export type TemplateType =
  | 'invitation'
  | 'reminder'

export type NotificationType =
  | 'info'
  | 'success'
  | 'error'
  | 'warning'

export interface NotificationAction {
  title: string
  entity: string
  id: string | number
  fields?: {
    [key: string]: any,
  }
  callback?: () => void
}

export type TextBlockPageRef = 'home' | 'company' | 'company-footer' | 'point-of-contacts'

// https://stackoverflow.com/questions/52258061/export-not-found-on-module
export const TextBlockPageRef = undefined

export interface Dict<T> { [key: string]: T }
