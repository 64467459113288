

























































































import { Component } from 'vue-property-decorator'

import AdminActions from '@/components/common/AdminActions.vue'
import MobilePreview from '@/components/preview/MobileView.vue'
import MemberContactInfo from '@/components/team/MemberContactInfo.vue'
import InvitationStatus from '@/components/team/InvitationStatus.vue'
import View from '../View'
import { CompanyUserProfile } from '@/models'

@Component({
  components: {
    MobilePreview,
    AdminActions,
    MemberContactInfo,
    InvitationStatus,
  },
})
export default class Profile extends View {
  public dataReady = false

  public async created() {
    // refetch entity
    const res = await CompanyUserProfile.api().$get({
      params: { id: this.$route.params?.user },
    })
    this.dataReady = true
  }

  get isOwnProfile() {
    return this.ownProfile && this.profile &&
      this.ownProfile.companyUserProfileId === this.profile.companyUserProfileId
  }

  get hasInterests() {
    return this.profile?.expertise && Object.keys(this.profile?.expertise?.items).length > 0
  }
  get profile() {
    const { user } = this.$route.params

    if (!user || user === 'me') {
      if (this.ownProfile) {
        return this.ownProfile
      }
    }

    const member = CompanyUserProfile.query()
      .withAll()
      .where('companyUserProfileId', user)
      .first()

    if (this.dataReady && !member) {
      this.$router.push('/not-found')
    }

    return member
  }

  public async setAccountAcitive(isActive: boolean) {
    if (!this.profile) {
      return false
    }

    await CompanyUserProfile.api().$update({
      params: { id: this.profile.companyUserProfileId },
      data: {
        isActive,
      },
    })
  }

  public async removeMember() {
    if (this.profile) {
      await CompanyUserProfile.softDelete(
        this.profile.companyUserProfileId,
        `${this.profile.name} was removed`,
      )
    }
    this.$router.push('/members')
  }
}
