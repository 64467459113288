import { getLogger } from './utils/logger'

const getEnv = () => {
  const { location } = window
  const match = location.hostname.match(/(.+)\.uelco\.co/)
  const app = match && match[1]
  if (app) {
    return 'prod'
  }
  return 'local'
}

const guessApiUrl = () => {
  if (getEnv() === 'local') {
    return 'http://localhost:4001'
  }
  return '/api'
}

export const apiUrl = process.env.VUE_APP_API_URL || guessApiUrl()
export const signUrl = 'https://europe-west1-uelco-160716.cloudfunctions.net/signedUrl'

const logger = getLogger('config')
logger('env:', getEnv())
logger('api:', guessApiUrl())
logger('signURL:', signUrl)
