
















































import { Component } from 'vue-property-decorator'
import AdminActions from '@/components/common/AdminActions.vue'
import { App, CompanyUserProfile } from '../../models'
import View from '../../View'
import MemberAvatar from '../../components/team/MemberAvatar.vue'

@Component({
  components: {
    AdminActions,
    MemberAvatar,
  },
})
export default class AppView extends View {
  get appId() {
    return this.$route.params.id
  }

  get app(): App {
    return App.find(this.appId) as App
  }

  get contactPerson(): CompanyUserProfile | null {
    if (this.app.contactPersonId) {
      return CompanyUserProfile.find(this.app.contactPersonId) as CompanyUserProfile
    }
    return null
  }

  public async removeApp() {
    if (this.app) {
      await App.softDelete(this.app.appId, `"${this.app.name} was removed`)
    }
    this.$router.push('/apps')
  }
}
