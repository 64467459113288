




























































import { Component } from 'vue-property-decorator'

import View from '../View'
import MemberAvatar from '@/components/team/MemberAvatar.vue'
import CompanyLogo from '@/components/company/CompanyLogo.vue'

import { Role } from '@/models'
import { App, Document, CompanyUserProfile } from '../models'

@Component({
  components: {
    MemberAvatar,
    CompanyLogo,
  },
})
export default class AdminHeader extends View {
  get billingDaysLeft() {
    return this.$store.state.billing.daysLeft
  }

  get restrictions() {
    return this.$store.state.billing.restrictions
  }

  get role() {
    return this.$store.state.ui.roleSwitch
  }

  get roles() {
    return ['member', 'hire', 'candidate', 'admin']
  }

  get appsCount() {
    return App.query().where('isDeleted', false).count() || 0
  }

  get docsCount() {
    return Document.query().where('isDeleted', false).count() || 0
  }

  get usersCount() {
    return CompanyUserProfile.query().where('isDeleted', false).count() || 0
  }

  public dropdownActive: boolean;
  private $search: HTMLInputElement;

  public data() {
    return {
      dropdownActive: false,
    }
  }

  public async logout() {
    this.$router.push({ name: 'logout' })
  }

  public hasRole(role: Role | 'admin') {
    return role === 'admin' // this is admin
  }

  public mounted() {
    window.addEventListener('keydown', this.handleKeyUp)
    this.$search = document.getElementById('search') as HTMLInputElement
  }

  public beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyUp)
  }

  public toggleFlag(key: string) {
    this.$store.commit('ui/toggleFlag', key)
  }

  public setRole(role: Role | 'admin') {
    if (role !== 'admin') {
      this.$store.commit('ui/setRoleSwitch', role)
      this.$store.commit('auth/setIsAdmin', false)
    } else {
      this.$store.commit('auth/setIsAdmin', true)
    }
  }

  public handleKeyUp(event: KeyboardEvent) {
    if (!this.isEdit()) {
      if (event.ctrlKey && event.code === 'KeyF') {
        this.$router.push({ name: 'search' })
      }
    }
  }
  private isEdit() {
    return (
      document &&
      document.activeElement &&
      (document.activeElement.nodeName === 'INPUT' ||
        document.activeElement.nodeName === 'TEXTAREA')
    )
  }
}
