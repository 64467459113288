

























import { Component } from 'vue-property-decorator'

import View from '../../View'
import database from '../../models/database'
import EntityValueDebug from './EntityValueDebug.vue'

@Component({
  components: {
    EntityValueDebug,
  },
})
export default class DebugDatabase extends View {

  get entities() {
    return database.entities.map((entity) => entity.name)
  }

  get records() {
    return this.getModel(this.active).query().withAll().all()
  }
  public active!: string

  public count(modelName: string) {
    return this.getModel(modelName).query().count()
  }

  public reload(modelName: string) {
    this.getModel(modelName).api().$fetch()
  }

  public data() {
    return {
      active: database.entities[0].name,
    }
  }

  public ownKeys(entity: any) {
    return Object.keys(entity).filter((k: string) => k.charAt(0) !== '$')
  }

  private getModel(modelName: string) {
    const { model } = (database.entities.filter(
      (item) => item.name === modelName,
    ) as any)[0]
    return model
  }
}
