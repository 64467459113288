




import { Component, Vue, Prop } from 'vue-property-decorator'
import { CompanyUserProfile } from '@/models'

interface RoleIconMap {
  [key: string]: string;
}

const roleToIcon: RoleIconMap = {
  hire: require('@/i/illustration-hire.svg'),
  candidate: require('@/i/illustration-candidate.svg'),
  member: require('@/i/illustration-member.svg')
}

@Component({})
export default class MemberAvatar extends Vue {
  @Prop({ type: Object }) public profile!: CompanyUserProfile;

  public extraClass!: string;

  get avatar() {
    if (this.profile.avatar) {
      return this.profile.avatar
    }
    return roleToIcon[this.profile.role]
  }

  get hasAvatar() {
    return (
      this.profile.avatar && !this.profile.avatar.match(/\/img\/illustration-/)
    )
  }

  public data() {
    return {
      extraClass: ''
    }
  }

  public imageLoaded(evt: any) {
    const { naturalWidth, naturalHeight } = evt.target
    const ratio = naturalWidth / naturalHeight

    const extraClasses: string[] = [`w-${naturalWidth}`, `h-${naturalHeight}`]

    if (ratio > 1.2) {
      extraClasses.push('wide')
    } else if (ratio < 0.8) {
      extraClasses.push('tall')
    }

    this.extraClass = extraClasses.join(' ')
  }
}
