












import { Component, Prop } from 'vue-property-decorator'

import View from '@/View'
import { App, Document } from '@/models'

@Component
export default class MenuFooter extends View {
  @Prop() public changePage: () => boolean

  get styles() {
    const gradientFrom = this.getSettingValue('gradient-from')
    const gradientTo = this.getSettingValue('gradient-to')

    if (gradientFrom && gradientTo) {
      return {
        background: `linear-gradient(-134deg, ${gradientTo} 0%, ${gradientFrom} 100%)`,
      }
    }
    return {}
  }

  get menuItems() {
    const items = [
      {
        title: 'Home',
        icon: 'icon-home-white.svg',
        cmp: 'AppHome',
      },
      {
        title: 'Company',
        icon: 'icon-company-white.svg',
        cmp: 'AppCompany',
      },
      {
        title: 'Team',
        icon: 'icon-team-white.svg',
        cmp: 'AppTeam',
      },
      {
        title: 'Apps',
        icon: 'icon-apps-white.svg',
        cmp: 'AppApps',
      },
      {
        title: 'Docs',
        icon: 'icon-docs-white.svg',
        cmp: 'AppDocs',
      },
    ]

    return items.filter(item => this.hasContent(item.title))
  }

  public hasContent(section: string) {
    switch (section) {
    case 'Apps':
      return App.query().where('isDeleted', false).count() > 0
    case 'Docs':
      return Document.query().where('isDeleted', false).count() > 0
    }

    return true
  }

}
