import templateVars from './TemplateVars'
import date from './Date'
import trimString from './TrimString'
import socialLink from './SocialLink'

export default (Vue: any) => {
  Vue.filter('templateVars', templateVars)
  Vue.filter('date', date)
  Vue.filter('trimString', trimString)
  Vue.filter('socialLink', socialLink)
}
