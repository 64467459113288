






















import { Component } from 'vue-property-decorator'
import MobileGridContact from '@/components/preview/MobileGridContact.vue'
import View from '../../View'
import TextBlockList from '@/components/text-block/TextBlockList.vue'

@Component({
  components: {
    MobileGridContact,
    TextBlockList,
  },
})
export default class PreviewAppCompany extends View {
  get hasSocial() {
    if (typeof this.company?.social !== 'object') {
      return false
    }
    return Object.values(this.company?.social || {})
      .filter(value => !!value)
      .length > 0
  }
}
