






























import { Component } from 'vue-property-decorator'
import AdminHeader from '@/components/AdminHeader.vue'
import UserHeader from '@/components/UserHeader.vue'
import MenuZone from '@/components/MenuZone.vue'
import Shortcuts from '@/components/Shortcuts.vue'
import AdminFooter from '@/components/AdminFooter.vue'
import UserFooter from '@/components/UserFooter.vue'
import Debug from '../debug/Debug.vue'
import View from '../../View'

@Component({
  components: {
    AdminHeader,
    UserHeader,
    MenuZone,
    Shortcuts,
    UserFooter,
    AdminFooter,
    Debug,
  },
  props: {
    menuAddLabel: String,
    menuAddActionRoute: String,
    showMenu: {
      type: Boolean,
      default: true,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppLayout extends View {
  public menuAddLabel: string
  public menuAddActionRoute: string
  public showMenu: boolean
  public narrow: boolean

  get linkColor() {
    return this.getSetting('link-color')?.value
  }

  public data() {
    return {
      menuVisible: this.showMenu,
      isNarrow: this.narrow,
    }
  }

  public mounted() {
    this.$store.commit('ui/setEditorActive', false)
  }
}
