






























import Vue from "vue";
import { Route } from "vue-router";
import { Component } from "vue-property-decorator";

import MobileView from "@/components/preview/MobileView.vue";
import CompanySettings from "@/components/settings/CompanySettings.vue";
import LookAndFeelSettings from "@/components/settings/LookAndFeelSettings.vue";
import AdvancedSettings from "@/components/settings/AdvancedSettings.vue";
// import NotificationSettings from '@/components/settings/NotificationSettings.vue'
import TemplatesSettings from "@/components/settings/TemplatesSettings.vue";
import SlackSettings from "@/components/settings/SlackSettings.vue";
import TabSwitch from "@/components/common/TabSwitch.vue";
import TodoTemplateSettings from "@/components/settings/TodoTemplatesSettings.vue";

import View from "@/View";

interface TabItem {
  key: string;
  label: string;
  cmp: typeof Vue;
}

@Component({
  components: {
    MobileView,
    TabSwitch,
  },
  beforeRouteEnter(to: Route, from: Route, next: any) {
    // Store to detect referer
    to.meta._from = from;
    next();
  },
})
export default class Settings extends View {
  get activeTab() {
    return this.$route.params?.tab || "company";
  }

  get referer() {
    return this.$store.getters["settings/referer"];
  }

  get tabItems(): TabItem[] {
    const items: TabItem[] = [
      {
        key: "company",
        label: "Company name and logo",
        cmp: CompanySettings,
      },
      {
        key: "look-and-fill",
        label: "Look and feel",
        cmp: LookAndFeelSettings,
        // }, {
        //   Temporarily disabled
        //   key: 'notifications',
        //   label: 'Notifications',
        //   cmp: NotificationSettings,
      },
      {
        key: "templates",
        label: "Email templates",
        cmp: TemplatesSettings,
      },
      {
        key: "slack",
        label: "Slack",
        cmp: SlackSettings,
      },
      {
        key: "advanced",
        label: "Advanced",
        cmp: AdvancedSettings,
      },
    ];

    if (this.$store.state.ui.flags.checklist) {
      items.push({
        key: "todos",
        label: "Checklist template",
        cmp: TodoTemplateSettings,
      });
    }

    return items;
  }

  get activeComponent() {
    const active = this.tabItems
      .filter((item: TabItem) => item.key === this.activeTab)
      .pop();
    return active?.cmp ?? undefined;
  }

  public created() {
    this.$store.dispatch("settings/loadSettings", this.company.companyId)
    this.$store.dispatch("settings/loadTemplates", this.company.companyId)
    this.$store.dispatch('billing/loadSubscription', this.company.companyId)
  }

  public updateActiveTab({ key }: { key: string }) {
    this.$router.push({
      name: "settings",
      params: {
        tab: key,
      },
    });
  }
}
