import faker from '@/faker'
import { Model } from '@vuex-orm/core'
import { CompanyUserProfile, Team, genCompanyUserProfile } from '@/models'

export class User extends Model {
  public static entity = 'users'

  public static primaryKey = 'userId'

  public static fields() {
    return {
      userId: this.attr(undefined),
      email: this.attr(''),
      profile: this.hasOne(CompanyUserProfile, 'userId'),
      isAdmin: this.attr(false),
    }
  }

  public userId!: string
  public email!: string
  public profile!: CompanyUserProfile | null
  public isAdmin!: boolean

  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/user')
      },
      $get ({ params }: any) {
        return (this as any).get(`/user/${params.id}`)
      },
      $create ({ data }: any) {
        return (this as any).post('/user', data)
      },
      $delete ({ params }: any) {
        return (this as any).delete(`/user/${params.id}`, {
          delete: params.id
        })
      },
      $update ({ params, data }: any) {
        return (this as any).put(`/user/${params.id}`, data)
      }
    }
  }
}

export const getMockUser = (teams?: Team[]): User =>
  new User({
    userId: faker.random.uuid(),
    email: faker.internet.email(),
    profile: genCompanyUserProfile(teams),
    isAdmin: false,
  })
