






























































































import { Component } from 'vue-property-decorator'

import AddSection from '@/components/AddSection.vue'

import RoleSwitch from '@/components/common/RoleSwitch.vue'
import EditableSection from '@/components/common/EditableSection.vue'
import EditableTextBlock from '@/components/company/EditableTextBlock.vue'
import MobileView from '@/components/preview/MobileView.vue'
import GridContact from '@/components/contact/GridContact.vue'
import EditableTextBlocksMixin from './mixins/EditableTextBlocksMixin'
import { Company, TextBlockPageRef } from '@/models'
import { companySocialNetworks } from '@/data/social-networks'

@Component({
  components: {
    AddSection,
    MobileView,
    RoleSwitch,
    GridContact,
    EditableTextBlock,
    EditableSection,
  },
})
export default class CompanyView extends EditableTextBlocksMixin {
  get textBlocksFooter() {
    return this.textBlocksByRef('company-footer')
  }

  get textBlocksContacts() {
    return this.textBlocksByRef('point-of-contacts')
  }

  get socialNetworks() {
    return companySocialNetworks
  }

  get hasSocial() {
    return Object.values(this.company?.social || {})
      .filter(value => !!value)
      .length > 0
  }

  public companySocial: object
  protected pageRef: TextBlockPageRef = 'company'

  public data() {
    return {
      companySocial: {},
    }
  }

  public async created() {
    await this.$store.dispatch('company/load', this.company)
  }

  public mounted() {
    this.$store.commit('ui/setMobilePreviewPage', 'AppCompany')
  }

  public async saveSocialLinks() {
    if (this.company) {
      await Company.api().$update({
        params: {
          id: this.company.companyId,
        },
        data: {
          social: Company.normalizeSocialLinks(this.companySocial)
        },
      })
    }
  }

  public cancelSocialLinks() {
    this.companySocial = {}
  }

  public initSocialLinks() {
    if (!this.company.social) {
      this.company.social = {}
    }
    this.companySocial = this.company ? { ...this.company.social } : {}
  }
}
