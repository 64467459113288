













import { Component, Vue } from 'vue-property-decorator'
import AutoSuggest from '@/components/common/AutoSuggest.vue'
import { CompanyUserProfile } from '@/models'
import MemberAvatar from '../team/MemberAvatar.vue'

@Component({
  components: {
    AutoSuggest,
    MemberAvatar,
  },
})
export default class ContactPersonSelector extends Vue {
  public filterItems(search: string): any {
    if (!search) {
      return []
    }

    const query = CompanyUserProfile.query()
      .where('role', (role: string) => role !== 'candidate')

    if (search !== '') {
      const re = new RegExp(search, 'i')
      query
        .where((row: CompanyUserProfile) => re.test(`${row.firstName} ${row.lastName}`))
    }

    return query.limit(12).get()
  }
}
