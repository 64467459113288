import * as localForage from 'localforage'
import { getLogger } from '@/utils/logger'

const logger = getLogger('storage')

const browserStorage = localForage

export const VUE_PERSIST_PATCH_DELAY = 'vue-persist-patch-delay'
export const STORAGE_READY_EVENT = 'storageReady'

export default browserStorage

export const isStorageReady = async () => new Promise((resolve, reject) => {
  localForage.ready((err) => {
    if (err) {
      logger('Storage not ready', err)
      return reject(err)
    }
    logger('Storage ready')
    return resolve(true)
  })
})

export const clearBrowserStorage = async () => {
  if (typeof window === 'undefined') { return false }

  window.localStorage.clear()
  await localForage.removeItem('vuex')
  await localForage.clear()
}

export const vuexPersistEmitter = (store: any) => {
  (store as any)._vm.$root.$data[VUE_PERSIST_PATCH_DELAY] = true

  store.subscribe((mutation: any) => {
    if (mutation.type === 'RESTORE_MUTATION') {
      (store as any)._vm.$root.$data[VUE_PERSIST_PATCH_DELAY] = false
      store._vm.$root.$emit(STORAGE_READY_EVENT)
    }
  })
}
