









import { Component, Vue, Prop } from 'vue-property-decorator'
import { CompanyUserProfile } from '@/models'

@Component
export default class MemberContactInfo extends Vue {
  @Prop({ type: Object })
  public profile!: CompanyUserProfile
}
