import Vue from 'vue'
import Router, { RouterMode } from 'vue-router'

import Login from './views/Login.vue'
import Logout from './views/Logout.vue'
import LoginCheck from './views/LoginCheck.vue'
import Welcome from './views/Welcome.vue'
import Company from './views/Company.vue'

import Apps from './views/apps/Apps.vue'
import AppEdit from './views/apps/AppEdit.vue'
import AppView from './views/apps/AppView.vue'
import AppFromCatalog from './views/apps/AppFromCatalog.vue'

import ContactEdit from './views/contacts/ContactEdit.vue'

import Documents from './views/documents/Documents.vue'
import DocumentEdit from './views/documents/DocumentEdit.vue'

import Members from './views/members/Members.vue'
import MemberAdd from './views/members/MemberAdd.vue'
import MemberEdit from './views/members/MemberEdit.vue'
import MemberInvite from './views/members/MemberInvite.vue'
import SlackImport from './views/members/SlackImport.vue'
import InviteMember from './views/members/InviteMember.vue'

import Profile from './views/Profile.vue'
import Settings from './views/Settings.vue'

import Search from './views/Search.vue'
import Playground from './views/Playground.vue'

import Billing from './views/Billing.vue'

import Debug from './views/Debug.vue'

import NotFound from './views/NotFound.vue'
import authService from './services/AuthServices'
import { getLogger } from './utils/logger'
import { VUE_PERSIST_PATCH_DELAY, STORAGE_READY_EVENT } from './services/StorageService'

const logger = getLogger('router')

const allowedReferers = [
  'home',
  'welcome',
  'company',
  'members',
  'apps',
  'documents',
  'profile',
]

const initRouter = (mode: RouterMode = 'history', store: any = undefined) => {
  Vue.use(Router)

  const router = new Router({
    mode,
    routes: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { title: 'Login', guest: true },
      },
      {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: { title: 'Logout', guest: true },
      },
      {
        path: '/login-check',
        name: 'login-check',
        component: LoginCheck,
        meta: { title: 'Login', guest: true },
      },
      {
        path: '/',
        name: 'home',
        redirect: { name: 'welcome' },
      },
      {
        path: '/welcome',
        name: 'welcome',
        component: Welcome,
        meta: { title: 'Welcome' },
      },
      {
        path: '/company',
        name: 'company',
        component: Company,
        meta: { title: 'Company' },
      },
      {
        path: '/members',
        name: 'members',
        component: Members,
        meta: { title: 'Members' },
      },
      {
        name: 'contact-edit',
        path: '/contacts/:id/edit',
        component: ContactEdit,
        meta: {
          backRoute: '/company',
          title: 'Edit Contact',
        },
      },
      {
        name: 'contact-add',
        path: '/contacts/add',
        component: ContactEdit,
        meta: {
          backRoute: '/company',
          title: 'Add Contact',
        },
      },
      {
        path: '/apps',
        name: 'apps',
        component: Apps,
        meta: { title: 'Apps' },
      },
      {
        name: 'app-from-catalog',
        path: '/apps/catalog',
        component: AppFromCatalog,
        meta: {
          backRoute: '/apps',
          title: 'Apps Catalog',
        },
      },
      {
        name: 'app-add',
        path: '/apps/add',
        component: AppEdit,
        meta: {
          backRoute: '/apps',
          title: 'Add App',
        },
      },
      {
        name: 'app-edit',
        path: '/apps/:id/edit',
        component: AppEdit,
        meta: {
          backRoute: '/apps',
          title: 'Edit App',
        },
      },
      {
        name: 'app-view',
        path: '/apps/:id/view',
        component: AppView,
        meta: {
          backRoute: '/apps',
          meta: { title: 'View App' },
        },
      },
      {
        path: '/documents',
        name: 'documents',
        component: Documents,
        meta: { title: 'Documents' },
      },
      {
        name: 'document-edit',
        path: '/documents/:id/edit',
        component: DocumentEdit,
        meta: {
          backRoute: '/documents',
          title: 'Edit Document',
        },
      },
      {
        name: 'document-add',
        path: '/documents/add',
        component: DocumentEdit,
        meta: {
          backRoute: '/documents',
          title: 'Add Document',
        },
      },
      {
        name: 'document-view',
        path: '/documents/:id/view',
        component: () => import(/* webpackChunkName: "doc-view" */ './views/documents/DocumentView.vue'),
        meta: {
          backRoute: '/documents',
          title: 'View Document',
        },
      },
      {
        path: '/profile',
        name: 'profileMe',
        component: Profile,
        meta: { title: 'My Profile' },
      },
      {
        path: '/profile/:user',
        name: 'profile',
        component: Profile,
        meta: {
          backRoute: '/members',
          title: 'Profile',
	  guest: true,
        },
      },
      {
        path: '/profile/:user/edit',
        name: 'profile-edit',
        component: MemberEdit,
        meta: {
          backRoute: '/members',
          title: 'Edit Member',
        },
      },
      {
        path: '/profile/:user/invite',
        name: 'profile-invite',
        component: MemberInvite,
        meta: {
          backRoute: '/members',
          title: 'Invite Member',
        },
      },
      {
        path: '/members/new/:role',
        name: 'member-add',
        component: MemberAdd,
        meta: {
          backRoute: '/members',
          title: 'Add New Member',
        },
      },
      {
        path: '/members/invite',
        name: 'invite-member',
        component: InviteMember,
        meta: {
          backRoute: '/members',
          title: 'Invite Member',
        },
      },
      {
        path: '/settings/:tab?',
        name: 'settings',
        component: Settings,
        meta: { title: 'Settings' },
      },
      {
        path: '/search',
        name: 'search',
        component: Search,
        meta: { title: 'Search' },
      },
      {
        path: '/billing',
        name: 'billing',
        component: Billing,
        meta: { title: 'Billing' },
      },
      {
        path: '/playground',
        name: 'playground',
        component: Playground,
      },
      {
        path: '/slack-import',
        name: 'slack-import',
        component: SlackImport,
        meta: { title: 'Import from Slack' },
      },
      {
        path: '/not-found',
        name: 'notFound',
        component: NotFound,
        meta: { title: 'Not Found' },
      },
      {
        path: '/debug',
        name: 'debug',
        component: Debug,
      },
      {
        path: '/*',
        name: 'catchAll',
        component: NotFound,
        meta: { title: 'Not Found', guest: true },
      },
    ],
  })

  router.beforeEach((to, from, next) => {
    const requiresAuth = !to.meta || to.meta.guest !== true

    if (requiresAuth && !authService.isAuthenticated()) {
      if (localStorage) {
        localStorage.setItem('redirect', String(to.fullPath || ''))
      }

      logger(`User not authorized to access ${to.fullPath}`)
      return next({ path: '/login' })
    }

    if (store && store._vm.$root.$data[VUE_PERSIST_PATCH_DELAY]) {
      return store._vm.$root.$on(STORAGE_READY_EVENT, async () => {
        try {
          if (requiresAuth) {
            // TODO: check auth
            // await store.dispatch('auth/load')
          }
          next()
        } catch (err) {
          return next(`/login?error=${err.message}`)
        }
      })
    }

    // remember last "meaningful" page
    if (allowedReferers.includes(String(to.name))) {
      store.commit('settings/setReferer', to)
    }

    next()
  })

  return router
}

export default initRouter
