import { Module } from 'vuex'
import { IRootState } from '@/store/root'
import { App, catalog, CatalogApp } from '@/models'

export interface IAppState {
  apps: App[]
  catalog: CatalogApp[]
}

export const moduleApp: Module<IAppState, IRootState> = {
  namespaced: true,
  state: {
    apps: [],
    catalog: [],
  },
  getters: {},
  mutations: {
    setApps(state, apps: App[]) {
      state.apps = apps
    },
    setCatalog(state, catalogItems: CatalogApp[]) {
      state.catalog = catalogItems
    },
    removeApp(state, app: App) {
      state.apps = state.apps.filter((item) => item.appId !== app.appId)
    },
  },
  actions: {
    loadCatalog() {
      if (CatalogApp.query().count() > 0) {
        return
      }

      CatalogApp.create({ data: catalog })
    },
    async load() {
      await App.api().$fetch()
    },
    remove(ctx, app: App) {
      App.api().$delete({
        params: {
          id: app.appId,
        },
      })
    },
  },
}
