import { Model } from '@vuex-orm/core'
import { Notification } from './notification'

export class BaseModel extends Model {
  [key: string]: any

  public static async softDelete(id: string | number, message?: string) {
    await this.api().$update({
      params: { id },
      data: {
        isDeleted: true,
      },
    })
    Notification.add('warning', message || 'Removed', {
      title: 'Undo',
      id,
      entity: this.entity,
      fields: {
        isDeleted: false,
      },
    })
  }

  // constructor(record?: any) {
  //   super(record)

  //   const addKeyIfMissing = (key: string) => {
  //     if (!this[key]) {
  //       this[key] = faker.random.uuid() // todo: beware of different types
  //     }
  //   }

  //   const primaryKey = (this.constructor as typeof Model).primaryKey
  //   const keys = Array.isArray(primaryKey) ? primaryKey : [primaryKey]
  //   keys.forEach(addKeyIfMissing)

  //   if ('createdAt' in this && !this.createdAt) {
  //     this.createdAt = new Date().toISOString()
  //   }
  // }

  /**
   * Trigger api call to save or update
   */
  public async $persist() {
    const model = this.constructor as typeof BaseModel

    let params = { id: this.$id }

    if (Array.isArray(model.primaryKey)) {
      params = model.primaryKey.reduce((res: any, cur: string) => {
        res[cur] = this[cur]
        return res
      }, {})
    }

    if (this.$id) {
      await model.api().$update({
        params,
        data: this.$toJson(), // TODO: dates are messed up
      })
    } else {
      await model.api().$create({
        data: this.$toJson(),
      })
    }
  }
}
