


































































import { Component } from 'vue-property-decorator'
import View from '../View'
import MemberAvatar from '@/components/team/MemberAvatar.vue'
import { CompanyUserProfile, App, Document, Role, Visibility } from '@/models'

const LIMIT = 100

@Component({
  components: {
    MemberAvatar,
  },
})
export default class Search extends View {
  get search(): string {
    return this.$store.state.ui.search
  }
  set search(value: string) {
    this.$store.commit('ui/setSearch', value)
  }

  get selectedCol(): number {
    return this.$store.state.ui.selectedCol
  }
  set selectedCol(value: number) {
    this.$store.commit('ui/setSelectedCol', value)
  }

  get selectedRow(): number {
    return this.$store.state.ui.selectedRow
  }
  set selectedRow(value: number) {
    this.$store.commit('ui/setSelectedRow', value)
  }

  get totalInCol() {
    switch (this.selectedCol) {
    case 0: return this.members.length
    case 1: return this.apps.length
    case 2: return this.docs.length
    }
    return 0
  }

  get members(): CompanyUserProfile[] {
    if (!this.search.trim()) {
      return []
    }

    const re = new RegExp(this.search.trim(), 'i')
    const query = CompanyUserProfile.query()
      .where(
        ({firstName, lastName }: CompanyUserProfile) =>
          !![firstName, lastName].join(' ').match(re),
      )

    if (!this.isAdmin) {
      query.where('role', (role: Role) => role !== 'candidate')
    }

    return query.limit(LIMIT).get() as CompanyUserProfile[]
  }

  get apps(): App[] {
    if (!this.search) {
      return []
    }

    const query = App.query()
    const re = new RegExp(this.search, 'i')
    query
      .where(
        ({name, header, description }: App) =>
          !![name, header, description].join(' ').match(re),
      )

    return query.limit(LIMIT).get() as App[]
  }

  get docs(): Document[] {
    if (!this.search) {
      return []
    }

    const query = Document.query()
    const re = new RegExp(this.search, 'i')
    query
      .where(({name, url }: Document) => !![name, url].join(' ').match(re))

    if (!this.isAdmin && this.roleSwitch) {
      query.where('visibility', (vis: Visibility) => vis.indexOf(this.roleSwitch) !== -1)
    }

    return query.limit(LIMIT).get() as Document[]
  }

  public data() {
    return {
      selectedPoint: {},
    }
  }

  public mounted() {
    if (this.$refs.input) {
      (this.$refs.input as HTMLInputElement).focus()
    }
  }

  public isSelected(col: number, row: number) {
    return this.selectedCol === col && this.selectedRow === row
  }

  public goToItemUnderCursor() {
    switch (this.selectedCol) {
    case 0:
      if (this.selectedRow < this.members.length) {
        this.$router.push({
          name: 'profile',
          params: { user: this.members[this.selectedRow].companyUserProfileId },
        })
      }
      break
    case 1:
      if (this.selectedRow < this.apps.length) {
        this.$router.push({
          name: 'app-view',
          params: { id: String(this.apps[this.selectedRow].id) },
        })
      }
      break
    case 2:
      if (this.selectedRow < this.docs.length) {
        this.$router.push({
          name: 'document-view',
          params: { id: String(this.docs[this.selectedRow].documentId) },
        })
      }
      break
    }

    return false
  }

  public handleKey(event: KeyboardEvent) {
    if (event.code.match(/^Arrow/)) {
      event.preventDefault()
    }

    switch (event.code) {
    case 'ArrowUp':
      this.selectedRow = Math.max(0, this.selectedRow - 1)
      return false
    case 'ArrowDown':
      this.selectedRow = Math.min(this.totalInCol - 1, this.selectedRow + 1)
      return false
    case 'ArrowLeft':
      this.selectedCol = Math.max(0, this.selectedCol - 1)
      this.selectedRow = Math.min(this.totalInCol - 1, this.selectedRow)
      return false
    case 'ArrowRight':
      this.selectedCol = Math.min(2, this.selectedCol + 1)
      this.selectedRow = Math.min(this.totalInCol - 1, this.selectedRow)
      return false
    case 'Enter':
      return this.goToItemUnderCursor()
    }
  }
}
