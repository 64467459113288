






















import faker from '@/faker'

import RichTextEditor from '@/components/form/RichTextEditor.vue'
import ProgressBar from '@/components/common/ProgressBar.vue'

import { Notification, NotificationType } from '@/models'

export default {
  name: 'playground',
  components: {
    RichTextEditor,
    ProgressBar,
  },
  data() {
    return {
      text: faker.lorem.paragraphs(),
    }
  },
  methods: {
    toast(type: NotificationType) {
      Notification.add(type, faker.lorem.words())
    },
  },
}
