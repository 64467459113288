
















































































































































import { Component, Watch, Vue } from "vue-property-decorator";

import AdminActions from "@/components/common/AdminActions.vue";
import MemberCard from "@/components/team/MemberCard.vue";
import FileUpload from "@/components/document/FileUpload.vue";
import RichTextEditor from "@/components/form/RichTextEditor.vue";
import ExpertiseSelector from "@/components/form/ExpertiseSelector.vue";
import EmojiList from "@/components/form/EmojiList.vue";
import RoleDate from "@/components/team/RoleDate.vue";
import { CompanyUserProfile, rolesList, Notification, Company } from "@/models";
import View from "@/View";
import { socialNetworks } from "@/data/social-networks";
import { Route } from "vue-router";

@Component({
  components: {
    MemberCard,
    FileUpload,
    AdminActions,
    RichTextEditor,
    ExpertiseSelector,
    EmojiList,
    RoleDate,
  },
  beforeRouteLeave(to, from, next: any) {
    (this as MemberEdit).onBeforeRouteLeave(to, from, next);
  },
})
export default class MemberEdit extends View {
  public profile!: CompanyUserProfile;

  public isDirty!: boolean;
  public notSaved!: boolean;

  get userId() {
    return this.$route.params.user;
  }

  get socialNetworks() {
    return socialNetworks;
  }

  get rolesList() {
    return rolesList;
  }

  public data() {
    return {
      profile: {},
      isDirty: false,
      notSaved: false,
    };
  }

  @Watch("profile", { deep: true })
  public onProfileChanged() {
    this.isDirty = true;
  }

  public beforeCancel() {
    this.isDirty = false;
    this.notSaved = false;
  }

  public onBeforeRouteLeave(
    to: Route,
    from: Route,
    next: (res?: boolean) => void
  ) {
    if (this.isDirty) {
      this.notSaved = true;
      return next(false);
    }
    next();
  }

  public created() {
    this.profile = Object.assign(
      {},
      CompanyUserProfile.find(this.userId)
    ) as CompanyUserProfile;

    if (!this.profile.social) {
      this.profile.social = {};
    }
    if (!this.profile.expertise) {
      this.profile.expertise = {
        items: [],
        text: "",
      };
    }
    if (!this.profile.interests) {
      this.profile.interests = {
        emojis: "",
        text: "",
      };
    }
    Vue.nextTick(() => {
      this.isDirty = false;
      this.notSaved = false;
    });
  }

  public removeExpertise(index: number) {
    this.profile.expertise.items.splice(index, 1);
    return false;
  }

  public addExpertise(item: { name: string; logo: string }) {
    const { expertise } = this.profile;
    if (!expertise.items.find((rec: any) => rec.name === item.name)) {
      this.profile.expertise.items.push(item);
    }
  }

  public addEmoji(emoji: string) {
    const { interests } = this.profile;
    if (!interests.emojis) {
      interests.emojis = "";
    }
    interests.emojis = `${interests.emojis} ${emoji}`;
  }

  public async addAvatar(url: URL) {
    this.profile.avatar = `${url.origin}${url.pathname}`;
  }

  public async handleSave() {
    const profile = new CompanyUserProfile(this.profile);
    profile.social = Company.normalizeSocialLinks(profile.social);
    await profile.$persist();

    this.isDirty = false;
    this.notSaved = false;

    await Notification.add("success", "Profile saved");

    this.goBack();
  }

  public removeAvatar() {
    this.profile.avatar = "";
  }
}
