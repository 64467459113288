










import { Component } from 'vue-property-decorator'

import View from '@/View'

@Component
export default class Debug extends View {

}
