export const devLogos = [
  {
    name: 'dot-net',
    logo: 'dev/dot-net/dot-net-original.svg',
  },
  {
    name: 'amazonwebservices',
    logo: 'dev/amazonwebservices/amazonwebservices-original-wordmark.svg',
  },
  {
    name: 'android',
    logo: 'dev/android/android-original-wordmark.svg',
  },
  {
    name: 'angularjs',
    logo: 'dev/angularjs/angularjs-plain.svg',
  },
  {
    name: 'apache',
    logo: 'dev/apache/apache-line.svg',
  },
  {
    name: 'appcelerator',
    logo: 'dev/appcelerator/appcelerator-original-wordmark.svg',
  },
  {
    name: 'apple',
    logo: 'dev/apple/apple-original.svg',
  },
  {
    name: 'atom',
    logo: 'dev/atom/atom-original-wordmark.svg',
  },
  {
    name: 'babel',
    logo: 'dev/babel/babel-original.svg',
  },
  {
    name: 'backbonejs',
    logo: 'dev/backbonejs/backbonejs-original-wordmark.svg',
  },
  {
    name: 'behance',
    logo: 'dev/behance/behance-plain.svg',
  },
  {
    name: 'bitbucket',
    logo: 'dev/bitbucket/bitbucket-original.svg',
  },
  {
    name: 'bootstrap',
    logo: 'dev/bootstrap/bootstrap-plain-wordmark.svg',
  },
  {
    name: 'bower',
    logo: 'dev/bower/bower-line.svg',
  },
  {
    name: 'c',
    logo: 'dev/c/c-plain.svg',
  },
  {
    name: 'cakephp',
    logo: 'dev/cakephp/cakephp-original.svg',
  },
  {
    name: 'ceylon',
    logo: 'dev/ceylon/ceylon-original.svg',
  },
  {
    name: 'chrome',
    logo: 'dev/chrome/chrome-plain.svg',
  },
  {
    name: 'codeigniter',
    logo: 'dev/codeigniter/codeigniter-plain.svg',
  },
  {
    name: 'coffeescript',
    logo: 'dev/coffeescript/coffeescript-original-wordmark.svg',
  },
  {
    name: 'confluence',
    logo: 'dev/confluence/confluence-original-wordmark.svg',
  },
  {
    name: 'couchdb',
    logo: 'dev/couchdb/couchdb-plain.svg',
  },
  {
    name: 'cplusplus',
    logo: 'dev/cplusplus/cplusplus-plain.svg',
  },
  {
    name: 'csharp',
    logo: 'dev/csharp/csharp-plain.svg',
  },
  {
    name: 'css3',
    logo: 'dev/css3/css3-plain.svg',
  },
  {
    name: 'cucumber',
    logo: 'dev/cucumber/cucumber-plain.svg',
  },
  {
    name: 'd3js',
    logo: 'dev/d3js/d3js-plain.svg',
  },
  {
    name: 'debian',
    logo: 'dev/debian/debian-original.svg',
  },
  {
    name: 'dev',
    logo: 'dev/apple/apple-original.svg',
  },
  {
    name: 'devicon',
    logo: 'dev/devicon/devicon-plain.svg',
  },
  {
    name: 'django',
    logo: 'dev/django/django-original.svg',
  },
  {
    name: 'docker',
    logo: 'dev/docker/docker-plain.svg',
  },
  {
    name: 'doctrine',
    logo: 'dev/doctrine/doctrine-line-wordmark.svg',
  },
  {
    name: 'drupal',
    logo: 'dev/drupal/drupal-plain-wordmark.svg',
  },
  {
    name: 'electron',
    logo: 'dev/electron/electron-original.svg',
  },
  {
    name: 'elm',
    logo: 'dev/elm/elm-original.svg',
  },
  {
    name: 'ember',
    logo: 'dev/ember/ember-original-wordmark.svg',
  },
  {
    name: 'erlang',
    logo: 'dev/erlang/erlang-original-wordmark.svg',
  },
  {
    name: 'express',
    logo: 'dev/express/express-original-wordmark.svg',
  },
  {
    name: 'facebook',
    logo: 'dev/facebook/facebook-original.svg',
  },
  {
    name: 'firefox',
    logo: 'dev/firefox/firefox-original.svg',
  },
  {
    name: 'foundation',
    logo: 'dev/foundation/foundation-plain-wordmark.svg',
  },
  {
    name: 'gatling',
    logo: 'dev/gatling/gatling-plain-wordmark.svg',
  },
  {
    name: 'gimp',
    logo: 'dev/gimp/gimp-original.svg',
  },
  {
    name: 'git',
    logo: 'dev/git/git-original-wordmark.svg',
  },
  {
    name: 'github',
    logo: 'dev/github/github-original-wordmark.svg',
  },
  {
    name: 'gitlab',
    logo: 'dev/gitlab/gitlab-original.svg',
  },
  {
    name: 'go',
    logo: 'dev/go/go-original.svg',
  },
  {
    name: 'google',
    logo: 'dev/google/google-plain.svg',
  },
  {
    name: 'gradle',
    logo: 'dev/gradle/gradle-plain-wordmark.svg',
  },
  {
    name: 'grunt',
    logo: 'dev/grunt/grunt-line-wordmark.svg',
  },
  {
    name: 'gulp',
    logo: 'dev/gulp/gulp-plain.svg',
  },
  {
    name: 'handlebars',
    logo: 'dev/handlebars/handlebars-original-wordmark.svg',
  },
  {
    name: 'heroku',
    logo: 'dev/heroku/heroku-plain-wordmark.svg',
  },
  {
    name: 'html5',
    logo: 'dev/html5/html5-plain-wordmark.svg',
  },
  {
    name: 'ie10',
    logo: 'dev/ie10/ie10-original.svg',
  },
  {
    name: 'illustrator',
    logo: 'dev/illustrator/illustrator-line.svg',
  },
  {
    name: 'inkscape',
    logo: 'dev/inkscape/inkscape-plain-wordmark.svg',
  },
  {
    name: 'intellij',
    logo: 'dev/intellij/intellij-original-wordmark.svg',
  },
  {
    name: 'ionic',
    logo: 'dev/ionic/ionic-original.svg',
  },
  {
    name: 'jasmine',
    logo: 'dev/jasmine/jasmine-plain-wordmark.svg',
  },
  {
    name: 'java',
    logo: 'dev/java/java-original.svg',
  },
  {
    name: 'javascript',
    logo: 'dev/javascript/javascript-plain.svg',
  },
  {
    name: 'jeet',
    logo: 'dev/jeet/jeet-plain.svg',
  },
  {
    name: 'jetbrains',
    logo: 'dev/jetbrains/jetbrains-original.svg',
  },
  {
    name: 'jquery',
    logo: 'dev/jquery/jquery-plain-wordmark.svg',
  },
  {
    name: 'krakenjs',
    logo: 'dev/krakenjs/krakenjs-original-wordmark.svg',
  },
  {
    name: 'laravel',
    logo: 'dev/laravel/laravel-plain.svg',
  },
  {
    name: 'less',
    logo: 'dev/less/less-plain-wordmark.svg',
  },
  {
    name: 'linkedin',
    logo: 'dev/linkedin/linkedin-plain.svg',
  },
  {
    name: 'linux',
    logo: 'dev/linux/linux-original.svg',
  },
  {
    name: 'meteor',
    logo: 'dev/meteor/meteor-plain-wordmark.svg',
  },
  {
    name: 'mocha',
    logo: 'dev/mocha/mocha-plain.svg',
  },
  {
    name: 'mongodb',
    logo: 'dev/mongodb/mongodb-plain.svg',
  },
  {
    name: 'moodle',
    logo: 'dev/moodle/moodle-original.svg',
  },
  {
    name: 'mysql',
    logo: 'dev/mysql/mysql-original-wordmark.svg',
  },
  {
    name: 'nginx',
    logo: 'dev/nginx/nginx-original.svg',
  },
  {
    name: 'nodejs',
    logo: 'dev/nodejs/nodejs-plain-wordmark.svg',
  },
  {
    name: 'nodewebkit',
    logo: 'dev/nodewebkit/nodewebkit-original-wordmark.svg',
  },
  {
    name: 'npm',
    logo: 'dev/npm/npm-original-wordmark.svg',
  },
  {
    name: 'oracle',
    logo: 'dev/oracle/oracle-original.svg',
  },
  {
    name: 'photoshop',
    logo: 'dev/photoshop/photoshop-plain.svg',
  },
  {
    name: 'php',
    logo: 'dev/php/php-plain.svg',
  },
  {
    name: 'phpstorm',
    logo: 'dev/phpstorm/phpstorm-original-wordmark.svg',
  },
  {
    name: 'postgresql',
    logo: 'dev/postgresql/postgresql-plain.svg',
  },
  {
    name: 'protractor',
    logo: 'dev/protractor/protractor-plain.svg',
  },
  {
    name: 'pycharm',
    logo: 'dev/pycharm/pycharm-plain.svg',
  },
  {
    name: 'python',
    logo: 'dev/python/python-original-wordmark.svg',
  },
  {
    name: 'rails',
    logo: 'dev/rails/rails-plain.svg',
  },
  {
    name: 'react',
    logo: 'dev/react/react-original-wordmark.svg',
  },
  {
    name: 'redhat',
    logo: 'dev/redhat/redhat-plain.svg',
  },
  {
    name: 'redis',
    logo: 'dev/redis/redis-plain.svg',
  },
  {
    name: 'ruby',
    logo: 'dev/ruby/ruby-plain.svg',
  },
  {
    name: 'rubymine',
    logo: 'dev/rubymine/rubymine-original-wordmark.svg',
  },
  {
    name: 'safari',
    logo: 'dev/safari/safari-plain-wordmark.svg',
  },
  {
    name: 'sass',
    logo: 'dev/sass/sass-original.svg',
  },
  {
    name: 'sequelize',
    logo: 'dev/sequelize/sequelize-original.svg',
  },
  {
    name: 'sketch',
    logo: 'dev/sketch/sketch-original.svg',
  },
  {
    name: 'slack',
    logo: 'dev/slack/slack-plain.svg',
  },
  {
    name: 'sourcetree',
    logo: 'dev/sourcetree/sourcetree-original-wordmark.svg',
  },
  {
    name: 'ssh',
    logo: 'dev/ssh/ssh-original.svg',
  },
  {
    name: 'stylus',
    logo: 'dev/stylus/stylus-original.svg',
  },
  {
    name: 'swift',
    logo: 'dev/swift/swift-original-wordmark.svg',
  },
  {
    name: 'symfony',
    logo: 'dev/symfony/symfony-original.svg',
  },
  {
    name: 'tomcat',
    logo: 'dev/tomcat/tomcat-original-wordmark.svg',
  },
  {
    name: 'travis',
    logo: 'dev/travis/travis-plain-wordmark.svg',
  },
  {
    name: 'trello',
    logo: 'dev/trello/trello-plain.svg',
  },
  {
    name: 'twitter',
    logo: 'dev/twitter/twitter-original.svg',
  },
  {
    name: 'typescript',
    logo: 'dev/typescript/typescript-original.svg',
  },
  {
    name: 'ubuntu',
    logo: 'dev/ubuntu/ubuntu-plain.svg',
  },
  {
    name: 'vagrant',
    logo: 'dev/vagrant/vagrant-plain.svg',
  },
  {
    name: 'vim',
    logo: 'dev/vim/vim-original.svg',
  },
  {
    name: 'visualstudio',
    logo: 'dev/visualstudio/visualstudio-plain.svg',
  },
  {
    name: 'vuejs',
    logo: 'dev/vuejs/vuejs-original-wordmark.svg',
  },
  {
    name: 'webpack',
    logo: 'dev/webpack/webpack-original-wordmark.svg',
  },
  {
    name: 'webstorm',
    logo: 'dev/webstorm/webstorm-plain.svg',
  },
  {
    name: 'windows8',
    logo: 'dev/windows8/windows8-original.svg',
  },
  {
    name: 'wordpress',
    logo: 'dev/wordpress/wordpress-plain-wordmark.svg',
  },
  {
    name: 'yarn',
    logo: 'dev/yarn/yarn-original-wordmark.svg',
  },
  {
    name: 'yii',
    logo: 'dev/yii/yii-original-wordmark.svg',
  },
  {
    name: 'zend',
    logo: 'dev/zend/zend-plain-wordmark.svg',
  },
]
