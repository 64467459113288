























import { Component, Vue, Model } from 'vue-property-decorator'

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth()

@Component
export default class DateSelector extends Vue {
  @Model('change', {
    type: String,
  })
  public value: string

  public month: number
  public year: number

  get monthNames() {
    return Array.from({ length: 12 }).map((_, i) =>
      new Date(2019, i, 10).toLocaleString('en-us', { month: 'short' }),
    )
  }

  get years() {
    return Array.from({ length: 50 }).map((_, i) => currentYear - i)
  }

  get valueYear() {
    return new Date(this.value).getFullYear()
  }

  get valueMonth() {
    return new Date(this.value).getMonth()
  }

  public data() {
    return {
      month: currentMonth,
      year: currentYear,
    }
  }

  public created() {
    if (this.value) {
      this.month = this.valueMonth + 1
      this.year = this.valueYear
    }
  }

  public updateValue() {
    this.$emit('change', new Date(this.year, this.month - 1, 1, 12).toISOString())
  }
}
