import { BaseModel } from './base-model'
import { Fields } from '@vuex-orm/core'

export class Setting extends BaseModel {
  public static entity = 'settings'

  public static primaryKey = ['companyId', 'key']

  public static methodConf = {
    http: {
      url: '/settings',
    },
    methods: {
      $get: {
        name: 'get',
        http: {
          url: '/:companyId/:key',
          method: 'get',
        },
      },
      $update: {
        name: 'update',
        http: {
          url: '/:companyId/:key',
          method: 'put',
        },
      },
      $delete: {
        name: 'delete',
        http: {
          url: '/:companyId/:key',
          method: 'delete',
        },
      },
    },
  }

  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/settings')
      },
      $get ({ params }: any) {
        return (this as any).get(`/settings/${params.companyId}/${params.key}`)
      },
      $create ({ data }: any) {
        return (this as any).post('/settings', data)
      },
      $delete ({ params }: any) {
        return (this as any).delete(`/settings/${params.companyId}/${params.key}`, {
          delete: [params.companyId, params.key]
        })
      },
      $update ({ params, data }: any) {
        return (this as any).put(`/settings/${params.companyId}/${params.key}`, data)
      }
    }
  }

  public static fields(): Fields {
    return {
      key: this.attr(''),
      value: this.attr(''),
      companyId: this.attr(''),
    }
  }

  public key: string
  public value: any
  public companyId: string
}
