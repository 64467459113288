import VuexORM from '@vuex-orm/core'

import {
  App,
  CatalogApp,
  Company,
  Contact,
  TextBlock,
  Team,
  User,
  CompanyUserProfile,
  Document,
  Setting,
  Template,
  Notification,
} from '@/models'

const database = new VuexORM.Database()
database.register(App)
database.register(CatalogApp)
database.register(Company)
database.register(Contact)
database.register(TextBlock)
database.register(Team)
database.register(User)
database.register(CompanyUserProfile)
database.register(Document)
database.register(Setting)
database.register(Template)
database.register(Notification)

export default database
