



























import { Component, Vue } from "vue-property-decorator";
import VideoPlayer from "@/components/common/VideoPlayer.vue";

import ContextHelp from "../help/ContextHelp";

@Component({
  components: {
    VideoPlayer,
    ContextHelp,
  },
})
export default class HelpBox extends Vue {
  public helpVisible: boolean;

  get showHelpbox() {
    return this.$store.state.ui.helpbox;
  }

  get flags() {
    return this.$store.state.ui.flags;
  }

  get route() {
    return this.$route.name;
  }

  public openHelpbox() {
    this.$store.commit("ui/toggleHelpbox", true);
  }

  public closeHelpbox() {
    this.$store.commit("ui/toggleHelpbox", false);
  }
}
