import { Module } from 'vuex'
import { IRootState } from '@/store/root'
import { Role, Dict } from '@/models'

export interface IUIState {
  modal: boolean
  mobilePreview: boolean
  mobilePreviewPage: string
  mobilePreviewParams: { [k: string]: any }
  mobilePreviewProfile: string | null
  helpbox: boolean
  feedback: boolean
  feedbackSubmitted: boolean
  selectedIndex: number
  selectedCol: number
  selectedRow: number
  search: string
  teamAdd: boolean
  roleSwitch: Role
  editorActive: boolean
  focusedSection: {
    type: string,
    id: string | number
  }
  flags: Dict<boolean>
  initialLoadDone: boolean
}

const getDefaultState = (): IUIState => ({
  modal: false,
  mobilePreview: true,
  mobilePreviewPage: 'AppHome',
  mobilePreviewParams: {},
  mobilePreviewProfile: null,
  helpbox: false,
  feedback: false,
  feedbackSubmitted: false,
  selectedIndex: -1,
  teamAdd: false,
  roleSwitch: 'candidate',
  search: '',
  selectedCol: 0,
  selectedRow: -1,
  editorActive: false,
  flags: {
    billing: false,
    help: true,
    feedback: true,
    debug: false,
    checklist: false,
  },
  focusedSection: {
    type: '',
    id: '',
  },
  initialLoadDone: false
})

export const moduleUi: Module<IUIState, IRootState> = {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    toggleModal(state, isVisible: boolean) {
      state.modal = isVisible
    },
    toggleMobilePreview(state, isVisible: boolean) {
      state.mobilePreview = isVisible
    },
    setMobilePreviewProfile(state, profile: string | null) {
      state.mobilePreviewProfile = profile
    },
    toggleFeedbackArea(state, isVisible: boolean) {
      state.feedback = isVisible
    },
    toggleFeedbackSubmitted(state, isSubmitted: boolean) {
      state.feedbackSubmitted = isSubmitted
    },
    toggleHelpbox(state, isVisible: boolean) {
      state.helpbox = isVisible
    },
    setSelectedIndex(state, value: number) {
      state.selectedIndex = value
    },
    setMobilePreviewPage(state, value: string | [string, any]) {
      if (typeof value === 'string') {
        state.mobilePreviewPage = value
      } else {
        state.mobilePreviewPage = value[0]
        state.mobilePreviewParams = value[1]
      }
    },
    setTeamAdd(state, value: boolean) {
      state.teamAdd = value
    },
    setFlags(state, flags: { [k: string]: boolean }) {
      state.flags = flags
    },
    toggleFlag(state, flag: string) {
      state.flags = {
        ...state.flags,
        [flag]: !state.flags[flag],
      }
    },
    setRoleSwitch(state, value: Role) {
      state.roleSwitch = value
    },
    setSearch(state, value: string) {
      state.search = value
    },
    setSelectedCol(state, value: number) {
      state.selectedCol = value
    },
    setSelectedRow(state, value: number) {
      state.selectedRow = value
    },
    setEditorActive(state, value: boolean) {
      state.editorActive = value
    },
    setFocusedSection(state, payload: { type: string, id: string | number }) {
      state.focusedSection = {
        type: payload.type,
        id: payload.id,
      }
    },
    setInitialLoadDone(state, value: boolean) {
      state.initialLoadDone = value
    }
  },
}
