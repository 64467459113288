
















import { Component } from 'vue-property-decorator'
import { App } from '@/models'
import View from '../../View'

@Component({
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
})
export default class MobileGridApp extends View {
  public app: App

  public changePage(newPage: string, params: any) {
    this.$store.commit('ui/setMobilePreviewPage', [newPage, params])
  }
}
