






import { Component } from 'vue-property-decorator'
import VideoPlayer from '@/components/common/VideoPlayer.vue'
import View from '../../View'
import TextBlockList from '@/components/text-block/TextBlockList.vue'

@Component({
  components: {
    VideoPlayer,
    TextBlockList,
  },
})
export default class PreviewAppHome extends View {
}
