const KEY_PROFILE = 'profile'
const KEY_ACCESS_TOKEN = 'access_token'
const KEY_EXPIRES_AT = 'expires_at'

export class AuthService {

  get accessToken() {
    return this._loadValue(KEY_ACCESS_TOKEN, '')
  }

  get profile() {
    return this._loadValue(KEY_PROFILE, {})
  }

  public authenticated = this.isAuthenticated()
  public userProfile: any

  public setSession(accessToken: any, profile: object) {
    const expiresAt = JSON.stringify(
      accessToken.ttl * 1000 + new Date().getTime(),
    )
    localStorage.setItem(KEY_ACCESS_TOKEN, accessToken.token)
    localStorage.setItem(KEY_PROFILE, JSON.stringify(profile))
    localStorage.setItem(KEY_EXPIRES_AT, expiresAt)
  }

  public logout() {
    localStorage.clear()
    localStorage.setItem(KEY_ACCESS_TOKEN, '')
    localStorage.setItem(KEY_PROFILE, '')
    localStorage.setItem(KEY_EXPIRES_AT, '')
  }

  public isAuthenticated() {
    return new Date().getTime() < this._loadValue(KEY_EXPIRES_AT, 0)
  }

  private _loadValue(key: string, defaultValue: any = '') {
    const value = localStorage.getItem(key)
    try {
      return JSON.parse(value || '')
    } catch (err) {
      return value || defaultValue
    }
  }
}

const authService = new AuthService()
export default authService
