












import { Component } from 'vue-property-decorator'
import View from '../../View'

@Component({})
export default class PreviewAppDocs extends View {

  get todos() {
    const setting = this.getSetting('todos-hire')
    return setting?.value ?? []
  }
}
