













import { Component, Prop } from "vue-property-decorator";
import Dropzone, { DropzoneFile } from "dropzone";

import View from "../../View";
import { uploadSigned } from "../../api";
import ProgressBar from "@/components/common/ProgressBar.vue";

Dropzone.autoDiscover = false;

@Component({
  components: {
    ProgressBar,
  },
})
export default class FileUpload extends View {
  public progress = "";
  public progressPct = 0;
  public isUploading = false;
  public error = "";

  @Prop({ type: String, default: null })
  public acceptedFiles: string;

  private dropzone: Dropzone;

  get dropzoneOptions() {
    return {
      url: "doesnt matter",
      autoProcessQueue: false,
      thumbnailWidth: 50,
      maxFilesize: 5000,
      createImageThumbnails: false,
      headers: {},
      accept: this.onFileAccept.bind(this),
      dictDefaultMessage: "",
      acceptedFiles: this.acceptedFiles,
    };
  }

  get dropzoneElm(): any {
    return this.$refs.dz;
  }

  public data() {
    return {
      isUploading: this.isUploading,
      progress: this.progress,
      progressPct: this.progressPct,
      error: this.error,
    };
  }

  public mounted() {
    this.dropzone = new Dropzone(this.dropzoneElm, this.dropzoneOptions);
  }

  public handleSuccess(file: File, response: any) {
    this.$emit("upload", file);
  }

  public async onFileAccept(file: File, done: () => void) {
    const extensionHeaders = {};

    this.isUploading = true;

    try {
      const url = await uploadSigned(
        file,
        extensionHeaders,
        this.onUploadProgress.bind(this)
      );
      this.$emit("upload", url, file);

      if (this.dropzone) {
        this.dropzone.removeFile(file as DropzoneFile);
      }
    } catch (err) {
      this.$emit("error", err);
      this.error = err.message;
    }
    this.isUploading = false;
    done();
  }

  public beforeDestroy() {
    this.dropzone.destroy();
  }

  private onUploadProgress(progress: ProgressEvent) {
    this.progressPct = Math.round((100 * progress.loaded) / progress.total);
    this.progress = `${this.progressPct.toFixed(2)}%`;

    if (this.progressPct >= 100) {
      setTimeout(() => {
        this.isUploading = false;
      }, 1500);
    }
  }
}
