import urlParser from 'js-video-url-parser'
import { Company, CompanyUserProfile } from '../models'

interface IVarMap {
  profile?: CompanyUserProfile | string
}

const urlRe = new RegExp(
  /(")?(http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=()]*))/igm,
)

const linkify = (value: string) =>
  value.replace(urlRe, (url: string) => {
    const parsed = urlParser.parse(url)
    if (parsed) {
      return `<div class="video-player"
      data-plyr-provider="${parsed.provider}"
      data-plyr-embed-id="${parsed.id}"></div>`
    }
    if (url.match(/\.(mpg|mpeg|mov|mp4|mp2|avi|webm|flv|ogg|vob|ogv|gifv|wmv)$/i)) {
      return `<video class="video-player" controls>
        <source src="${url}" type="video/${url.split('.').pop()}" />
      </video>`
    }
    if (url.match(/\.(png|jpg|jpeg|gif)$/i)) {
      return `<img src="${url}" class="text-image" />`
    }
    return `<a href="${url}">${url}</a>`
  })


export default function templateVars(value: string, extraVars: IVarMap = {}): string {
  if (!value) {
    return ''
  }

  const company = Company.query().first() as Company
  const profile = typeof extraVars.profile === 'string' ?
    CompanyUserProfile.find(extraVars.profile) :
    extraVars.profile

  const vars: { [key: string]: string} = {
    name: profile?.firstName ?? '',
    username: profile?.firstName ?? '',
    companyname: company.name,

    // extra space is kinda important.. to prevent accidental links from concatenating random trailing strings
    link: `https://app.uelco.co/company/${company.url}`,
  }

  const replacer = (match: any, openTag: string, variable: string, closeTag: string) => {
    return vars[variable] ? `${openTag || ''}${vars[variable]}${closeTag || ''}`
      : `%${openTag || ''}${variable}${closeTag || ''}%`

  }

  const parsed = value.replace(/%(<[a-z]+>)?([a-z0-9]+)(<\/[a-z]+>)?%/ig, replacer)
  return linkify(parsed)
}
