








import { Component } from 'vue-property-decorator'
import MobileGridApp from '@/components/preview/MobileGridApp.vue'
import View from '../../View'
import { App } from '@/models'

@Component({
  components: {
    MobileGridApp,
  },
})
export default class PreviewAppApps extends View {
  get apps() {
    return App.query().where('isDeleted', false).all()
  }
}
