import { Visibility } from './types'
import { BaseModel } from './base-model'
import { CompanyUserProfile } from './company-user-profile'

export class App extends BaseModel {
  public static entity = 'apps'

  public static primaryKey = 'appId'

  public static fields() {
    return {
      appId: this.attr(undefined),
      companyId: this.attr(''),
      name: this.attr(''),
      icon: this.attr(null),
      url: this.attr(''),
      header: this.attr(''),
      description: this.attr(''),
      visibility: this.attr(['member']),

      contactPerson: this.hasOne(CompanyUserProfile, 'companyUserProfileId'),
      contactPersonId: this.attr(null),
      guideUrl: this.attr(''),

      isDeleted: this.attr(false),

      discovered: this.attr(false),
      catalog: this.attr(false),
    }
  }

  public appId: string
  public companyId: string | null
  public name: string
  public icon: string
  public url: string
  public header: string
  public description: string
  public visibility: Visibility
  public contactPerson: CompanyUserProfile
  public contactPersonId: string
  public guideUrl: string

  public isDeleted: boolean
  public catalog?: boolean
  public discovered?: boolean


  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/apps')
      },
      $get ({ params }: any) {
        return (this as any).get(`/apps/${params.id}`)
      },
      $create ({ data }: any) {
        return (this as any).post('/apps', data)
      },
      $delete ({ params }: any) {
        return (this as any).delete(`/apps/${params.id}`, {
          delete: params.id
        })
      },
      $update ({ params, data }: any) {
        return (this as any).put(`/apps/${params.id}`, data)
      }
    }
  }
}
