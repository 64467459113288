














import { Component } from 'vue-property-decorator'
import ItemMenu from '@/components/common/ItemMenu.vue'
import MemberAvatar from '@/components/team/MemberAvatar.vue'
import { CompanyUserProfile } from '@/models'
import View from '../../View'

@Component({
  props: {
    member: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItemMenu,
    MemberAvatar,
  },
})
export default class MobileGridMember extends View {
  public member: CompanyUserProfile

  get tag() {
    const { role } = this.member
    return ['tag', 'tag-role', `tag-${role}`]
  }

  public goToProfile() {
    this.$store.commit('ui/setMobilePreviewPage', ['MobileMemberPage', { member: this.member }])
  }
}
