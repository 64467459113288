



















import { Component, Vue, Prop, Model } from 'vue-property-decorator'
import { SlackMember, CompanyUserProfile } from '@/models'

@Component
export default class GridSlackMember extends Vue {
  @Prop({type: Object, required: true})
  public item: SlackMember

  @Prop({type: Boolean, default: true})
  public showCheckbox: boolean

  @Model('change', {type: Boolean})
  public value: boolean


  public visitProfile() {
    const profile = CompanyUserProfile.query()
      .where('slackUserId', this.item.id)
      .first() as CompanyUserProfile

    if (profile) {
      this.$router.push({
        name: 'profile',
        params: {
          user: profile.companyUserProfileId,
        },
      })
    }
  }
}
