import { Module } from 'vuex'
import { IRootState } from '@/store/root'
import { apiCall } from '@/api'

export interface IBillingState {
  subscriptionStatus: string;
  subscriptionDue: string;
  daysLeft: number | string;
  activePlan: object;
  restrictions: { [key: string]: number }
}

export const moduleBilling: Module<IBillingState, IRootState> = {
  namespaced: true,
  state: {
    subscriptionStatus: 'active',
    subscriptionDue: '2019-04-24',
    daysLeft: '2 days',
    activePlan: {
      name: 'dreamer',
      nextPaymentDay: '2019-01-01',
      amount: 39.99,
    },
    restrictions: {
      apps: 5,
      docs: 5,
      users: 5
    }
  },
  getters: {

  },
  mutations: {
    setRestrictions(state, restrictions) {
      state.restrictions = restrictions
    }
  },
  actions: {
    async loadSubscription({ commit }, companyId: string) {
      const res = await apiCall(`/companies/${companyId}/subscription`)
      commit('setRestrictions', res?.restrictions)
    },
  },
}
