


























import {Component } from 'vue-property-decorator'
import { Company } from '@/models'
import FileUpload from '@/components/document/FileUpload.vue'
import EditableSection from '@/components/common/EditableSection.vue'
import View from '@/View'

@Component({
  components: {
    FileUpload,
    EditableSection,
  },
})
export default class CompanySettings extends View {
  get company(): Company {
    return Company.query()
      .with('textBlocks')
      .with('contacts')
      .first() as Company
  }

  public async updateCompanyLogo(url: URL) {
    this.company.logo = `${url.origin}${url.pathname}`
    this.company.$persist()
  }

  public async removeCompanyLogo() {
    this.company.logo = ''
    this.company.$persist()
  }

  public initEdit() {
    this.company.$name = this.company.name
  }

  public cancelEdit() {
    this.company.name = this.company.$name
  }

  public saveCompanyName() {
    this.company.$persist()
    this.company.$name = ''
  }
}
