























import { Component } from "vue-property-decorator";
import { Template, TemplateType } from "../../models";

import RichTextEditor from "@/components/form/RichTextEditor.vue";
import RoleSwitch from "@/components/common/RoleSwitch.vue";
import View from "../../View";
import { apiCall } from "../../api";

@Component({
  components: {
    RichTextEditor,
    RoleSwitch,
  },
})
export default class TemplatesSettings extends View {
  get templates() {
    return Template.query().get();
  }

  get emailTypes() {
    return {
      invitation: "Invitation email",
      reminder: "Reminder",
    };
  }

  private timeouts: { [key: string]: any } = {};

  public tplByType(type: TemplateType) {
    return Template.query()
      .where("type", type)
      .where("role", this.$store.state.ui.roleSwitch)
      .first();
  }

  public async resetTemplates() {
    await Template.deleteAll();
    const res = await apiCall("/templates/reset", "post");
    await Template.create({
      data: res,
    });
  }

  public async templateUpdate(tpl: Template, event: any) {
    if (this.timeouts[tpl.templateId]) {
      clearTimeout(this.timeouts[tpl.templateId]);
    }
    this.timeouts[tpl.templateId] = setTimeout(() => tpl.$persist(), 1000);
  }
}
