





















































import { Component } from 'vue-property-decorator'
import View from '../View'
import { Notification } from '@/models'
import { clearBrowserStorage } from '@/services/StorageService'
import authService from '../services/AuthServices'

@Component
export default class Login extends View {
  public linkSent!: boolean
  public email!: string
  public loginToken!: string
  public invalidToken!: boolean

  public valid = false
  public submitting = false

  get error() {
    const { error } = this.$route.query
    return error
  }

  get authError() {
    return this.$store.getters['auth/authError']
  }

  public data() {
    return {
      email: '',
      loginToken: '',
      invalidToken: false,
      linkSent: false,
    }
  }

  public validateForm(e: any) {
    this.valid = false
    if (/^[^@]+@.+\..+$/.test(this.email)) {
      this.valid = true
    }

    if (this.valid) {
      this.login()
    }

    e.preventDefault()
  }

  public async validateTokenForm(e: any) {
    this.valid = false
    this.invalidToken = false
    if (/^([A-Z0-9]{3}-?){4}$/.test(this.loginToken)) {
      this.valid = true
    }

    e.preventDefault()

    if (this.valid) {
      try {
        await this.$store.dispatch('auth/loginCheck', {
          email: this.email,
          token: this.loginToken,
        })
        this.$router.push('/welcome')
      } catch (err) {
        this.invalidToken = true
      }
    }
  }

  public async login() {
    if (this.submitting) {
      return false
    }

    this.submitting = true
    this.loginToken = ''
    await this.$store.dispatch('auth/login', { email: this.email })
    this.submitting = false
    this.linkSent = true
  }

  public async loginWithSlack() {
    if (this.submitting) {
      return false
    }
    this.submitting = true
    const data = await this.$store.dispatch('slack/login')
    this.submitting = false

    if (data && data.url) {
      window.location = data.url
    }
  }

  public async mounted() {
    if (this.error) {
      Notification.add('error', 'There was an error')
    }
    await this.$store.dispatch('auth/logout')
  }
}
