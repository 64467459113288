import { BaseModel } from './base-model'
import { Company } from './company'
import { TemplateType, Role } from './types'
import emailTemplates from '@/data/email-templates'
import { Fields } from '@vuex-orm/core'

export class Template extends BaseModel {
  public static entity = 'templates'

  public static primaryKey = 'templateId'

  public static fields(): Fields {
    return {
      templateId: this.attr(undefined),
      company: this.hasOne(Company, 'companyId'),
      companyId: this.attr(''),
      isActive: this.attr(true),
      name: this.attr(''),
      type: this.attr(''),
      role: this.attr(''),
      description: this.attr(''),
      body: this.attr(''),
    }
  }

  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/templates')
      },
      $get ({ params }: any) {
        return (this as any).get(`/templates/${params.id}`)
      },
      $create ({ data }: any) {
        return (this as any).post('/templates', data)
      },
      $delete ({ params }: any) {
        return (this as any).delete(`/templates/${params.id}`, {
          delete: params.id
        })
      },
      $update ({ params, data }: any) {
        return (this as any).put(`/templates/${params.id}`, data)
      }
    }
  }

  public templateId: string
  public company: Company
  public companyId: string
  public isActive: boolean
  public name: string
  public type: TemplateType
  public role: Role
  public description: string
  public body: string
}

export const genTemplates = (company: Company | null) =>
  emailTemplates.map((template) => new Template({
    company,
    companyId: company && company.companyId,
    isActive: true,
    ...template,
  }))
