

















import { Component } from 'vue-property-decorator'
import View from '../View'

@Component({
  props: {
    addLabel: String,
    addActionRoute: String,
  },
})
export default class MenuZone extends View {
  public addLabel: string
  public addActionRoute: string

  public data() {
    return {
      menuItems: [
        { link: '/welcome', label: 'Welcome' },
        { link: '/company', label: 'Company' },
        { link: '/members', label: 'Members' },
        { link: '/apps', label: 'Apps' },
        { link: '/documents', label: 'Documents' },
      ],
    }
  }

  public showAddModal() {
    if (this.addActionRoute) {
      this.$router.push({
        name: this.addActionRoute,
      })
    } else {
      this.$store.commit('ui/toggleModal', true)
    }
  }
}
