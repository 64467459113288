








import { Component } from 'vue-property-decorator'
import { Document } from '@/models'
import View from '../../View'

@Component({
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
})
export default class MobileGridDocument extends View {
  public document: Document
}
