



































































import { Component } from 'vue-property-decorator'

import { Card } from 'vue-stripe-elements-plus'
import View from '../View'
import { App, Document, CompanyUserProfile } from '@/models'

@Component({
  components: {
    Card,
  },
})
export default class Billing extends View {
  public loaded = false
  public showForm = false
  public feedbackSubmitted = false
  public message = ''

  get appsCount() {
    return App.query().count() || 0
  }

  get docsCount() {
    return Document.query().count() || 0
  }

  get usersCount() {
    return CompanyUserProfile.query().count() || 0
  }

  get subscriptionStatus() {
    return this.$store.state.billing.subscriptionStatus
  }

  get subscriptionDue() {
    return this.$store.state.billing.subscriptionDue
  }

  get daysLeft() {
    return this.$store.state.billing.daysLeft
  }

  get activePlan() {
    return this.$store.state.billing.activePlan
  }

  get product() {
    return {
      name: '1 Year Subscription',
      description: 'Enjoy smooth experience',
      amount: 6990,
      currency: 'USD',
    }
  }

  public data() {
    return {
      complete: false,
      showForm: false,
      message: '',
      stripeOptions: {
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
    }
  }

  public async submitFeedback() {
    await this.$store.dispatch('auth/sendFeedback', this.message)
    this.feedbackSubmitted = true
  }

  public mounted() {
    this.$store.dispatch('billing/loadSubscription', this.$store.getters['auth/companyId'])

    // todo : re-enable
    return
    // if (window.hasOwnProperty('Stripe')) {
    //   this.loaded = true
    //   return
    // }

    // const stripeScript = document.createElement('script')
    // stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
    // stripeScript.onload = () => {
    //   this.loaded = true
    // }
    // document.head.appendChild(stripeScript)
  }

  public pay() {
    // createToken returns a Promise which resolves in a result object with
    // either a token or an error key.
    // See https://stripe.com/docs/api#tokens for the token object.
    // See https://stripe.com/docs/api#errors for the error object.
    // More general https://stripe.com/docs/stripe.js#stripe-create-token.
    // createToken().then((data) => console.log(data.token))
  }
}
