





























import { Component, Prop } from 'vue-property-decorator'
import ItemMenu from '@/components/common/ItemMenu.vue'
import InvitationStatus from '@/components/team/InvitationStatus.vue'
import MemberContactInfo from '@/components/team/MemberContactInfo.vue'
import { CompanyUserProfile } from '@/models'
import View from '../../View'

@Component({
  components: {
    ItemMenu,
    InvitationStatus,
    MemberContactInfo
  }
})
export default class GridMember extends View {
  @Prop({ type: Boolean, default: false })
  public selected: boolean;

  @Prop({ type: Object, required: true })
  public member: CompanyUserProfile;

  get sel() {
    return this.selected ? 'selected' : ''
  }

  get extraMenuItems() {
    return this.isAdmin
      ? [
        { action: 'invite', label: 'Invite' },
        { action: 'remove', label: 'Remove' }
      ]
      : []
  }

  get tag() {
    const { role } = this.member
    return ['tag', 'tag-role', `tag-${role}`]
  }

  public goToProfile() {
    this.$router.push(`/profile/${this.member.companyUserProfileId}`)
  }

  public async handleAction(action: string) {
    switch (action) {
    case 'view':
      this.goToProfile()
      break
    case 'remove':
      await CompanyUserProfile.softDelete(
        this.member.companyUserProfileId,
        `${this.member.name} was removed`
      )
      break
    case 'edit':
      this.$router.push({
        name: 'profile-edit',
        params: { user: this.member.companyUserProfileId }
      })
      break
    case 'invite':
      this.$router.push({
        name: 'profile-invite',
        params: { user: this.member.companyUserProfileId }
      })
      break
    }
  }
}
