





































































































import { Component } from 'vue-property-decorator'
import { Setting } from '../../models'
import FileUpload from '@/components/document/FileUpload.vue'
import MenuFooter from '@/components/preview/MenuFooter.vue'
import View from '../../View'

@Component({
  components: {
    FileUpload,
    MenuFooter,
  },
})
export default class LookAndFeelSettings extends View {
  get gradientFrom(): Setting {
    return this.getSetting('gradient-from')
  }

  get gradientTo(): Setting {
    return this.getSetting('gradient-to')
  }

  get linkColor(): Setting {
    return this.getSetting('link-color')
  }

  get showAboutMe(): Setting {
    return this.getSetting('show-about-me')
  }

  get showMyExpertise(): Setting {
    return this.getSetting('show-my-expertise')
  }

  get showInterests(): Setting {
    return this.getSetting('show-interests')
  }

  get showSocialProfiles(): Setting {
    return this.getSetting('show-social-profiles')
  }

  get showCandidates(): Setting {
    return this.getSetting('show-candidates')
  }

  public updateSettings(entity: Setting) {
    if (entity) {
      entity.$persist()
    }
  }

  public validateColor(value: string) {
    // tslint:disable max-line-length
    const re = /^(#[a-f0-9]{6}|#[a-f0-9]{3}|rgb *\( *[0-9]{1,3}%? *, *[0-9]{1,3}%? *, *[0-9]{1,3}%? *\)|rgba *\( *[0-9]{1,3}%? *, *[0-9]{1,3}%? *, *[0-9]{1,3}%? *, *[0-9]{1,3}%? *\)|black|green|silver|gray|olive|white|yellow|orange|maroon|navy|red|blue|purple|teal|fuchsia|aqua)$/i
    return re.test(value)
  }

  public resetColors() {
    const { gradientFrom, gradientTo, linkColor } = this
    gradientFrom.value = '#774AE2'
    gradientTo.value = '#4A90E2'
    linkColor.value = '#774AE2'

    this.updateSettings(gradientFrom)
    this.updateSettings(gradientTo)
    this.updateSettings(linkColor)
  }
}
