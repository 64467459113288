












import { Component, Watch } from 'vue-property-decorator'

import View from '../View'
import { Setting, App, Document } from '@/models'

@Component
export default class UserFooter extends View {
  public hidden = false
  private scrollDelta = 0
  private scrollPrev = 0

  get gradientFrom(): Setting {
    return this.getSetting('gradient-from')
  }

  get gradientTo(): Setting {
    return this.getSetting('gradient-to')
  }

  get styles() {
    if (this.gradientFrom && this.gradientTo) {
      return {
        background: `linear-gradient(-134deg, ${this.gradientTo.value} 0%, ${this.gradientFrom.value} 100%)`,
      }
    }
    return {}
  }

  get reloadKey() {
    return [
      'uf', // user-footer
      this.gradientFrom?.value ?? 'x',
      this.gradientTo?.value ?? 'y',
      App.query().count(),
      Document.query().count(),
    ].join('-')
  }

  get menuItems() {
    const items = [
      {
        title: 'Home',
        icon: 'icon-home-white.svg',
        route: '/welcome',
      },
      {
        title: 'Company',
        icon: 'icon-company-white.svg',
        route: '/company',
      },
      {
        title: 'Team',
        icon: 'icon-team-white.svg',
        route: '/members',
      },
      {
        title: 'Apps',
        icon: 'icon-apps-white.svg',
        route: '/apps',
      },
      {
        title: 'Docs',
        icon: 'icon-docs-white.svg',
        route: '/documents',
      },
    ]

    return items.filter(item => this.hasContent(item.title))
  }

  public hasContent(section: string) {
    // TODO same as MenuFooter ...
    switch (section) {
    case 'Apps':
      return App.query().where('isDeleted', false).count() > 0
    case 'Docs':
      return Document.query().where('isDeleted', false).count() > 0
    }

    return true
  }

  public data() {
    return {
      hidden: false,
    }
  }

  public scrollHandler() {
    this.scrollDelta = window.scrollY - this.scrollPrev
    this.scrollPrev = window.scrollY

    if (this.scrollDelta > 0) {
      this.hidden = true
    } else if (this.scrollDelta < 0) {
      this.hidden = false
    }
  }

  public mounted() {
    window.addEventListener('scroll', this.scrollHandler.bind(this))
  }

  public beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler.bind(this))
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange(newVal: any) {
    this.hidden = false
  }
}
