



































import { Component } from 'vue-property-decorator'

import GridContact from '@/components/contact/GridContact.vue'
import ContactPersonSelector from '@/components/form/ContactPersonSelector.vue'
import AdminActions from '@/components/common/AdminActions.vue'
import { Contact, Company, CompanyUserProfile } from '../../models'
import View from '../../View'

@Component({
  components: {
    GridContact,
    AdminActions,
    ContactPersonSelector,
  },
})
export default class ContactEdit extends View {
  get contactId() {
    return this.$route.params.id
  }

  public contact: any

  public async created() {
    const rec = Contact.find(this.contactId) as Contact
    if (this.contactId) {
      this.contact = new Contact(rec || {})
    }
  }

  public data() {
    return {
      contact: new Contact(),
    }
  }

  public async handleSave(closeCallback: () => void) {
    if (!this.contactId) {
      const company = Company.query().first()
      this.contact.companyId = company && company.companyId
    }
    await this.contact.$persist()
    closeCallback()
  }

  public contactSelected(item: CompanyUserProfile) {
    this.contact.profile = item
    this.contact.profileId = item.companyUserProfileId

    this.contact.name = item.name
    this.contact.email = item.email
  }
}
