import faker from '@/faker'
import { CompanyUserProfile } from '@/models'
import { BaseModel } from './base-model'

export class Contact extends BaseModel {
  public static entity = 'contacts'
  public static primaryKey = 'contactId'

  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/contacts')
      },
      $get ({ params }: any) {
        return (this as any).get(`/contacts/${params.id}`)
      },
      $create ({ data }: any) {
        return (this as any).post('/contacts', data)
      },
      $delete ({ params }: any) {
        return (this as any).delete(`/contacts/${params.id}`, {
          delete: params.id
        })
      },
      $update ({ params, data }: any) {
        return (this as any).put(`/contacts/${params.id}`, data)
      }
    }
  }

  public static fields() {
    return {
      contactId: this.attr(undefined),
      companyId: this.attr(''),
      department: this.attr(''),
      name: this.attr(''),
      email: this.attr(''),
      topics: this.attr(''),
      slackChannel: this.attr('#'),
      profile: this.belongsTo(CompanyUserProfile, 'profileId'),
      profileId: this.attr(''),
    }
  }

  public contactId: string
  public companyId: string
  public department: string | null
  public name: string
  public email: string
  public topics: string
  public slackChannel: string | null
  public profile: CompanyUserProfile | null
  public profileId: string | null
}

export const genContact = (companyId: string): Contact =>
  new Contact({
    contactId: faker.random.uuid(),
    companyId: companyId || faker.random.uuid(),
    department: faker.commerce.department(),
    name: faker.name.findName(),
    email: faker.internet.email(),
    topics: faker.company.catchPhrase(),
    slackChannel: `#${faker.commerce.department()}`,
    profile: null,
    profileId: null,
  })
