






import Plyr from 'plyr'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class VideoPlayer extends Vue {
  protected players: any[]

  get opts() {
    return {}
  }

  public mounted() {
    this.players = []
    const elms = this.$el.querySelectorAll('.video-player')
    elms.forEach((elm) => {
      this.players.push(
        new Plyr(elm, this.opts),
      )
    })
  }

  public beforeDestroy() {
    if (this.players && this.players.length) {
      this.players.forEach((player) => {
        try {
          player.destroy()
        } catch (e) {
          if (!(e.message === 'The YouTube player is not attached to the DOM.')) {
            // tslint:disable no-console
            console.error(e)
          }
        }
      })
      this.players = []
    }
  }
}
