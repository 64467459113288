












































import { Component, Vue, Prop } from 'vue-property-decorator'

import AddSection from '@/components/AddSection.vue'

import TextEditMenu from '@/components/common/TextEditMenu.vue'
import EditableSection from '@/components/common/EditableSection.vue'
import FileUpload from '@/components/document/FileUpload.vue'
import MobileView from '@/components/preview/MobileView.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import VideoPlayer from '@/components/common/VideoPlayer.vue'
import { TextBlock, TextBlockPageRef, CompanyUserProfile } from '@/models'

@Component({
  components: {
    RichTextEditor,
    AddSection,
    MobileView,
    FileUpload,
    TextEditMenu,
    EditableSection,
    VideoPlayer,
  },
})
export default class EditableTextBlock extends Vue {
  @Prop({ type: Boolean, default: true })
  public showAddSection: boolean

  @Prop({ type: Boolean, default: true })
  public canRemove: boolean

  @Prop({ type: String, default: 'company' })
  public pageRef: TextBlockPageRef

  @Prop({ type: Object, required: true })
  public textBlock: TextBlock

  get roleSwitch() {
    return this.$store.state.ui.roleSwitch
  }

  get extraVars() {
    const profile = CompanyUserProfile.find(this.$store.state.auth.profileId) as CompanyUserProfile
    return {
      profile,
    }
  }

  public initEdit(textBlock: TextBlock) {
    // todo ? smarter way?
    textBlock.$title = textBlock.title
    textBlock.$description = textBlock.description
    this.$store.commit('ui/setEditorActive', true)
    this.$store.commit('ui/setFocusedSection', { type: 'textBlock', id: textBlock.textBlockId })
  }

  public cancelEdit(textBlock: TextBlock) {
    // todo ? smarter way?
    textBlock.title = textBlock.$title
    textBlock.description = textBlock.$description
    this.$store.commit('ui/setEditorActive', false)
    this.$store.commit('ui/setFocusedSection', { type: '', id: '' })
  }

  public async saveTextBlock(textBlock: TextBlock) {
    // duplicate on different roleSwitch
    const shouldDuplicate = textBlock.visibility.length > 1 && this.roleSwitch

    if (shouldDuplicate) {
      const newTextBlock = TextBlock.clone(textBlock)
      newTextBlock.visibility = [this.roleSwitch]
      await newTextBlock.$persist()

      textBlock.removeRole(this.roleSwitch)
      this.cancelEdit(textBlock)
    }

    await textBlock.$persist()
    this.$store.commit('ui/setEditorActive', false)
  }

  public async removeTextBlock(textBlock: TextBlock) {
    // last text blocks cannot be removed
    if (this.canRemove) {
      if (textBlock.visibility.length > 1) {
        textBlock.removeRole(this.roleSwitch)
        await textBlock.$persist()
      } else {
        await TextBlock.softDelete(textBlock.textBlockId, 'Text block removed')
        this.$store.commit('ui/setEditorActive', false)
      }
    }
  }

  public moveUp(textBlock: TextBlock) {
    TextBlock.moveUp(textBlock, this.roleSwitch)
  }

  public moveDown(textBlock: TextBlock) {
    TextBlock.moveDown(textBlock, this.roleSwitch)
  }

  public async changeImage(textBlock: TextBlock, url: URL) {
    textBlock.image = `${url.origin}${url.pathname}`
  }
}
