































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class EditableSection extends Vue {
  public menuTextActive: boolean
  public isEdit: boolean

  @Prop({ type: Boolean, default: true })
  public canRemove: boolean

  get isAdmin() {
    return this.$store.getters['auth/isAdmin']
  }

  public data() {
    return {
      menuTextActive: false,
      isEdit: false,
    }
  }

  public startEdit() {
    if (this.isAdmin) {
      this.$emit('edit')
      this.isEdit = true
    }
  }

  public cancelEdit() {
    this.isEdit = false
    this.$emit('cancel')
  }

  public commitEdit() {
    this.$emit('save')
    this.isEdit = false
  }
}
