



































import { Component, Prop, Vue } from 'vue-property-decorator'
import FileUpload from '@/components/document/FileUpload.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import { TextBlock, Company } from '@/models'
import { TextBlockPageRef } from '@/models/types'

@Component({
  components: {
    FileUpload,
    RichTextEditor
  }
})
export default class AddSection extends Vue {
  public activeSection: string;

  @Prop({ type: Object, required: false })
  public after: TextBlock | null;

  @Prop({ type: Object, required: false })
  public before: TextBlock | null;

  @Prop({ type: String, default: 'company' })
  public pageRef: TextBlockPageRef;

  public textBlock: TextBlock;

  get roleSwitch() {
    return this.$store.state.ui.roleSwitch
  }

  get isAdmin() {
    return this.$store.getters['auth/isAdmin']
  }

  public data() {
    return {
      activeSection: '',
      textBlock: new TextBlock()
    }
  }

  public setActiveSection(section: string) {
    if (this.activeSection === section) {
      this.activeSection = ''
      this.$store.commit('ui/setEditorActive', false)
    } else {
      this.activeSection = section
      this.textBlock.hasImage = section === 'image'
      this.$store.commit('ui/setEditorActive', true)
    }
  }

  public async addTextBlock() {
    const { textBlock } = this
    const company = Company.query().first()
    textBlock.companyId = company ? company.companyId : ''
    if (this.after) {
      textBlock.order = this.after.order
      await TextBlock.allUp(this.roleSwitch, this.pageRef, this.after.order)
    } else if (this.before) {
      textBlock.order = this.before.order + 1
      await TextBlock.allDown(this.roleSwitch, this.pageRef, this.before.order)
    } else {
      textBlock.order = 0
    }
    textBlock.visibility = [this.roleSwitch]
    textBlock.pageRef = this.pageRef

    if (!textBlock.image && textBlock.isEmpty()) {
      return
    }

    await textBlock.$persist()

    this.$store.commit('ui/setEditorActive', false)

    // reset
    this.activeSection = ''
    this.textBlock = new TextBlock()
  }

  public async addImage(url: URL) {
    this.textBlock.image = `${url.origin}${url.pathname}`

    // don't add immediately, allow caption to be added
    // return this.addTextBlock()
  }
}
