








































import { Component } from 'vue-property-decorator'

import { CatalogApp, App, catalogToApp, Company } from '../../models'
import View from '../../View'
import { ApiError } from '../../api'
import SubscriptionLimit from '../../components/common/SubscriptionLimit.vue'

@Component({
  components: {
    SubscriptionLimit
  },
})
export default class AppFromCatalog extends View {
  public searchApp = ''
  public error!: string

  get catalog() {
    return CatalogApp.all()
  }

  get foundNothing() {
    return this.searchApp && this.modalAddApps.length === 0
  }

  get modalAddApps() {
    const { searchApp } = this

    const query = CatalogApp.query()
    if (searchApp) {
      const re = new RegExp(searchApp, 'i')
      query.where('name', (name: string) => name.match(re))
    }

    // exclude installed
    query.where('name', (name: string) =>
      App.query().where('name', name).count() === 0)


    return query.limit(6).get()
  }

  public data() {
    return {
      error: '',
    }
  }

  public mounted() {
    this.$store.commit('team/setSubscriptionLimit', {
      resource: 'apps',
      value: false
    })
  }

  public isExternal(url: string) {
    return url.match(/^https?:\/\//i)
  }

  public async handleAdd(item: CatalogApp) {
    const company = Company.query().first()
    const app = catalogToApp(item, company ? company.companyId : '')
    try {
      await app.$persist()
      this.$router.push({ name: 'apps' })
    } catch (err) {
      if (err instanceof ApiError && err.statusCode === 402) {
        this.$store.commit('team/setSubscriptionLimit', {
          resource: 'apps',
          value: true
        })
      } else {
        this.error = err.message
      }
    }
  }
}
