











import Vue from 'vue'
import AddToSlack from '../slack/AddToSlack.vue'

export default Vue.extend({
  name: 'SlackSettings',
  components: {
    AddToSlack,
  },
})
