import faker from '@/faker'
import { Company } from './company'
import { BaseModel } from './base-model'
import { Fields } from '@vuex-orm/core'

export const genTeam = (companyId: string = faker.random.uuid()) =>
  new Team({
    companyId: companyId || faker.random.uuid(),
    name: faker.name.jobArea(),
  })

export class Team extends BaseModel {
  public static entity = 'teams'

  public static primaryKey = 'teamId'

  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/teams')
      },
      $get ({ params }: any) {
        return (this as any).get(`/teams/${params.id}`)
      },
      $create ({ data }: any) {
        return (this as any).post('/teams', data)
      },
      $delete ({ params }: any) {
        return (this as any).delete(`/teams/${params.id}`, {
          delete: params.id
        })
      },
      $update ({ params, data }: any) {
        return (this as any).put(`/teams/${params.id}`, data)
      }
    }
  }

  public static fields(): Fields {
    return {
      teamId: this.attr(undefined),
      companyId: this.attr(''),
      name: this.attr(''),

      company: this.belongsTo(Company, 'companyId'),
    }
  }

  public teamId: string
  public companyId: string
  public name: string
  public company?: Company | null
}
