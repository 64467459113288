import VuePageTitle from 'vue-page-title'

declare module 'vue/types/vue' {
  interface Vue {
    $title: string
  }
}

const initPageTitle = (VueLocal: any, router: any) => {
  VueLocal.use(VuePageTitle, {
    router,
    prefix: 'Uelco - ',
  })
}

export default initPageTitle
