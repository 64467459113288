






























import { Component, Provide } from 'vue-property-decorator'

import MembersFilter from '@/components/common/MembersFilter.vue'
import MobileView from '@/components/preview/MobileView.vue'
import GridMember from '@/components/team/GridMember.vue'
import MenuZone from '@/components/MenuZone.vue'

import { CompanyUserProfile, Team } from '@/models'
import View from '../../View'

@Component({
  components: {
    MobileView,
    GridMember,
    MenuZone,
    MembersFilter,
  },
})
export default class Members extends View {
  @Provide() public newTeamName = ''

  get filter() {
    return this.$store.state.team.filter
  }

  get hasTeams() {
    return this.teams.length > 0
  }

  get teams() {
    return Team.query().get() as Team[]
  }

  get hasMembers() {
    return CompanyUserProfile.query().count() > 0
  }

  get membersLoaded(): boolean {
    return this.$store.state.team.membersLoaded
  }

  get selectedTeamIds(): string[] {
    // todo: reimplement
    return []
    // (
    //   this.teams
    //     // .filter((a: { selected?: boolean }) => a.selected === true)
    //     .reduce((arr: string[], team: Team) => {
    //       arr.push(team.teamId)
    //       return arr
    //     }, new Array())
    // )
  }

  get filteredMembers(): CompanyUserProfile[] {
    const { selectedTeamIds, filter } = this

    const query = CompanyUserProfile.query()
      .withAll()

    query.orderBy('createdAt', 'desc')
    query.where('isDeleted', false)

    if (selectedTeamIds.length !== 0) {
      query.where(
        'teamId',
        (value: string) => selectedTeamIds.indexOf(value) !== -1,
      )
    }

    if (this.isAdmin && filter.role !== '') {
      query.where('role', filter.role)
    }
    if (!this.isAdmin) {
      const { ownProfile } = this
      query.where(
        (row: CompanyUserProfile) => row.role !== 'candidate' ||
          row.companyUserProfileId === ownProfile?.companyUserProfileId
      )
    }

    return query.get() as CompanyUserProfile[]
  }

  public isSelected(index: number) {
    return this.selectedIndex === index
  }

  public async created() {
    await this.$store.dispatch('team/load')
  }

  public mounted() {
    this.$store.commit('ui/setMobilePreviewPage', 'AppTeam')
  }

  public showTeamAdd() {
    this.$store.commit('ui/setTeamAdd', true)
    setTimeout(() => {
      const teamName: HTMLInputElement = this.$refs
        .teamName as HTMLInputElement
      teamName.focus()
    }, 10)
  }

  public addNewTeam() {
    this.$store.dispatch('team/add', this.newTeamName)
    this.$store.commit('ui/setTeamAdd', false)
    this.newTeamName = ''
  }
}
