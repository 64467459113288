










import { Component, Vue } from 'vue-property-decorator'

import { emojisList } from '@/data/emojis'

interface PlainObject {
  [key: string]: string
}

@Component({})
export default class EmojiList extends Vue {
  public filter!: string

  public filteredEmojis(items: PlainObject) {
    const { filter } = this
    if (!filter) {
      return items
    }
    const filtered: PlainObject = {}
    const re = new RegExp(filter.replace(/[^0-9a-z\s]+/, ''), 'i')
    Object.keys(items).filter((key) => {
      if (key.match(re)) {
        filtered[key] = items[key]
      }
    })
    return filtered
  }

  public data() {
    return {
      emojis: emojisList,
      filter: '',
    }
  }

  public pickEmoji(event: MouseEvent) {
    const elm = event.target as HTMLElement
    if (elm.nodeName !== 'SPAN') {
      return
    }

    const text = elm.innerText.trim()
    if (text) {
      this.$emit('selected', text)
    }
  }
}
