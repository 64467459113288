import faker from '@/faker'
import { TextBlock } from './text-block'
import { Team } from '.'
import { CompanyUserProfile } from './company-user-profile'
import { Document } from './document'
import { Contact, genContact } from './contact'
import { companySocialNetworks, socialNetworkDomains } from '@/data/social-networks'
import { BaseModel } from './base-model'

export class Company extends BaseModel {
  public static entity = 'companies'
  public static primaryKey = 'companyId'

  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/companies')
      },
      $get ({ params }: any) {
        return (this as any).get(`/companies/${params.id}`)
      },
      $create ({ data }: any) {
        return (this as any).post('/companies', data)
      },
      $delete ({ params }: any) {
        return (this as any).delete(`/companies/${params.id}`, {
          delete: params.id
        })
      },
      $update ({ params, data }: any) {
        return (this as any).put(`/companies/${params.id}`, data)
      }
    }
  }

  public static fields() {
    return {
      companyId: this.attr(''),
      ownerId: this.attr(null),
      name: this.attr(''),
      url: this.attr(''),
      logo: this.attr(''),

      textBlocks: this.hasMany(TextBlock, 'companyId'),
      teams: this.hasMany(Team, 'companyId'),
      profiles: this.hasMany(CompanyUserProfile, 'companyId'),
      documents: this.hasMany(Document, 'companyId'),

      contacts: this.hasMany(Contact, 'companyId'),

      social: this.attr({}),
    }
  }

  public static normalizeSocialLinks(links: any) {
    return Object.keys(links).reduce((res: any, cur: string) => {
      const domain = socialNetworkDomains[cur.toLowerCase()]
      if (domain && links[cur].includes(domain)) {
        res[cur] = links[cur].replace(/\/$/, '').replace(domain, '').trim()
      } else if (typeof links[cur] === 'string') {
        res[cur] = links[cur].trim()
      }
      return res
    }, {})
  }

  public companyId: string
  public userId: string
  public name: string
  public url: string
  public logo: string
  public textBlocks?: TextBlock[]
  public teams?: Team[]
  public profiles?: CompanyUserProfile[]
  public documents?: Document[]
  public contacts?: Contact[]

  public social!: object

  // virtual
  public $name: string

}

export const genCompany = (companyId = faker.random.uuid()) =>
  new Company({
    companyId,
    ownerId: faker.random.uuid(),
    name: faker.company.companyName(),
    url: faker.internet.url(),
    logo: faker.internet.avatar(),
    textBlocks: [],
    contacts: Array.from({ length: 3 }, () => genContact(companyId)),
    social: Array.from({ length: 4 }).reduce((val: any) => {
      val[faker.random.arrayElement(companySocialNetworks)] = faker.internet.url()
      return val
    }, {}),
  })
