












import { Component, Vue, Model } from 'vue-property-decorator'

import MediumEditor from 'vue2-medium-editor'

MediumEditor.MediumEditor.extensions.form.prototype.formSaveLabel =
  '<span class="icon-checkmark"></span>'
MediumEditor.MediumEditor.extensions.form.prototype.formCloseLabel =
  '<span class="icon-close"></span>'
MediumEditor.MediumEditor.extensions.anchor.prototype.contentDefault =
  '<span class="icon-link"></span>'

const boldBtn = {
  name: 'bold',
  action: 'bold',
  aria: 'bold',
  tagNames: ['b', 'strong'],
  style: {
    prop: 'font-weight',
    value: '700|bold',
  },
  useQueryState: true,
  contentDefault: '<b>B</b>',
  contentFA: '<i class="fa fa-bold"></i>',
}

const italicBtn = {
  name: 'italic',
  action: 'italic',
  aria: 'italic',
  tagNames: ['i', 'em'],
  style: {
    prop: 'font-style',
    value: 'italic',
  },
  useQueryState: true,
  contentDefault: '<b><i>I</i></b>',
  contentFA: '<i class="fa fa-italic"></i>',
}
/*const underlineBtn = {
  name: 'underline',
  action: 'underline',
  aria: 'underline',
  tagNames: ['u'],
  style: {
    prop: 'text-decoration',
    value: 'underline',
  },
  useQueryState: true,
  contentDefault: '<b><u>U</u></b>',
  contentFA: '<i class="fa fa-underline"></i>',
}
*/
const orderedlistBtn = {
  name: 'orderedlist',
  action: 'insertorderedlist',
  aria: 'ordered list',
  tagNames: ['ol'],
  useQueryState: true,
  contentDefault: '<b>1.</b>',
  contentFA: '<i class="fa fa-list-ol"></i>',
}
const removeFormatBtn = {
  name: 'removeFormat',
  aria: 'remove formatting',
  action: 'removeFormat',
  contentDefault: '<span class="icon-remove"></span>',
  contentFA: '<i class="fa fa-eraser"></i>',
} /*
const quoteBtn = {
  name: 'quote',
  action: 'append-blockquote',
  aria: 'blockquote',
  tagNames: ['blockquote'],
  contentDefault: '<b>&ldquo;</b>',
  contentFA: '<i class="fa fa-quote-right"></i>',
}
const preBtn = {
  name: 'pre',
  action: 'append-pre',
  aria: 'preformatted text',
  tagNames: ['pre'],
  contentDefault: '<b>Code</b>',
  contentFA: '<i class="fa fa-code fa-lg"></i>',
}
*/
@Component({
  components: {
    MediumEditor,
  },
})
export default class RichTextEditor extends Vue {
  @Model('input', {
    type: String,
  })
  public value: string

  get richTextEditor() {
    return true // could be prop
  }

  get editorOptions() {
    // ref: https://github.com/yabwe/medium-editor
    return {
      autoLink: false,
      toolbar: {
        buttons: [
          boldBtn,
          italicBtn,
          'anchor',
          orderedlistBtn,
          removeFormatBtn,
        ],
        sticky: true,
        align: 'center',
        diffLeft: 0,
        diffTop: -10,
      },
      anchor: {
        placeholderText: 'Paste a link here',
      },
    }
  }
}
