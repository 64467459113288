












import { Component, Vue, Prop } from 'vue-property-decorator'
import { CompanyUserProfile } from '@/models'

import DateSelector from '@/components/form/DateSelector.vue'

@Component({
  components: {
    DateSelector,
  },
})
export default class RoleDate extends Vue {
  @Prop({ type: Object })
  public profile!: CompanyUserProfile

  public expiresIn!: string

  public data() {
    return {
      expiresIn: '7',
    }
  }

  public setExpiresAt(value: string) {
    const days = parseInt(value, 10)
    if (!isNaN(days)) {
      const date = new Date()
      date.setDate(date.getDate() + days)
      this.profile.inviteExpiresAt = date
      this.expiresIn = value
    }
  }

  public setFormValues(value: Date) {
    const date = new Date(value)
    if (date) {
      const aDay = 1000 * 60 * 60 * 24
      const toDays = Math.ceil((date.getTime() - new Date().getTime()) / aDay)
      this.expiresIn = `${toDays}`
    }
  }

  public mounted() {
    if (this.profile.role === 'candidate') {
      if (this.profile.inviteExpiresAt) {
        this.setFormValues(this.profile.inviteExpiresAt)
      } else {
        this.setExpiresAt(this.expiresIn)
      }
    }
  }
}
