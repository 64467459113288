






















import { Component, Vue } from 'vue-property-decorator'
import { Notification } from '@/models'
import database from '@/models/database'
import { BaseModel } from '../models/base-model'

const maxNotifications = 5
const autoDismissTimeout = 5 * 1000

@Component
export default class Notifications extends Vue {
  get notifications(): Notification[] {
    return Notification.query()
      .limit(maxNotifications)
      .all() as Notification[]
  }

  public notificationClass(notification: Notification) {
    return notification && notification.type
  }

  public showClose(notification: Notification) {
    return ['error', 'warning'].indexOf(notification.type) !== -1
  }

  public dismiss(notification: Notification) {
    Notification.markShownAndDelete(notification)
  }

  public appearIn(notification: Notification) {
    if (notification.type === 'success') {
      setTimeout(() => this.dismiss(notification), autoDismissTimeout)
    } else if (notification.type === 'warning') {
      setTimeout(() => this.dismiss(notification), 2 * autoDismissTimeout)
    }
  }

  public async handleAction(notification: Notification) {
    const { action } = notification
    if (action.entity) {
      await (database.model(action.entity) as typeof BaseModel).api().$update({
        params: { id: action.id },
        data: action.fields,
      })
    }
    if (action.callback) {
      action.callback()
    }
    this.dismiss(notification)
  }
}
