
















import { Component } from 'vue-property-decorator'
import View from '../View'
import { Role } from '@/models'
import CompanyLogo from '@/components/company/CompanyLogo.vue'

@Component({
  components: {
    CompanyLogo,
  },
})
export default class UserHeader extends View {

  get role() {
    return this.$store.state.ui.roleSwitch
  }

  get roles() {
    return ['member', 'hire', 'candidate', 'admin']
  }

  public setRole(role: Role | 'admin') {
    if (role !== 'admin') {
      this.$store.commit('ui/setRoleSwitch', role)
      this.$store.commit('auth/setIsAdmin', false)
    } else {
      this.$store.commit('auth/setIsAdmin', true)
    }
  }

  public hasRole(role: Role | 'admin') {
    if (role === 'admin') {
      return this.isAdmin
    }
    return this.role === role
  }

}
