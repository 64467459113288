





































import { Component } from 'vue-property-decorator'

import AdminActions from '@/components/common/AdminActions.vue'
import MemberCard from '@/components/team/MemberCard.vue'
import FileUpload from '@/components/document/FileUpload.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import ExpertiseSelector from '@/components/form/ExpertiseSelector.vue'
import EmojiList from '@/components/form/EmojiList.vue'
import MobileView from '@/components/preview/MobileView.vue'
import {
  CompanyUserProfile,
  Notification,
  Template,
  Role,
} from '@/models'
import View from '@/View'
import templateVars from '@/filters/TemplateVars'
import { apiCall } from '../../api'

@Component({
  components: {
    MobileView,
    MemberCard,
    FileUpload,
    AdminActions,
    RichTextEditor,
    ExpertiseSelector,
    EmojiList,
  },
})
export default class MemberInvite extends View {
  private message: string
  private prevMobState: boolean
  private prevRole: Role

  get userId() {
    return this.$route.params.user
  }

  get profile(): CompanyUserProfile {
    return CompanyUserProfile.find(this.userId) as CompanyUserProfile
  }

  get template(): Template {
    return Template.query()
      .where('type', 'invitation')
      .where('role', this.profile.role)
      .first() as Template
  }

  get parsedTemplate() {
    if (!this.template) {
      return ''
    }
    if (!this.message) {
      this.message = templateVars(this.template.body, {
        profile: this.profile,
      })
    }
    return this.message
  }

  set parsedTemplate(value: string) {
    this.message = value
  }

  public dateInPast(value: Date) {
    return new Date().getTime() > new Date(value).getTime()
  }

  public created() {
    this.prevMobState = this.$store.state.ui.mobilePreview
    this.prevRole = this.$store.state.ui.roleSwitch

    this.$store.dispatch('settings/loadTemplates')
    this.$store.commit('ui/toggleMobilePreview', true)
    this.$store.commit('ui/setRoleSwitch', this.profile.role)
    this.$store.commit('ui/setMobilePreviewProfile', this.userId)

    this.parsedTemplate = templateVars(this.template.body, {
      profile: this.profile,
    })
  }

  public beforeDestroy() {
    this.$store.commit('ui/toggleMobilePreview', this.prevMobState)
    this.$store.commit('ui/setRoleSwitch', this.prevRole)
    this.$store.commit('ui/setMobilePreviewProfile', null)
  }

  public mounted() {
    this.$store.commit('ui/setMobilePreviewPage', 'AppHome')
  }

  public async sendInvite() {
    // send invite
    await apiCall(`/invites/${this.profile.companyUserProfileId}`, 'POST', {
      message: this.parsedTemplate,
    })
    // refetch entity
    await CompanyUserProfile.api().$get({
      params: { id: this.profile.companyUserProfileId },
    })

    Notification.add('success', `Invite sent to ${this.profile.email}`)

    this.goBack('/members')
  }
}
