














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  props: {
    menuTextActive: {
      type: Boolean,
      default: false,
    },
  },
})
export default class TextEditMenu extends Vue {
  @Prop() public menuTextActive: boolean
}
