import { render, staticRenderFns } from "./LogoHeader.vue?vue&type=template&id=469e3abc&scoped=true&"
import script from "./LogoHeader.vue?vue&type=script&lang=ts&"
export * from "./LogoHeader.vue?vue&type=script&lang=ts&"
import style0 from "./LogoHeader.vue?vue&type=style&index=0&id=469e3abc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "469e3abc",
  null
  
)

export default component.exports