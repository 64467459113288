







import { Component } from 'vue-property-decorator'
import View from '../../View'
import CompanyLogo from '../company/CompanyLogo.vue'

@Component({
  components: {
    CompanyLogo,
  }
})
export default class LogoHeader extends View {}
