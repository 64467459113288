
import { Model, Fields } from '@vuex-orm/core'
import { NotificationType, NotificationAction } from './types'

export class Notification extends Model {
  public static entity = 'notifications'

  public static primaryKey = 'id'

  public static fields(): Fields {
    return {
      id: this.uid(),
      type: this.attr('info'),
      text: this.attr(''),
      isShown: this.attr(false),
      action: this.attr(''),
      createdAt: this.attr(new Date()),
    }
  }

  public static async add(type: NotificationType, text: string, action?: any) {
    const notification = new Notification({
      type,
      text,
      action,
      isShown: false,
    })
    await notification.$save()

    return notification
  }

  public static async markShownAndDelete(notification: Notification) {
    notification.isShown = true
    notification.$save()
    setTimeout(async () => {
      await Notification.delete(notification.id)
    }, 500)
  }

  public id: string
  public type: NotificationType
  public text: string
  public isShown: boolean
  public action: NotificationAction
  public createdAt: Date

}
