























import { Component } from 'vue-property-decorator'
import { TextBlockPageRef } from '@/models'
import RoleSwitch from '@/components/common/RoleSwitch.vue'
import EditableTextBlock from '@/components/company/EditableTextBlock.vue'
import MobileView from '@/components/preview/MobileView.vue'
import EditableTextBlocksMixin from '@/views/mixins/EditableTextBlocksMixin'
import FileUpload from '@/components/document/FileUpload.vue'

@Component({
  components: {
    FileUpload,
    MobileView,
    EditableTextBlock,
    RoleSwitch,
  },
})
export default class CompanyView extends EditableTextBlocksMixin {
  protected pageRef: TextBlockPageRef = 'home'

  get companyId() {
    return this.$store.state.auth.companyId
  }

  public async mounted() {
    await this.$store.dispatch('company/load', { companyId: this.companyId })
    this.$store.commit('ui/setMobilePreviewPage', 'AppHome')

    if (!this.$store.state.ui.initialLoadDone) {
      await Promise.all([
        this.$store.dispatch('document/load'),
        this.$store.dispatch('app/load'),
        this.$store.dispatch('team/load'),
      ])
      this.$store.commit('ui/setInitialLoadDone', true)
    }
  }
}
