import Vue from 'vue'

import HelpWelcome from './HelpWelcome.vue'
import HelpCompany from './HelpCompany.vue'
import HelpMembers from './HelpMembers.vue'
import HelpApps from './HelpApps.vue'
import HelpDocuments from './HelpDocuments.vue'
import HelpSettings from './HelpSettings.vue'
import HelpProfile from './HelpProfile.vue'
import { Dict } from '@/models'

const componentMap: Dict<any> = {
  welcome: HelpWelcome,
  company: HelpCompany,
  members: HelpMembers,
  apps: HelpApps,
  documents: HelpDocuments,
  settings: HelpSettings,
  profile: HelpProfile,
  profileMe: HelpProfile,
}

const ContextHelp = Vue.extend({
  functional: true,
  props: {
    page: { type: String },
  },
  render(createElement: any, context) {
    const page = context.props.page
    if (!page || !componentMap[page]) {
      return null
    }
    return createElement(componentMap[page])
  },
})

export default ContextHelp
