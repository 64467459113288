




















import { Component, Vue, Prop } from 'vue-property-decorator'
import { CompanyUserProfile } from '@/models'
import RoleDate from '@/components/team/RoleDate.vue'
import MemberAvatar from '@/components/team/MemberAvatar.vue'

@Component({
  components: {
    RoleDate,
    MemberAvatar,
  },
})
export default class MemberCard extends Vue {
  @Prop({ type: Object })
  public member!: CompanyUserProfile
  @Prop() public placeholder!: any

  get tag() {
    const { role } = this.member
    return ['tag', 'tag-role', `tag-${role}`]
  }
}
