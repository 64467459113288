




























import { Component } from 'vue-property-decorator'
import View from '@/View'

@Component
export default class MobileAppHowto extends View {

  get app() {
    const { mobilePreviewParams = {} } = this.$store.state.ui
    return mobilePreviewParams.app
  }

  public closeHowto() {
    this.$store.commit('ui/setMobilePreviewPage', 'AppApps', {})
  }

}
