





















import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Feedback extends Vue {
  public message: string

  get showFeedbackArea() {
    return this.$store.state.ui.feedback
  }

  get feedbackSubmitted() {
    return this.$store.state.ui.feedbackSubmitted
  }

  get flags() {
    return this.$store.state.ui.flags
  }

  public data() {
    return {
      message: '',
    }
  }

  public openFeedbackArea() {
    this.$store.commit('ui/toggleFeedbackArea', true)
  }

  public closeFeedbackArea() {
    this.$store.commit('ui/toggleFeedbackArea', false)
    if (this.feedbackSubmitted) {
      this.$store.commit('ui/toggleFeedbackSubmitted', false)
      this.message = ''
    }
  }

  public async submitFeedback() {
    this.$store.commit('ui/toggleFeedbackSubmitted', true)
    await this.$store.dispatch('auth/sendFeedback', this.message)
    this.closeFeedbackArea()
  }
}
