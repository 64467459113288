import faker from '@/faker'
import { Model } from '@vuex-orm/core'
import { App } from '.'
import { rolesList } from './types'
import mainAppsList from '@/data/main-apps-list'
import otherAppsList from '@/data/other-apps-list'

export const catalogToApp = (catalogApp: CatalogApp, companyId: string): App =>
  new App({
    companyId,
    name: catalogApp.name,
    icon: catalogApp.logoUrl || '_missing.svg', //TODO add name if logo is  missing
    catalog: true,
    url: catalogApp.url || faker.internet.url(),
    header: catalogApp.description || faker.company.catchPhrase(), // TODO missing header
    description: catalogApp.description || faker.lorem.paragraph(),
    visibility: [...rolesList],
    contactPerson: '',
    guideUrl: `https://${faker.internet.domainName()}.${faker.internet.domainSuffix()}/how-to`,
  })

export class CatalogApp extends Model {
  public static entity = 'catalogApps'

  public static methodConf = {
    http: {
      url: '/apps/catalog',
    },
  }

  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/apps/catalog')
      }
    }
  }

  public static fields() {
    return {
      id: this.uid(),
      priority: this.attr(''),
      name: this.attr(''),
      url: this.attr(''),
      companyLink: this.attr(''),
      logoUrl: this.attr(''),
      logoIcon: this.attr(''),
      description: this.attr(''),
      tags: this.attr([]),
    }
  }

  public id: string | number
  public priority: number
  public name: string
  public url: string
  public companyLink: string
  public logoUrl: string
  public logoIcon: string
  public description: string
  public tags?: string[]
}

// todo: lazyload
export const catalog = [
  ...mainAppsList,
  ...otherAppsList,
]
