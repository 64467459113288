import { Module } from 'vuex'
import { IRootState } from '@/store/root'
import {
  Team,
  CompanyUserProfile,
  genTeam,
  genCompanyUserProfile,
} from '@/models'

interface TeamFilter {
  role: string
}

export interface ITeamState {
  teamsLoaded: boolean
  membersLoaded: boolean
  filter: TeamFilter
  error?: any
  subscriptionLimit: {
    [key: string]: boolean;
  }
}

export const moduleTeam: Module<ITeamState, IRootState> = {
  namespaced: true,
  state: {
    teamsLoaded: false,
    membersLoaded: false,
    filter: {
      role: '',
    },
    error: undefined,
    subscriptionLimit: {
      users: false,
      docs: false,
      apps: false,
    }
  },
  getters: {},
  mutations: {
    addTeam(state, team: Team) {
      Team.insert({
        data: team,
      })
    },
    setFilter(state, filter: TeamFilter) {
      state.filter = filter
    },
    setTeamsLoaded(state, value = true) {
      state.teamsLoaded = value
    },
    setMembersLoaded(state, value = true) {
      state.membersLoaded = value
    },
    setError(state, value: any) {
      state.error = value
    },
    setSubscriptionLimit(state, payload: { resource: string, value: boolean }) {
      state.subscriptionLimit[payload.resource] = payload.value
    },
  },
  actions: {
    async loadFake({ commit }, { companyId } = {}) {
      let teams = Team.query().get() as Team[]
      if (teams.length === 0) {
        teams = Array.from({ length: 6 }, () => genTeam(companyId))
        await Team.api().$create({
          data: teams,
        })
        commit('setTeamsLoaded', true)
      }

      const members = Array.from({ length: 21 }, () =>
        genCompanyUserProfile(teams, companyId),
      )
      await CompanyUserProfile.api().$create({
        data: members,
      })
      commit('setMembersLoaded', true)
    },
    async load() {
      await Team.api().$fetch()
      await CompanyUserProfile.api().$fetch()
    },
    async add(ctx, name) {
      await Team.api().$create({
        data: {
          name,
        },
      })
    },
  },
}
