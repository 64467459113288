






import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Logout extends Vue {

  public async mounted() {
    await this.$store.dispatch('auth/logout')
    this.$store.dispatch('entities/deleteAll')
    if (localStorage) {
      localStorage.setItem('redirect', '')
    }
    setTimeout(() => {
      if (window && window.location) {
        window.location.href = '/'
      }
    }, 400)
  }
}
