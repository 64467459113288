import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import VuexPersistence from 'vuex-persist'

import { IRootState } from '@/store/root'
import { moduleAuth } from '@/store/auth'
import { moduleBilling } from '@/store/billing'
import { moduleTeam } from '@/store/team'
import { moduleUi } from '@/store/ui'
import { moduleApp } from '@/store/app'
import { moduleDocument } from '@/store/document'
import { moduleCompany } from '@/store/company'
import { moduleSlack } from '@/store/slack'
import database from '../models/database'
import { moduleSettings } from './settings'

import browserStorage, { vuexPersistEmitter } from '@/services/StorageService'
import { axiosInstance } from '@/api'
import authService from '@/services/AuthServices'

const initStore = () => {

  const vuexLocal = new VuexPersistence({
    asyncStorage: true,
    storage: browserStorage as any,
    strictMode: true,
    filter: (mutation: any) => {
      // do not persist notifications, as they might contain executable code, which is not good serialized
      if (mutation.type?.match(/entities\//) && mutation.payload?.entity === 'notifications') {
        return false
      }

      const shouldSaveState = mutation.type !== 'auth/logout'
      return shouldSaveState
    },
    reducer: (state: any) => {
      if (authService.isAuthenticated()) {
        return state
      }
      return {}
    }
  })

  Vue.use(Vuex)

  VuexORM.use(VuexORMAxios, {
    database,
    axios: axiosInstance,
  })

  const store = new Vuex.Store<IRootState>({
    strict: false,
    modules: {
      auth: moduleAuth,
      billing: moduleBilling,
      company: moduleCompany,
      team: moduleTeam,
      ui: moduleUi,
      app: moduleApp,
      document: moduleDocument,
      slack: moduleSlack,
      settings: moduleSettings,
    },
    mutations: {
      RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
    },
    plugins: [
      VuexORM.install(database),
      vuexPersistEmitter,
      vuexLocal.plugin,
    ],
  })

  return store
}

export default initStore
