import { Module } from 'vuex'
import { IRootState } from '@/store/root'
import { Document, genPdfDocument, Visibility, rolesList } from '@/models'
import faker from '@/faker'

export interface Upload {
  name?: string
  filename?: string
  visibility?: Visibility
  type?: string
}

export interface IDocumentState {
  documentsLoaded: boolean
  newFileName?: string
  upload: Upload
}

export const moduleDocument: Module<IDocumentState, IRootState> = {
  namespaced: true,
  state: {
    documentsLoaded: false,
    newFileName: '',
    upload: {},
  },
  getters: {},
  mutations: {
    createDocument(state, file: File) {
      state.newFileName = file.name
      state.upload.name = file.name
      state.upload.filename = file.name
      state.upload.type = file.type
    },
    clearUpload(state) {
      state.newFileName = ''
      state.upload = {}
    },
  },
  actions: {
    async loadFake(ctx, companyId: string) {
      await Document.api().$create({
        data: Array.from({ length: 3 }, () => genPdfDocument(companyId)),
      })
    },
    remove(ctx, doc: Document) {
      Document.api().$delete({
        params: {
          id: doc.documentId,
        },
      })
    },
    upload({ commit }, file: File) {
      commit('createDocument', file)
    },
    async load() {
      await Document.api().$fetch()
    },
    create({ commit }, upload: Upload) {
      Document.api().$create({
        data: {
          documentId: faker.random.uuid(),
          name: upload.name,
          link: upload.filename,
          visibility: [...rolesList],
        },
      })
      commit('clearUpload')
    },
  },
}
