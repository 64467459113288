export const mainLogos = [
  {
    name: 'Figma',
    logo: 'figma.svg',
  },

  {
    name: 'Sketch App',
    logo: 'sketch.svg',
  },
  {
    name: 'InVision',
    logo: 'invision.svg',
  },
  {
    name: 'Adobe Illustrator',
    logo: 'logo-ai.png',
  },
  {
    name: 'Adobe Photoshop',
    logo: 'logo-ps.png',
  },
  {
    name: 'Adobe After Effects',
    logo: 'logo-ae.png',
  },
  {
    name: 'HTML',
    logo: 'logo-html.svg',
  },
  {
    name: 'CSS',
    logo: 'logo-css.svg',
  },
]
