import axios, { CancelToken } from 'axios'

import { apiUrl, signUrl } from './config'
import authService from './services/AuthServices'
import { getLogger } from './utils/logger'

const logger = getLogger('api')

export class ApiError extends Error {
  public details: any
  public response: any
  public statusCode?: number

  constructor(msg: string, response: { data?: any, status?: number }) {
    super(msg)

    this.message = msg
    this.response = response.data
    this.statusCode = response.status
  }
}

export const axiosInstance = axios.create({
  baseURL: apiUrl,
  url: '/',
  headers: {
    Accept: 'application/json',
  },
  data: false, // required to fix bug with `merge()` inside axios that converts arrays to objects
  responseType: 'json',
})
axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = authService.accessToken ? `Bearer ${authService.accessToken}` : ''
  return config
})
axiosInstance.interceptors.response.use((response) => {
  if (response.status === 401) {
    return Promise.reject(new ApiError('Unauthorized', response))
  } else if (response.status === 402) {
    return Promise.reject(new ApiError(`Subscription required ${response.data.error}`, response))
  }
  return response
}, (error) => {
  if (!error.response) {
    return Promise.reject(new ApiError('Error talking to the API', {
      status: 0,
    }))
  }
  const { response } = error
  if (response.config.url === '/user/logout') {
    return Promise.resolve({})
  }
  let msg = ''
  switch (response.status) {
  case 401:
    msg = 'Unauthorized'
    break
  case 402:
    msg = `Subscription required ${response.data.error}`
    break
  default:
    msg = `Error: ${response.status}`
  }
  const err = new ApiError(msg, response)
  err.details = response.data && response.data
  return Promise.reject(err)
})

export const uploadSigned = async (file: File, extensionHeaders: object, progressCb: (evt: ProgressEvent) => void) => {
  logger('Signing url', file)
  const res = await axios({
    method: 'post',
    url: signUrl,
    timeout: 500000,
    data: {
      filePath: file.name,
      contentType: file.type,
      extensionHeaders,
    },
  })

  logger('signing success', res.status, res.data)

  if (res.status !== 200) {
    throw new Error(res.data)
  }
  const { url } = res.data

  logger('Uploading file', url)
  await axios.put(url, file, {
    headers: {
      ...extensionHeaders,
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent) => progressCb(progressEvent),
  })

  const urlObj = new URL(url)
  logger('Upload success', urlObj)

  return urlObj
}

export const discoverApp = async (url: string, cancelToken ?: CancelToken) => {
  logger('Discovering app', url)
  const res = await axiosInstance({
    method: 'post',
    url: '/apps/discover',
    timeout: 15000,
    data: { url },
    cancelToken,
  })

  if (res.status !== 200 || res.data.error) {
    throw new Error(res.data)
  }

  const { metadata } = res.data
  logger('Discovered', url, metadata)
  return metadata
}

export const apiCall = async (url: string, method: any = 'get', data: any = undefined) => {
  logger('Api call', url, method, data)
  const res = await axiosInstance({
    method,
    url,
    timeout: 500000,
    data,
  })

  logger('Api result', res.status, res.data)
  if (res.status >= 400) {
    throw new Error(res.data)
  }

  return res.data
}
