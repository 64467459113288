









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ProgressBar extends Vue {
  @Prop({ type: Number, default: 0 })
  public progress!: number;

  @Prop({ type: Number, default: 100 })
  public width!: number;

  @Prop({ type: Number, default: 8 })
  public height!: number;
}
