



















import {
  Component,
  Prop,
} from 'vue-property-decorator'
import View from '../../View'
import { TextBlockPageRef } from '@/models'
import VideoPlayer from '@/components/common/VideoPlayer.vue'

@Component({
  components: {
    VideoPlayer,
  },
})
export default class TextBlockList extends View {
  @Prop({ type: String, required: true })
  public pageRef: TextBlockPageRef

  get textBlocks() {
    return this.textBlocksByRef(this.pageRef)
  }

  get profile() {
    return this.$store.state.ui.mobilePreviewProfile
  }

  get extraVars() {
    return {
      profile: this.profile,
    }
  }

  get focusedId() {
    const { type, id } = this.$store.state.ui.focusedSection
    return type === 'textBlock' ? id : null
  }
}
