import { Module } from 'vuex'
import { IRootState } from '@/store/root'
import { SlackUsersResponse, SlackMember } from '@/models'
import { apiCall } from '../api'

export interface ISlackState {
  loading: boolean
  loadUrl: string
  profiles: SlackUsersResponse | null
  team: any
}

export const moduleSlack: Module<ISlackState, IRootState> = {
  namespaced: true,
  state: {
    loading: false,
    loadUrl: '',
    profiles: null,
    team: null,
  },
  getters: {},
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading
    },
    setLoadUrl(state, loadUrl: string) {
      state.loadUrl = loadUrl
    },
    setProfiles(state, profiles: SlackUsersResponse) {
      state.profiles = profiles
    },
    setTeam(state, team: any) {
      state.team = team
    },
    addProfiles(state, profiles: SlackUsersResponse) {
      if (state.profiles && profiles.members) {
        state.profiles.members = [
          ...(state.profiles.members as SlackMember[]),
          ...profiles.members,
        ]
      } else {
        state.profiles = profiles
      }
    },
    importAllProfiles(state) {
      const { profiles } = state
      if (profiles && profiles.members) {
        profiles.members = profiles.members.map((item) => ({
          isImported: true,
          ...item,
        }))
      }
      state.profiles = profiles
    },
    importOne(state, item: SlackMember) {
      const { profiles } = state
      if (profiles && profiles.members) {
        const { members } = profiles
        profiles.members = members.map((member) => {
          if (item.id === member.id) {
            member.isImported = true
          }
          return member
        })
      }

      state.profiles = profiles
    },
  },
  actions: {
    async login({ commit }) {
      commit('setLoading', true)
      const res = await apiCall('/slack/authorize', 'POST')
      commit('setLoading', false)
      return res
    },
    async install({ commit }) {
      commit('setLoading', true)
      const res = await apiCall('/slack/install', 'POST')
      commit('setLoading', false)
      return res
    },
    loadUrl({ commit }) {
      commit('setLoadUrl', '#slack-load-url')
    },
    async loadProfiles({ commit }) {
      commit('setLoading', true)
      const data = await apiCall('/slack/import', 'POST')
      commit('setLoading', false)
      commit('setProfiles', data.users)
      commit('setTeam', data.team)
    },
    loadMore({ commit, state }) {
      // commit('addProfiles', genProfiles())
    },
    importAll({ commit }) {
      commit('importAllProfiles')
    },
    importOne({ commit }, item: SlackMember) {
      commit('importOne', item)
    },
  },
}
