

















import { Component } from 'vue-property-decorator'
import View from '../View'

@Component({
  components: {},
})
export default class NotFound extends View {
  get url() {
    return this.$route.path
  }
}
