import Vue from 'vue'

import App from './App.vue'
import initRouter from '@/router'
import initStore from '@/store'
import initComponents from './components'

// import '@/registerServiceWorker'

import './styles'
import './directives'
import './vue-config'
import initFilters from './filters'

import initPageTitle from './page-title'
import { getErrorHandler } from './error-handler'

const store = initStore()
const router = initRouter('history', store)

initPageTitle(Vue, router)
initComponents(Vue)
initFilters(Vue)

Vue.config.errorHandler = getErrorHandler(store, router)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
