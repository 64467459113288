






















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class AutoSuggest extends Vue {
  public search: string
  public selectedIndex: number

  @Prop({ type: Function })
  public filterItems: (search: string) => any

  get colCount() {
    return 3
  }

  get filteredItems(): any {
    return this.filterItems(this.search)
  }

  public data() {
    return {
      search: '',
      selectedIndex: -1,
    }
  }

  public onSelected(item: any) {
    this.$emit('selected', item)
    this.selectedIndex = -1
    this.search = ''
  }

  public handleKey(event: KeyboardEvent) {
    if (event.code.match(/^Arrow/)) {
      event.preventDefault()
    }

    switch (event.code) {
    case 'ArrowUp':
      this.selectedIndex = Math.max(-1, this.selectedIndex - this.colCount)
      break
    case 'ArrowDown':
      this.selectedIndex =
          this.selectedIndex === -1
            ? 0
            : Math.min(
              this.filteredItems.length,
              this.selectedIndex + this.colCount,
            )
      break
    case 'ArrowLeft':
      this.selectedIndex = Math.max(-1, this.selectedIndex - 1)
      break
    case 'ArrowRight':
      this.selectedIndex = Math.min(
        this.filteredItems.length,
        this.selectedIndex + 1,
      )
      break
    case 'Enter':
      return this.selectItemUnderCursor()
    }

    setTimeout(() => this.scrollIntoView(), 50)
  }

  public scrollIntoView() {
    const suggestions = this.$refs.suggestions as HTMLElement
    if (suggestions) {
      const elm = suggestions.querySelector('.selected') as HTMLElement
      if (elm) {
        const { clientHeight, scrollTop } = suggestions
        const { offsetTop } = elm
        if (offsetTop < scrollTop || offsetTop > scrollTop + clientHeight) {
          suggestions.scrollTop = offsetTop - 10
        }
      }
    }
  }

  public selectItemUnderCursor() {
    const item = this.filteredItems[this.selectedIndex]
    if (item) {
      this.onSelected(item)
    }
  }
}
