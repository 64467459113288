import { Module } from 'vuex'
import { IRootState } from '@/store/root'
import { Setting, Template } from '@/models'

interface ISettingsState {
  referer: any
}

export const moduleSettings: Module<ISettingsState, IRootState> = {
  namespaced: true,
  state: {
    referer: null,
  },
  getters: {
    referer: state => state.referer
  },
  mutations: {
    setReferer(state, payload: any) {
      if (payload && !payload?.fullPath?.includes('/settings')) {
        state.referer = {
          url: payload?.fullPath,
          title: payload?.meta.title
        }
      }
    }
  },
  actions: {
    async loadSettings(ctx, companyId: string) {
      await Setting.api().$fetch()
    },
    async loadTemplates() {
      await Template.api().$fetch()
    },
  },
}
