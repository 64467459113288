


















import { Component, Prop } from 'vue-property-decorator'
import AdminActions from '@/components/common/AdminActions.vue'
import View from '../../View'

@Component({
  components: {
    AdminActions,
  },
})
export default class EditLayout extends View {
  @Prop({ type: Boolean, required: false, default: true })
  public showActions: boolean

  @Prop({ type: String, required: false, default: 'Save changes' })
  public actionName: string

  @Prop({ type: String, required: false, default: '' })
  public backRoute: string

  public cancel() {
    this.$emit('before-cancel')
    this.goBack(this.backRoute)
  }

  public save() {
    this.$emit('save', () => {
      this.cancel()
    })
  }
}
