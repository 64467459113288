
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class MenuApp extends Vue {
  public menuActive = false

  @Prop({ type: String, required: false })
  public viewLabel: string

  @Prop({ type: String, required: false })
  public viewIcon: string

  @Prop({ type: Boolean, default: true })
  public canEdit: boolean

  @Prop({ type: Array, default: [] })
  public extra: any

  get isAdmin() {
    return this.$store.getters['auth/isAdmin']
  }

  get menuItems() {
    const items = []

    if (this.canEdit) {
      items.push({
        action: 'edit',
        label: 'Edit',
        icon: '', // default is the action name
      })
    }
    if (this.viewLabel) {
      items.push({
        action: 'view',
        label: this.viewLabel,
        icon: this.viewIcon,
      })
    }
    return items.concat(this.extra)
  }
}
