const getTooltipElm = (): HTMLElement => {
  let tooltipElm = document.getElementById('tooltip')
  if (!tooltipElm) {
    tooltipElm = document.createElement('div')
    tooltipElm.className = 'tooltip'
    tooltipElm.id = 'tooltip'
    document.body.appendChild(tooltipElm)
  }
  return tooltipElm
}

const offset = (elem: HTMLElement) => {
  let elm = elem
  let x = 0
  let y = 0

  while (elm) {
    x += elm.offsetLeft
    y += elm.offsetTop
    elm = elm.offsetParent as HTMLElement
  }

  return { left: x, top: y }
}

const tooltipPaddingCompensation = 4

interface BindingValue { text: string, ofs?: number }

let timeout: any

const removeTooltip = () => {
  const tooltipElm = getTooltipElm()
  tooltipElm.innerHTML = ''
  tooltipElm.style.display = 'none'
}

const tooltip = {
  inserted(el: HTMLElement, binding: { value?: BindingValue }) {
    const tooltipElm = getTooltipElm()

    el.addEventListener('mouseenter', () => {
      if (!binding || !binding.value) {
        return false
      }
      const { text, ofs } = binding.value
      const { left, top } = offset(el)

      tooltipElm.style.display = 'block'
      tooltipElm.innerHTML = text
      tooltipElm.style.top = `${top - el.clientHeight - (ofs || 0)}px`
      tooltipElm.style.left = `${left - ((tooltipElm.clientWidth - el.clientWidth) / 2) + tooltipPaddingCompensation}px`

      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(removeTooltip, 10000)
    })

    el.addEventListener('mouseleave', removeTooltip)
    el.addEventListener('mouseout', removeTooltip)
  },
  unbind(el: HTMLElement, binding: { value?: BindingValue }) {
    const tooltipElm = getTooltipElm()
    if (!binding || !binding.value) {
      return false
    }
    const { text } = binding.value
    if (text === tooltipElm.innerHTML) {
      clearTimeout(timeout)
      removeTooltip()
    }
  },
}

export default tooltip
