









import DebugDatabase from '../components/debug/DebugDatabase.vue'
import Debug from '../components/debug/Debug.vue'

export default {
  name: 'playground',
  components: {
    DebugDatabase,
    Debug,
  },
}
