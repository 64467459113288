



























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class InvitationStatus extends Vue {
  @Prop({ type: String })
  public invitationStatus!: InvitationStatus;

  @Prop({ type: String })
  public companyUserProfileId!: string;

  public goTo(route: any) {
    this.$router.push(route)
  }
}
