



























import { Component } from 'vue-property-decorator'

import View from '../../View'

@Component({
  components: {},
})
export default class InviteMember extends View {
  get modalAddTypes() {
    return [
      {
        type: 'Candidate',
        route: {
          name: 'member-add',
          params: {
            role: 'candidate',
          },
        },
        icon: 'illustration-candidate.svg',
        tagLine: 'candidate',
      },
      {
        type: 'New hire',
        route: {
          name: 'member-add',
          params: {
            role: 'hire',
          },
        },
        icon: 'illustration-hire.svg',
        tagLine: 'new-hire',
      },
      {
        type: 'Existing members',
        route: {
          name: 'member-add',
          params: {
            role: 'member',
          },
        },
        icon: 'illustration-member.svg',
        tagLine: 'member',
      },
    ]
  }

  public goto(route: any) {
    this.$router.push(route)
  }
}
