










































import { Component } from 'vue-property-decorator'
import AdminActions from '@/components/common/AdminActions.vue'
import MemberCard from '@/components/team/MemberCard.vue'
import { CompanyUserProfile, Company, Notification } from '@/models'
import FieldErrors from '@/components/form/FieldErrors.vue'
import SubscriptionLimit from '@/components/common/SubscriptionLimit.vue'
import View from '@/View'
import { ApiError } from '../../api'


@Component({
  components: {
    MemberCard,
    AdminActions,
    FieldErrors,
    SubscriptionLimit,
  },
})
export default class MemberAdd extends View {
  public profile!: CompanyUserProfile
  public placeholder: any

  get error() {
    return this.$store.state.team.error
  }

  get errors() {
    return this.error && this.error.errors // TODO naming sucks
  }

get role() {
    return this.$route.params.role
  }

  public mounted() {
    this.$store.commit('team/setError', undefined)
    this.$store.commit('team/setSubscriptionLimit', {
      resource: 'users',
      value: false
    })

    this.$title = `Add new ${this.role}`

    const match = this.user?.email?.match(/@(.+)$/)
    const domain = match ? match[1] : 'uelco.dev'

    this.placeholder = {
      firstName: 'Andy',
      lastName: 'Croffenshtauer',
      position: 'Front-End Developer',
      email: `new-${this.role}@${domain}`,
    }

    this.profile = new CompanyUserProfile({
      role: this.role,
    })
    if (this.role === 'candidate') {
      const expireDate = new Date()
      expireDate.setDate(expireDate.getDate() + 7) // 7 days
      this.profile.inviteExpiresAt = expireDate
    }
  }

  public data() {
    return {
      placeholder: {},
      profile: {},
    }
  }

  public async handleSave(redirectRoute: any = { name: 'members' }) {
    const company = Company.query().first()
    this.profile.companyId = company ? company.companyId : ''

    this.$store.commit('team/setError', undefined)

    try {
      await this.profile.$persist()
      if (redirectRoute) {
        this.$router.push(redirectRoute)
      }
    } catch (err) {
      this.$store.commit('team/setError', err.details || err.message)
      if (err instanceof ApiError) {
        if (err.statusCode === 402) {
          this.$store.commit('team/setSubscriptionLimit', {
            resource: 'users',
            value: true
          })
        }
      } else {
        Notification.add('warning', err.details ? err.details.error :  err.message, {})
      }
    }
  }

  public async saveAndInvite() {
    await this.handleSave(false)
    // reload profile
    const profile = CompanyUserProfile.query().where('email', this.profile.email).first()
    if (!profile) {
      return this.$router.push({ name: 'members'})
    }
    this.$router.push({
      name: 'profile-invite',
      params: {
        user: profile.companyUserProfileId,
      },
    })
  }
}
