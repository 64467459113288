import { Module } from 'vuex'
import { IRootState } from '@/store/root'
import { Company, TextBlock, Contact, Setting } from '@/models'

export interface ICompanyState {
  company: Company | null
  loading: boolean
}

export const moduleCompany: Module<ICompanyState, IRootState> = {
  namespaced: true,
  state: {
    company: null,
    loading: false,
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading
    },
  },
  actions: {
    async load({ commit }, { companyId }: { companyId: string }) {
      commit('setLoading', true)
      await Company.api().$get({
        params: {
          id: companyId,
        },
      })
      await Promise.all([
        TextBlock.api().$fetch(),
        Contact.api().$fetch(),
        Setting.api().$fetch(),
      ])
      commit('setLoading', false)
    },
  },
}
