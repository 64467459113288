



















































































import axios, { CancelTokenSource } from "axios";
import { Component } from "vue-property-decorator";
import AdminActions from "@/components/common/AdminActions.vue";
import ContactPersonSelector from "@/components/form/ContactPersonSelector.vue";
import RichTextEditor from "@/components/form/RichTextEditor.vue";
import FileUpload from "@/components/document/FileUpload.vue";
import MemberAvatar from "../../components/team/MemberAvatar.vue";

import { App, CompanyUserProfile } from "../../models";
import View from "../../View";
import { discoverApp } from "@/api";

@Component({
  components: {
    AdminActions,
    ContactPersonSelector,
    RichTextEditor,
    FileUpload,
    MemberAvatar,
  },
})
export default class AppEdit extends View {
  get appId() {
    return this.$route.params.id;
  }

  get contactPerson(): CompanyUserProfile | null {
    if (this.app.contactPersonId) {
      return CompanyUserProfile.find(
        this.app.contactPersonId
      ) as CompanyUserProfile;
    }
    return null;
  }

  public app!: App;
  public appMetaData!: object | undefined | null;
  public discovering!: boolean;

  private urlChanged: any;
  private cancelToken: CancelTokenSource | null;

  public async created() {
    const rec = App.find(this.appId) as App;
    if (this.appId) {
      this.app = new App(rec || {});
    } else {
      this.createNewApp();
    }
  }

  public data() {
    return {
      app: null,
      appMetaData: undefined,
      discovering: false,
    };
  }

  public async addLogo(url: URL) {
    this.app.icon = `${url.origin}${url.pathname}`;
  }

  public contactSelected(item: CompanyUserProfile) {
    this.app.contactPersonId = item.companyUserProfileId;
  }

  public async handleSave(closeCallback: () => void) {
    await this.app.$persist();
    closeCallback();
  }

  public triggerDiscovery() {
    if (this.app.url) {
      return false;
    }

    if (this.urlChanged) {
      clearTimeout(this.urlChanged);
    }
    if (this.cancelToken) {
      this.cancelToken.cancel("New request");
    }
    this.urlChanged = setTimeout(() => this.discover(), 500);
  }

  public async discover(url?: string) {
    if (!url && !this.app.name) {
      return;
    }

    try {
      this.discovering = true;
      this.cancelToken = axios.CancelToken.source();
      const meta = await discoverApp(
        url || this.app.name,
        this.cancelToken.token
      );
      this.appMetaData = meta;

      if (meta) {
        this.app.catalog = false;
        this.app.discovered = false;
        this.app.name = new URL(meta.url).hostname;
        this.app.icon = meta.logo;
        this.app.url = meta.url;
        this.app.guideUrl = meta.url;
        this.app.header = meta.title;
        this.app.description = meta.description;
      }
    } catch (err) {
      this.appMetaData = null;
    }
    this.discovering = false;
    this.cancelToken = null;
  }

  private createNewApp() {
    const { q } = this.$route.params;

    this.app = new App({
      header: "",
      name: q || "",
      description: `
      Description

      How to install:

      How to use:

      `,
    });

    if (q) {
      this.discover(q.indexOf(".") !== -1 ? q : `${q}.com`);
    }
  }
}
