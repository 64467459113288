


























import { Component } from 'vue-property-decorator'
import ItemMenu from '@/components/common/ItemMenu.vue'
import { App } from '@/models'
import View from '../../View'

@Component({
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  components: {
    ItemMenu,
  },
})
export default class GridApp extends View {
  public app: App

  get extraMenuItems() {
    return this.isAdmin ? [{ action: 'remove', label: 'Remove' }] : []
  }

  public async handleAction(cmdType: string) {
    switch (cmdType) {
    case 'remove':
      await App.softDelete(this.app.appId, `"${this.app.name}" was removed`)
      break
    case 'edit':
      this.$router.push({
        name: 'app-edit',
        params: { id: `${this.app.appId}` },
      })
      break
    case 'view':
      this.$router.push({
        name: 'app-view',
        params: { id: `${this.app.appId}` },
      })
      break
    }
  }
}
