












import { Component, Vue } from 'vue-property-decorator'

interface TabItem {
  key: string
  label: string
}

@Component({
  props: {
    items: {
      default: [],
    },
    activeTab: {
      default: '',
    },
  },
})
export default class TabSwitch extends Vue {
  public items!: TabItem[]
  public activeTab!: string
}
