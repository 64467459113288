



















import { Component } from 'vue-property-decorator'
import MobileView from '@/components/preview/MobileView.vue'
import GridDocument from '@/components/document/GridDocument.vue'

import { Document, Visibility } from '@/models'
import View from '../../View'

@Component({
  components: {
    MobileView,
    GridDocument,
  },
})
export default class Documents extends View {
  get hasDocuments() {
    return this.documents.length > 0
  }

  get documents() {
    const query = Document.query().where('isDeleted', false)

    if (this.roleSwitch && !this.isAdmin) {
      query.where('visibility', (vis: Visibility) => vis.indexOf(this.roleSwitch) !== -1)
    }

    query.orderBy('createdAt', 'desc')
    return query.get()
  }

  get newFileName() {
    return this.$store.state.document.newFileName
  }

  get upload() {
    return this.$store.state.document.upload
  }

  get companyId() {
    return this.$store.state.auth.companyId
  }

  public async mounted() {
    this.$store.commit('ui/setMobilePreviewPage', 'AppDocs')
    await this.$store.dispatch('document/load')
  }

  public closeModal() {
    this.$store.commit('ui/toggleModal', false)
  }
}
