














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class SubscriptionLimit extends Vue {

  @Prop()
  public resource: string

  get subscriptionLimit() {
    return this.$store.state.team.subscriptionLimit[this.resource]
  }
}
