import faker from '@/faker'
import { Team, User, Contact } from '@/models'
import { Role, InvitationStatus } from './types'
import { BaseModel } from './base-model'
import { socialNetworks } from '@/data/social-networks'
import { SlackMember } from './slack'

export class CompanyUserProfile extends BaseModel {
  public static entity = 'profiles'
  public static primaryKey = 'companyUserProfileId'

  public static methodConf = {
    http: {
      url: '/company-user-profiles',
    },
  }

  static apiConfig = {
    actions: {
      $fetch () {
        return (this as any).get('/company-user-profiles')
      },
      $get ({ params }: any) {
        return (this as any).get(`/company-user-profiles/${params.id}`)
      },
      $create ({ data }: any) {
        return (this as any).post('/company-user-profiles', data)
      },
      $delete ({ params }: any) {
        return (this as any).delete(`/company-user-profiles/${params.id}`, {
          delete: params.id
        })
      },
      $update ({ params, data }: any) {
        return (this as any).put(`/company-user-profiles/${params.id}`, data)
      }
    }
  }

  public static fields(): any {
    return {
      companyUserProfileId: this.attr(undefined),
      userId: this.belongsTo(User, 'userId'),
      email: this.attr(''),
      invitationStatus: this.attr('none'),
      companyId: this.attr(undefined),
      teamId: this.attr(undefined),
      team: this.attr(null),
      locationId: this.attr(null),
      officePlacementLocationId: this.attr(null),
      firstName: this.attr(''),
      lastName: this.attr(''),
      fullName: this.attr(''),
      avatar: this.attr(''),
      position: this.attr(''),
      about: this.attr(''),
      interests: this.attr({ text: '', emojis: '' }),
      expertise: this.attr({ items: [], text: '' }),
      social: this.attr({}),
      startDate: this.attr(null),
      endDate: this.attr(null),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
      role: this.attr(''),
      meta: this.attr({}),
      slackUserId: this.attr(undefined),
      isActive: this.attr(true),
      isDeleted: this.attr(false),

      inviteExpiresAt: this.attr(null),

      contacts: this.hasMany(Contact, 'profileId'),
    }
  }

  public companyUserProfileId: string
  public userId: string
  public email: string
  public invitationStatus: InvitationStatus
  public companyId: string
  public teamId!: string | string[]
  public team: Team | null
  public locationId!: string
  public officePlacementLocationId!: string
  public firstName!: string
  public lastName!: string
  public fullName!: string
  public avatar!: string
  public position!: string
  public about!: string
  public interests!: { text: string, emojis: string }
  public expertise!: { items: object[], text: string }
  public social!: string | object
  public startDate!: Date
  public endDate!: Date
  public createdAt!: Date
  public updatedAt!: Date
  public role!: Role
  public meta!: object
  public slackUserId!: string
  public isActive: boolean
  public isDeleted: boolean

  public inviteExpiresAt!: Date

  get name() {
    return `${this.firstName ?? ''} ${this.lastName ?? ''}`
  }

  get hasInterests() {
    return this.interests && (
      this.interests.emojis ||
      this.interests.text
    )
  }

  get hasSocial() {
    return this.social && Object.keys(this.social).length > 0
  }

  get hasExpertise() {
    return this.expertise && Object.keys(this.expertise.items).length > 0
  }

  public $toJson() {
    const data = super.$toJson()
    data.inviteExpiresAt = this.inviteExpiresAt
    return data
  }

}

const EXPERTISE = [
  {
    name: 'InVision',
    logo: 'invision.svg',
  },
  {
    name: 'Sketch App',
    logo: 'sketch.svg',
  },
  {
    name: 'InVision',
    logo: 'invision.svg',
  },
  {
    name: 'Adobe Illustrator',
    logo: 'logo-ai.png',
  },
  {
    name: 'Adobe Photoshop',
    logo: 'logo-ps.png',
  },
  {
    name: 'Adobe After Effects',
    logo: 'logo-ae.png',
  },
  {
    name: 'HTML',
    logo: 'logo-html.svg',
  },
  {
    name: 'CSS',
    logo: 'logo-css.svg',
  },
]

export const genCompanyUserProfile = (teams?: Team[], companyId?: string): CompanyUserProfile =>
  new CompanyUserProfile({
    companyId,
    avatar: faker.internet.avatar(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    position: `${faker.name.jobDescriptor()} ${faker.name.jobTitle()}`,
    email: faker.internet.email(),
    invitationStatus: faker.random.arrayElement(['none', 'invite_sent', 'invite_accepted', 'invite_expired']),
    role: faker.random.arrayElement(['hire', 'candidate', 'member']),
    about: faker.lorem.paragraph(),
    interests: {
      text: faker.lorem.paragraph(),
      emojis: '🎸 ☕ 🏓 🕹 🍺',
    },
    expertise: {
      text: faker.lorem.sentence(),
      items: Array.from({ length: 5 }, () =>
        faker.random.arrayElement(EXPERTISE),
      ),
    },
    social: Array.from({ length: 4 }).reduce((val: any) => {
      val[faker.random.arrayElement(socialNetworks)] = faker.internet.url()
      return val
    }, {}),
    teamId: teams ? faker.random.arrayElement(teams).teamId : undefined,
    isActive: true,
    createdAt: faker.date.past(),
    isDeleted: false,
    inviteExpiresAt: faker.date.future(),
    startDate: faker.date.past(),
  })

export const slackProfileToMember = (profile: SlackMember): CompanyUserProfile =>
  new CompanyUserProfile({
    companyUserProfileId: faker.random.uuid(),
    avatar: profile.profile.image_512,
    firstName: profile.name,
    email: profile.profile.email,
    role: 'member',
    about: profile.profile.status_text,
    meta: {
      slackProfile: profile,
    },
    slackUserId: profile.id,
  })
