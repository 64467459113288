import { Store } from 'vuex'
import VueRouter from 'vue-router'

import { IRootState } from './store/root'
import { ApiError } from './api'
import { Notification } from './models'

export const getErrorHandler = (store: Store<IRootState>, router: VueRouter) =>
  (err: any, vm: Vue, info: any) => {
    if (err instanceof ApiError) {
      if (err.statusCode === 401) {
        store.commit('auth/setSessionExpired')
        Notification.add('error', 'You have been logged out.', {
          title: 'Login again',
          callback: () => {
            router.push({ name: 'logout' })
          },
        })
      } else if (err.statusCode === 402) {
        Notification.add('error', 'Subscription limit reached.', {
          title: 'Please upgrade',
          callback: () => {
            router.push({ name: 'billing' })
          },
        })
      } else {
        Notification.add('warning', err.message)
      }
    }
  }
