const locale = 'en-GB'
const options: any = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}
const optionsShort: any = {
  year: 'numeric',
  month: 'long',
}

export default function date(value: string, showDay = true): string {
  if (!value) {
    return ''
  }

  const dt = new Date(value)
  return dt.toLocaleDateString(locale, showDay ? options : optionsShort)
}
