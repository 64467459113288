
















































import { Component } from 'vue-property-decorator'
import AddToSlack from '@/components/slack/AddToSlack.vue'
import AdminActions from '@/components/common/AdminActions.vue'
import GridSlackMember from '@/components/slack/GridSlackMember.vue'
import { SlackMember, Notification, slackProfileToMember } from '../../models'
import View from '../../View'

@Component({
  components: {
    AddToSlack,
    AdminActions,
    GridSlackMember,
  },
})
export default class ImportSlack extends View {
  public selected: { [id: string]: boolean } = {}

  get profiles(): SlackMember[] {
    const { profiles } = this.$store.state.slack
    return profiles
  }

  get team() {
    const { team } = this.$store.state.slack
    return team
  }

  get notImportedProfiles(): SlackMember[] {
    return (this.profiles || []).filter((profile) => !profile.isImported)
  }

  get importedProfiles(): SlackMember[] {
    return (this.profiles || []).filter((profile) => profile.isImported)
  }

  get selectedIds() {
    return Object.keys(this.selected).filter((id) => this.selected[id] === true)
  }

  public data() {
    return {
      selected: {},
    }
  }

  public created() {
    this.$store.dispatch('slack/loadProfiles')
  }

  public onConnected() {
    this.$store.dispatch('slack/loadProfiles')
  }

  public loadFromSlack() {
    this.$store.dispatch('slack/loadMore')
  }

  public async importAll() {
    // todo incase of huge imports, might not be efficient
    await Promise.all(this.profiles.map((profile) => this.importOne(profile)))
    Notification.add(
      'success',
      `${this.profiles.length} profiles were imported`,
    )
    this.goBack('/members')
  }

  public async importOne(profile?: SlackMember) {
    if (!profile || profile.isImported) {
      return
    }
    try {
      await this.$store.dispatch('slack/importOne', profile)
      const companyUserProfile = slackProfileToMember(profile)
      await companyUserProfile.$persist()
    } catch (err) {
      Notification.add(
        'error',
        `Cannot import ${profile.name} - profile already exists`,
      )
      throw err
    }
  }

  public async importSelected() {
    const { profiles, selectedIds } = this
    await Promise.all(
      selectedIds.map((id) =>
        this.importOne(profiles.find((item) => item.id === id)),
      ),
    )
    Notification.add('success', `${selectedIds.length} profiles were imported`)
    this.goBack('/members')
  }
}
