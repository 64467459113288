import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=da631d02&scoped=true&"
import script from "./Notifications.vue?vue&type=script&lang=ts&"
export * from "./Notifications.vue?vue&type=script&lang=ts&"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=da631d02&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da631d02",
  null
  
)

export default component.exports