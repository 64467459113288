/* tslint:disable */

export default [
  {
    priority: 0,
    name: 'Github',
    description:
      'Connect Github with Uelco to manage Github access for your employees automatically.',
    category: 'Collaboration',
    tags: ['Collaboration', 'Developer', 'Source Control', 'Popular'],
    url: 'https://www.github.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/github/github-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Proxy',
    description: 'Access the office effortlessly using your phone.',
    category: 'Keycard Access',
    tags: ['Keycard Access'],
    url: 'https://www.proxy.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/proxy/proxy-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Hardware',
    description: 'Let us automate your IT needs.',
    category: 'IT Management',
    tags: ['IT Management', 'Popular'],
    url: 'https://www.hardware.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hardware/hardware-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Gmail',
    description:
      'Connect GSuite with Uelco to manage email accounts for your employees automatically.',
    category: 'Popular',
    tags: ['Popular', 'Collaboration', 'Email', 'Storage'],
    url: 'https://www.gmail.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/gmail/gmail-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Box',
    description:
      'Connect Box with Uelco to manage Box accounts for your employees automatically.',
    category: 'Storage',
    tags: ['Storage'],
    url: 'https://www.box.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/box/box-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Dropbox',
    description:
      'Connect your Dropbox Business with Uelco to manage Dropbox accounts for your employees automatically.',
    category: 'Popular',
    tags: ['Popular', 'Storage'],
    url: 'https://www.dropbox.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/dropbox/dropbox-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Payroll',
    description: 'Let us take care of your payroll.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Popular', 'Payroll & Benefits'],
    url: 'https://www.payroll.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/payroll/payroll-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'PTO',
    description: 'Let us help you track your Paid Time Off',
    category: 'Human Resources',
    tags: ['Human Resources', 'Popular', 'Payroll & Benefits'],
    url: 'https://www.pto.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/pto/pto-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Pagerduty',
    description:
      'PagerDuty is an operations performance platform designed to make businesses more reliable by eliminating the chaos across the entire operations lifecycle.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.pagerduty.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/pagerduty/pagerduty-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Document',
    description: 'Let us manage your Documents.',
    category: '',
    tags: [],
    url: 'https://www.document.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/document/document-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Zendesk',
    description:
      'Zendesk allow businesses to be more reliable, flexible, and scalable. They help improve communication and make sense of massive amounts of data. Above all, they work together to help turn interactions into lasting relationships.',
    category: 'Popular',
    tags: ['Popular', 'Customer Support'],
    url: 'https://www.zendesk.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/zendesk/zendesk-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Asana',
    description:
      'Asana is a web and mobile application designed to help teams track their work. Note: To add/remove members from Asana teams, Asana requires the user (you) to be a part of the team you are managing.',
    category: 'Collaboration',
    tags: ['Collaboration', 'Popular'],
    url: 'https://www.asana.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/asana/asana-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Newrelic',
    description:
      'Gain instant end-to-end visibility across your customer experience, application performance, and dynamic infrastructure with the New Relic Digital Intelligence Platform.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.newrelic.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/newrelic/newrelic-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Expensify',
    description:
      'Let Expensify\'s intelligent automation handle your expenses in realtime. Because you have better things to do.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Expense Management'],
    url: 'https://www.expensify.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/expensify/expensify-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Domo',
    description:
      'Domo helps you manage, analyze, and share data across your entire organization, enabling decision makers to identify and act on strategic opportunities.',
    category: 'Finance & Accounting',
    tags: [
      'Finance & Accounting',
      'Analytics & BI',
      'Financial Planning & Analysis',
    ],
    url: 'https://www.domo.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/domo/domo-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'GotoMeeting',
    description:
      'Don’t just meet, connect. With GoToMeeting web conferencing, you and your team can seamlessly work together, even when you’re apart.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Virtual Meetings'],
    url: 'https://www.gotomeeting.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/gotomeeting/gotomeeting-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Basecamp',
    description:
      'Basecamp organizes your projects, internal communications, and client work in one place so you have a central source of truth.',
    category: 'Collaboration',
    tags: ['Collaboration', 'Storage'],
    url: 'https://www.basecamp.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/basecamp/basecamp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'DocuSign',
    description:
      'Docusign helps you send and manage documents anytime, anywhere, on any device, with confidence.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'eSignature'],
    url: 'https://www.docusign.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/docusign/docusign-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Lively',
    description:
      'Lively brings your HSA into the 21st century. 100% paperless with all of the modern conveniences you would expect. Simple, transparent and easy to use. Invest in your health.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Payroll & Benefits'],
    url: 'https://www.lively.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/lively/lively-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Salesforce',
    description:
      'Salesforce is a global cloud computing company that develops CRM solutions and provides business software on a subscription basis.',
    category: 'Sales & Marketing',
    tags: [
      'Sales & Marketing',
      'CRM',
      'Marketing Automation',
      'Popular',
      'Customer Support',
    ],
    url: 'https://www.salesforce.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/salesforce/salesforce-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Microsoft Office 365',
    description:
      'Microsoft Office 365 is a cloud-based service that is designed to help meet your organization\'s needs for robust security, reliability, and user productivity.  Connect Office 365 with Uelco to provision your employees\' accounts automatically for email and desktop apps like Excel and PowerPoint.',
    category: 'Popular',
    tags: ['Popular', 'Email', 'Storage'],
    url: 'https://www.microsoft office 365.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/microsoft office 365/microsoft office 365-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Close.io',
    description:
      'Close.io is the inside sales CRM of choice for startups and SMBs.Increase productivity with all your sales communication in one place.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM'],
    url: 'https://www.close.io.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/close.io/close.io-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'IQBOXY',
    description:
      'A bookkeeper in your pocket. Veryfi automates the boring parts of your accounting (data entry, ledger categorization, bank reconciliation & vehicle mileage logbook) with AI, OCR and machines, not humans.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Expense Management'],
    url: 'https://www.iqboxy.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/iqboxy/iqboxy-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Kisi',
    description:
      'Kisi is a cloud based access control system built to secure the modern business.',
    category: 'Keycard Access',
    tags: ['Keycard Access'],
    url: 'https://www.kisi.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/kisi/kisi-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Guideline',
    description:
      'Guideline is an all-inclusive 401(k) plan for growing businesses that\'s easy for employers to set up and even easier for employees to enroll and save. Connect Guideline with Uelco to provision Guideline accounts automatically for your employees, and manage deductions through Rippling payroll. If you have an existing Guideline account already connected with a different payroll provider, you need to notify support@guideline.com before connecting to Rippling.',
    category: 'Human Resources',
    tags: ['Human Resources', '401k', 'Payroll & Benefits'],
    url: 'https://www.guideline.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/guideline/guideline-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Lattice',
    description:
      'Lattice helps companies evolve their performance management system with performance reviews, real-times feedback, clear goal setting and one-on-one tools.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.lattice.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/lattice/lattice-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Adobe Sign',
    description:
      'Adobe Sign (formerly Adobe EchoSign) makes it faster and easier than ever to securely sign and manage documents across devices.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'eSignature'],
    url: 'https://www.adobe sign.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/adobe sign/adobe sign-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Cylance',
    description:
      'Cylance® is revolutionizing cybersecurity with products and services that proactively prevent, rather than reactively detect the execution of advanced persistent threats and malware. Our technology is deployed on over four million endpoints and protects hundreds of enterprise clients worldwide including Fortune 100 organizations and government institutions.',
    category: 'Security / Backup',
    tags: ['Security / Backup', 'IT Management', 'Popular'],
    url: 'https://www.cylance.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/cylance/cylance-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Zoomus',
    description: 'Connect Zoom.us with Uelco to manage users and groups. ',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Virtual Meetings', 'Popular'],
    url: 'https://www.zoomus.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/zoomus/zoomus-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Rippling',
    description: '',
    category: '',
    tags: [],
    url: 'https://www.rippling.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/rippling/rippling-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Abacus',
    description:
      'Connect Abacus with Uelco to manage Abacus access for your employees automatically.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Expense Management'],
    url: 'https://www.abacus.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/abacus/abacus-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'TSheets',
    description:
      'Connect TSheets with Uelco to manage TSheets access for your employees automatically. Also seamlessly integrate with Rippling Payroll. Single click to pull employee hours into Rippling payroll.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Time and Attendance', 'Popular'],
    url: 'https://www.tsheets.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/tsheets/tsheets-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Captain401',
    description:
      'Human Interest (formerly Captain401) makes it easy to offer a 401(k) retirement plan as an employee benefit. Human Interest automates all of the administration and helps your employees plan for their futures with built-in investment advising and friendly customer support. If you have an existing Human Interest account already connected with a different payroll provider, you need to notify support@humaninterest.com before connecting to Uelco.',
    category: 'Human Resources',
    tags: ['Human Resources', '401k', 'Payroll & Benefits'],
    url: 'https://www.captain401.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/captain401/captain401-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Aws',
    description:
      'Amazon Web Services offers reliable, scalable, and inexpensive cloud computing services.',
    category: 'Cloud Hosting',
    tags: ['Cloud Hosting', 'Developer', 'Popular'],
    url: 'https://www.aws.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/aws/aws-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Bitbucket',
    description:
      'Connect Bitbucket with Uelco to manage Bitbucket access for your employees automatically.',
    category: 'Developer',
    tags: ['Developer', 'Source Control'],
    url: 'https://www.bitbucket.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/bitbucket/bitbucket-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Loggly',
    description: 'Log data intelligence for your modern infrastructure.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Analytics & BI', 'Developer'],
    url: 'https://www.loggly.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/loggly/loggly-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Scalr',
    description:
      'The Scalr Cloud Management Platform makes multi-cloud app deployment fast, easy, cost-effective and safe.',
    category: 'Cloud Hosting',
    tags: ['Cloud Hosting', 'Developer'],
    url: 'https://www.scalr.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/scalr/scalr-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Clearslide',
    description:
      'ClearSlide is the most complete, intuitive Sales Engagement Platform that integrates content, communications and actionable insights to improve results.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Sales Productivity'],
    url: 'https://www.clearslide.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/clearslide/clearslide-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'VLDAP',
    description:
      'LDAP (Lightweight Directory Access Protocol) is a protocol that lets you connect applications to Uelco that don\'t otherwise have an integration, particularly on-premise services, or custom, in-house software. <br/><br/>LDAP is used to access, query, and modify directories using the X.500 data model. Rippling\'s Virtual LDAP Service (VLDAP) enables you to use Rippling as a cloud-based LDAP directory, providing another standard way of authenticating and authorizing your users.',
    category: 'Access Management',
    tags: ['Access Management', 'Developer'],
    url: 'https://www.vldap.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/vldap/vldap-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Atlassian',
    description:
      'Atlassian provides the tools to help every team unleash their full potential.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.atlassian.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/atlassian/atlassian-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SSH',
    description:
      'Secure Shell (SSH) is a cryptographic network protocol for operating network services securely over an unsecured network. With Uelco\'s SSH key management, employees self-manage their public keys inside of Rippling, you configure your servers and other services to point to Rippling\'s public key repo, and you can then conditionally provide employees access to services. New hires are automatically given SSH access to services, and terminating them in Rippling automatically revokes access.',
    category: 'Security / Backup',
    tags: ['Security / Backup', 'Access Management', 'Developer'],
    url: 'https://www.ssh.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/ssh/ssh-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AtlassianJira',
    description:
      'JIRA Software is built for every member of your software team to plan, track, and release great software. Quick note: Are you sure you\'re using Jira? Companies sometimes confuse Jira with Jira core. Jira is a version of Jira Core that includes a bunch of functionality for software engineers (such as agile development project tracking). Jira Core is missing the software engineering functionality and is aimed at non-engineering clients. If you\'re using Jira Core instead, you click back and install the "Jira Core" app in Uelco.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.atlassianjira.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/atlassianjira/atlassianjira-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AtlassianConfluence',
    description:
      'High performing teams work together to maximize productivity. Confluence combines the speed of creating on your own with the advantages of working together. Confluence makes it easy to create documents as a team, provide feedback in context and quickly iterate until your work is finalized.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.atlassianconfluence.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/atlassianconfluence/atlassianconfluence-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Heroku',
    description:
      'Connect Heroku with Uelco to manage Heroku accounts for your employees automatically.',
    category: 'Cloud Hosting',
    tags: ['Cloud Hosting', 'Developer'],
    url: 'https://www.heroku.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/heroku/heroku-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AtlassianServicedesk',
    description:
      'IT service desk and customer service software by JIRA Service Desk. Powerful ticketing support used by 20000+ teams.',
    category: 'Customer Support',
    tags: ['Customer Support'],
    url: 'https://www.atlassianservicedesk.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/atlassianservicedesk/atlassianservicedesk-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AtlassianJiracore',
    description:
      'A project and task management solution built for business teams. You can manage any business project in JIRA Core. Quick note: Are you sure you\'re using Jira Core? Companies sometimes confuse Jira with Jira core. Jira is a version of Jira Core that includes a bunch of functionality for software engineers (such as agile development project tracking). Jira Core is missing the software engineering functionality and is aimed at non-engineering clients. If you\'re using Jira instead, you click back and install the "Jira" app in Uelco.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.atlassianjiracore.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/atlassianjiracore/atlassianjiracore-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Runscope',
    description:
      'API testing and monitoring. Everything is going to be 200 OK.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.runscope.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/runscope/runscope-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Joinme',
    description:
      'Screen sharing, online meetings and team collaboration are all fast and easy at join.me.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Virtual Meetings'],
    url: 'https://www.joinme.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/joinme/joinme-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Apteligent',
    description:
      'Automatically capture and interpret app data. Unlock hidden insights through proven data science. Deliver answers to ongoing mobile business problems.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Analytics & BI', 'Developer'],
    url: 'https://www.apteligent.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/apteligent/apteligent-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Invision',
    description:
      'The world\'s best companies use InVision to design the products you love. Try InVision free and turn your screens into live, clickable prototypes in seconds.',
    category: 'Design',
    tags: ['Design', 'Collaboration'],
    url: 'https://www.invision.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/invision/invision-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Meraki',
    description:
      'Cisco Meraki is the leader in cloud controlled WiFi, routing, and security. Secure and scalable, Cisco Meraki enterprise networks simply work.',
    category: 'Security / Backup',
    tags: ['Security / Backup'],
    url: 'https://www.meraki.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/meraki/meraki-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Splunk',
    description:
      'Splunk Inc. provides the leading platform for Operational Intelligence. Customers use Splunk to search, monitor, analyze and visualize machine data.',
    category: 'Security / Backup',
    tags: ['Security / Backup', 'Analytics & BI'],
    url: 'https://www.splunk.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/splunk/splunk-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Saucelabs',
    description:
      'Cloud-based platform for automated testing of web and mobile applications. Access web browsers, mobile emulators and simulators, and real mobile devices.',
    category: 'Developer',
    tags: ['Developer', 'Test Management'],
    url: 'https://www.saucelabs.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/saucelabs/saucelabs-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Bugsnag',
    description: 'Detect and diagnose crashes in your applications',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.bugsnag.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/bugsnag/bugsnag-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'CustomInfo',
    description: 'Spoke to handle collection of custom fields for each role',
    category: 'Onboarding',
    tags: ['Onboarding', 'Human Resources', 'Popular'],
    url: 'https://www.custominfo.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/custominfo/custominfo-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Fifteenfive',
    description:
      '15Five software elevates the performance and engagement of employees by consistently asking questions and starting the right conversations.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.fifteenfive.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/fifteenfive/fifteenfive-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Chartio',
    description:
      'Chartio helps businesses analyze and track their critical data through an intutive interface.',
    category: 'Finance & Accounting',
    tags: [
      'Finance & Accounting',
      'Analytics & BI',
      'Financial Planning & Analysis',
    ],
    url: 'https://www.chartio.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/chartio/chartio-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Mixpanel',
    description:
      'Deeply understand every user\'s journey with instant insights for everyone on mobile and web.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.mixpanel.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/mixpanel/mixpanel-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'PivotalTracker',
    description:
      'Pivotal Tracker is an agile project management tool that shows software teams their work in progress and allows them to track upcoming milestones.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.pivotaltracker.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/pivotaltracker/pivotaltracker-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Egnyte',
    description:
      'Egnyte provides software for enterprise file synchronization and sharing.',
    category: 'Security / Backup',
    tags: ['Security / Backup', 'Storage'],
    url: 'https://www.egnyte.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/egnyte/egnyte-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Greenhouse',
    description:
      'Greenhouse is a recruiting optimization platform to help companies build and scale their recruiting processes.  Connect Greenhouse with Uelco to import candidates from Greenhouse when you hire them in Rippling.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Applicant Tracking'],
    url: 'https://www.greenhouse.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/greenhouse/greenhouse-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Five9',
    description:
      'Five9 is a company providing cloud-based call center software for sales, marketing and support.',
    category: '',
    tags: [],
    url: 'https://www.five9.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/five9/five9-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'RPass',
    description: 'Uelco Password Manager',
    category: 'Security / Backup',
    tags: ['Security / Backup'],
    url: 'https://www.rpass.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/rpass/rpass-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Marketo',
    description:
      'Marketo\'s powerful marketing automation software helps marketers master the art & science of digital marketing to engage customers and prospects.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing Automation'],
    url: 'https://www.marketo.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/marketo/marketo-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Azure',
    description: '',
    category: '',
    tags: [],
    url: 'https://www.azure.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/azure/azure-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Deputy',
    description:
      'Deputy is the employee management tool, deputy manages scheduling, timesheets, tasks and workplace communication for employees.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Time and Attendance'],
    url: 'https://www.deputy.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/deputy/deputy-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Looker',
    description:
      'Looker is a software company employing business intelligence to make data accessible to organizations and data analysts.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.looker.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/looker/looker-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'NetSuite',
    description: '',
    category: '',
    tags: [],
    url: 'https://www.netsuite.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/netsuite/netsuite-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SurveyMonkey',
    description:
      'SurveyMonkey is a provider of web-based survey solutions, trusted by millions of companies, organizations and individuals alike to gather the insights they need to make more informed decisions.',
    category: 'Human Resources',
    tags: [
      'Human Resources',
      'Sales & Marketing',
      'Performance Management',
      'Employee Recognition',
      'Surveys',
    ],
    url: 'https://www.surveymonkey.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/surveymonkey/surveymonkey-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Outreach',
    description:
      'The Outreach sales engagement platform helps efficiently and effectively engage prospects to drive more pipeline and close more deals.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Prospecting', 'Marketing Automation'],
    url: 'https://www.outreach.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/outreach/outreach-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Seamless',
    description: '',
    category: '',
    tags: [],
    url: 'https://www.seamless.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/seamless/seamless-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'WorkRamp',
    description: '',
    category: '',
    tags: [],
    url: 'https://www.workramp.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/workramp/workramp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Insurance',
    description: 'Offer health insurance to employees.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Popular', 'Payroll & Benefits'],
    url: 'https://www.insurance.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/insurance/insurance-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Lever',
    description:
      'Lever streamlines the hiring process and simplifies the Applicant Tracking System, so that recruiters and hiring managers can focus on what truly matters: their candidates.  Connect Lever with Uelco to import candidates from Lever when you hire them in Rippling.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Applicant Tracking'],
    url: 'https://www.lever.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/lever/lever-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Sentry',
    description:
      'Open-source error tracking that helps developers monitor and fix crashes in real time. Iterate continuously. Boost workflow efficiency. Improve user experience.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.sentry.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/sentry/sentry-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Basecrm',
    description:
      'CRM software geared towards scientifically making your small business, mid-market or enterprise sales team 10x more productive. Sell more with Base!',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM'],
    url: 'https://www.basecrm.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/basecrm/basecrm-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Degreed',
    description:
      'A million ways to learn, one place to discover, track and measure all of it. Get recognized for your skill, knowledge, and accomplishments with Degreed.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Training'],
    url: 'https://www.degreed.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/degreed/degreed-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Trello',
    description:
      'Infinitely flexible. Incredibly easy to use. Great mobile apps. It\'s free. Trello keeps track of everything, from the big picture to the minute details.',
    category: 'Collaboration',
    tags: ['Collaboration', 'Popular'],
    url: 'https://www.trello.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/trello/trello-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Periscopedata',
    description:
      'It\'s easy. Connect your databases and create beautiful analyses you can share with your whole company.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.periscopedata.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/periscopedata/periscopedata-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Airtable',
    description:
      'Airtable works like a spreadsheet but gives you the power of a database to organize anything. Sign up for free.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI', 'Collaboration', 'Storage'],
    url: 'https://www.airtable.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/airtable/airtable-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'FBWorkplace',
    description:
      'Connect Workplace by Facebook with Uelco to manage social accounts for your employees automatically.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.fbworkplace.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/fbworkplace/fbworkplace-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Reports',
    description: 'Uelco HRIS Reporting System.',
    category: 'Popular',
    tags: ['Popular'],
    url: 'https://www.reports.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/reports/reports-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExApp',
    description:
      'This is API only integration with ExApp. Test app to be only used for internal testing purposes',
    category: '',
    tags: [],
    url: 'https://www.exapp.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/exapp/exapp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Medical',
    description: 'Offer medical insurance to employees.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Popular', 'Payroll & Benefits'],
    url: 'https://www.medical.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/medical/medical-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Dental',
    description: 'Offer dental insurance to employees.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Popular', 'Payroll & Benefits'],
    url: 'https://www.dental.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/dental/dental-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Vision',
    description: 'Offer vision insurance to employees.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Popular', 'Payroll & Benefits'],
    url: 'https://www.vision.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/vision/vision-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Disability',
    description: 'Offer disability insurance to employees.',
    category: '',
    tags: [],
    url: 'https://www.disability.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/disability/disability-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Life',
    description: 'Offer life insurance to employees.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Popular', 'Payroll & Benefits'],
    url: 'https://www.life.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/life/life-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Commuter',
    description: 'Offer Commuter Benefits to employees.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Payroll & Benefits'],
    url: 'https://www.commuter.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/commuter/commuter-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Envoy',
    description:
      'Envoy is the visitor registration product that is changing how visitors are greeted in workplaces around the world.  Connect Envoy with Uelco to populate your employees automatically in Envoy, so visitors can select the person they\'re visiting.',
    category: 'eReceptionist',
    tags: ['eReceptionist'],
    url: 'https://www.envoy.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/envoy/envoy-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SendBloom',
    description:
      'Sendbloom is a sales automation platform that helps inside sales representatives and SDRs prospect, segment, and nurture target accounts. We\'re getting our clients more qualified opportunities per rep at a fraction of the time and cost.',
    category: 'Prospecting',
    tags: ['Prospecting', 'Sales & Marketing'],
    url: 'https://www.sendbloom.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/sendbloom/sendbloom-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'EatClub',
    description:
      'EAT Club is the best corporate catering and office lunch service, delivering lunch as a benefit to offices in San Francisco, Los Angeles, and New York.',
    category: 'Catering',
    tags: ['Catering'],
    url: 'https://www.eatclub.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/eatclub/eatclub-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Gitlab',
    description:
      'Connect GitLab.com with Uelco to manage GitLab access for your employees automatically. If you are using self-hosted GitLab, please click back and install GitLab (Self-Hosted) app instead.',
    category: 'Developer',
    tags: ['Developer', 'Source Control'],
    url: 'https://www.gitlab.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/gitlab/gitlab-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Emburse',
    description: '',
    category: '',
    tags: [],
    url: 'https://www.emburse.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/emburse/emburse-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'FrontApp',
    description:
      'The shared inbox for customer-driven teams. Manage all your customer conversations in one place with Front',
    category: 'Sales & Marketing',
    tags: [
      'Sales & Marketing',
      'Shared Inbox',
      'Collaboration',
      'Email',
      'Customer Support',
    ],
    url: 'https://www.frontapp.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/frontapp/frontapp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Teamwork',
    description:
      'Online project management, help desk & team messaging software designed to maximize your teams productivity, communication, and overall customer happiness.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.teamwork.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/teamwork/teamwork-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExAppSAML',
    description:
      'ExAppSAML is SAML Only version of ExApp integration. To be used for internal testing purposes',
    category: '',
    tags: [],
    url: 'https://www.exappsaml.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/exappsaml/exappsaml-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'OnboardingChecklist',
    description:
      'Do you have some new hire onboarding tasks that are impossible to automate with Uelco? If you have a template "project" you use to track these tasks in Asana or Wrike, Rippling can automatically kick off this template project each time you hire someone in Rippling.',
    category: 'Onboarding',
    tags: ['Onboarding', 'Human Resources', 'Popular'],
    url: 'https://www.onboardingchecklist.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/onboardingchecklist/onboardingchecklist-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SalesHood',
    description:
      'SalesHood is a sales productivity SaaS platform engaging salespeople to share best practices and learn from each other. Increase deal and ramp velocity fast with SalesHood.',
    category: 'Sales & Marketing',
    tags: [
      'Sales & Marketing',
      'Human Resources',
      'Sales Productivity',
      'Training',
    ],
    url: 'https://www.saleshood.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/saleshood/saleshood-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'LastPass',
    description:
      'From password autofill to automated security reports, LastPass makes it easy for businesses to fix poor passwords, without slowing down employees.  Note that you must be on the LastPass Enterprise plan to manage user accounts.',
    category: 'Security / Backup',
    tags: ['Security / Backup'],
    url: 'https://www.lastpass.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/lastpass/lastpass-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Anaplan',
    description:
      'Anaplan provides a planning platform that connects data, people, and plans in every part of your business.',
    category: 'Finance & Accounting',
    tags: [
      'Finance & Accounting',
      'Analytics & BI',
      'Financial Planning & Analysis',
    ],
    url: 'https://www.anaplan.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/anaplan/anaplan-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Breezy',
    description:
      'Breezy provides end-to-end recruiting software to help you attract and hire great employees with less effort',
    category: 'Human Resources',
    tags: ['Human Resources', 'Applicant Tracking'],
    url: 'https://www.breezy.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/breezy/breezy-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Guru',
    description:
      'Guru is a knowledge management solution that keeps your customer-facing teams up-to-date, consistent, and confident with trusted knowledge that’s accessible in every application they use.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Training', 'Knowledge Base', 'Collaboration'],
    url: 'https://www.guru.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/guru/guru-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Latticesso',
    description:
      'Lattice helps companies evolve their performance management system with performance reviews, real-time feedback, clear goal setting and one-on-one tools.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.latticesso.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/latticesso/latticesso-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Reflektive',
    description:
      'Performance management software made modern. Build a culture of feedback and watch your employees thrive.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.reflektive.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/reflektive/reflektive-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Wrike',
    description:
      'Wrike is online project management software that gives you full visibility and control over your tasks. With the help of our product, managing projects becomes easier.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.wrike.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/wrike/wrike-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Bonusly',
    description:
      'The easiest way to recognize and reward employees. Increase employee engagement and retention with Bonusly.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Employee Recognition'],
    url: 'https://www.bonusly.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/bonusly/bonusly-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Zoho',
    description:
      'Zoho offers a suite of business, collaboration, and productivity applications. The company offers over 30 online applications from CRM to mail, office suite, project management, invoicing, email marketing, social media management, and more.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM', 'Collaboration', 'Email'],
    url: 'https://www.zoho.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/zoho/zoho-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Capshare',
    description:
      'Issue stock today and manage all your equity in one place without getting bogged down in spreadsheets and paperwork.  Connect Capshare with Uelco to record option grants in your cap table when employees are hired.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Cap Table'],
    url: 'https://www.capshare.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/capshare/capshare-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Logrocket',
    description:
      'Stop guessing why bugs happen. LogRocket records everything users do on your site, helping you reproduce bugs and fix issues faster.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.logrocket.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/logrocket/logrocket-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'HelloSign',
    description:
      'HelloSign offers fast, secure, and legally binding eSignatures for businesses.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'eSignature'],
    url: 'https://www.hellosign.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hellosign/hellosign-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExpensifyAPI',
    description:
      'Let Expensify\'s intelligent automation handle your expenses in realtime. Because you have better things to do.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Popular', 'Expense Management'],
    url: 'https://www.expensifyapi.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/expensifyapi/expensifyapi-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Pipedrive',
    description:
      'Pipedrive is a CRM platform that helps small- to medium-sized businesses sell more.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM'],
    url: 'https://www.pipedrive.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/pipedrive/pipedrive-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Workerscomp',
    description:
      'Providing wage replacement and medical benefits to employees injured in the course of employment',
    category: '',
    tags: [],
    url: 'https://www.workerscomp.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/workerscomp/workerscomp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'OffboardingChecklist',
    description:
      'Do you have some termination tasks that are impossible to automate with Uelco? If you have a template "project" you use to track these tasks in Asana or Wrike, Rippling can automatically kick off this template project each time you terminate someone in Rippling.',
    category: 'Onboarding',
    tags: ['Onboarding', 'Human Resources'],
    url: 'https://www.offboardingchecklist.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/offboardingchecklist/offboardingchecklist-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Short Term Disability',
    description: 'Offer short term disability insurance to employees.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Popular', 'Payroll & Benefits'],
    url: 'https://www.short term disability.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/short term disability/short term disability-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Long Term Disability',
    description: 'Offer long term disability insurance to employees.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Popular', 'Payroll & Benefits'],
    url: 'https://www.long term disability.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/long term disability/long term disability-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Impraise',
    description:
      'Performance Reviews or 360 Degree Feedback for your team are set up in minutes. Simple reports and automated notifications keep everyone on the right track.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.impraise.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/impraise/impraise-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Sigopt',
    description:
      'SigOpt’s API tunes your model’s parameters through state-of-the-art Bayesian optimization.',
    category: 'Developer',
    tags: ['Developer', 'Machine Learning'],
    url: 'https://www.sigopt.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/sigopt/sigopt-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Druva',
    description:
      'Druva delivers secure backup as a service to the cloud across endpoints, servers and cloud applications, with integrated workflows, global deduplication, and deep search and data analytics capabilities making data protection and information management simpler, safer and more cost effective.',
    category: 'Security / Backup',
    tags: ['Security / Backup'],
    url: 'https://www.druva.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/druva/druva-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Checkr',
    description:
      'Checkr provides modern and compliant background checks for global enterprises and startups.  Connect Checkr with Uelco to run background checks with one click when hiring through Rippling.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Background Checks', 'Popular'],
    url: 'https://www.checkr.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/checkr/checkr-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'WhenIWork',
    description:
      'When I Work is an employee scheduling and communication app using the web, mobile apps, text messaging, social media, and email.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Time and Attendance'],
    url: 'https://www.wheniwork.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/wheniwork/wheniwork-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'HSA',
    description: 'Health Savings Account.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Payroll & Benefits'],
    url: 'https://www.hsa.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hsa/hsa-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'FSA',
    description: 'Flexible Spending Account.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Payroll & Benefits'],
    url: 'https://www.fsa.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/fsa/fsa-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AtlassianIdentityConfluence',
    description:
      'High performing teams work together to maximize productivity. Confluence combines the speed of creating on your own with the advantages of working together. Confluence makes it easy to create documents as a team, provide feedback in context and quickly iterate until your work is finalized. Install this app to enable SAML Single Sign-On from Uelco.  If your company uses Jira, install the Jira app to provision users for both Jira and Confluence.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.atlassianidentityconfluence.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/atlassianidentityconfluence/atlassianidentityconfluence-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExApp_API_SAML',
    description:
      'This is API + SAML integration with ExApp. Test app to be only used for internal testing purposes',
    category: '',
    tags: [],
    url: 'https://www.exapp_api_saml.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/exapp_api_saml/exapp_api_saml-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExApp_API_SAML_RPass',
    description:
      'This is API + SAML + RPass integration with ExApp. Test app to be only used for internal testing purposes',
    category: '',
    tags: [],
    url: 'https://www.exapp_api_saml_rpass.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/exapp_api_saml_rpass/exapp_api_saml_rpass-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExApp_RPass',
    description:
      'This is RPass only integration with ExApp. To be used for internal testing purposes',
    category: '',
    tags: [],
    url: 'https://www.exapp_rpass.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/exapp_rpass/exapp_rpass-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Twitter',
    description:
      'Manage which employees have access to your company Twitter account.',
    category: 'Social Media',
    tags: ['Social Media'],
    url: 'https://www.twitter.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/twitter/twitter-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Sendoso',
    description:
      'Sendoso is a B2B engagement platform for sending eGifts, swag, handwritten notes, and custom gifts.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Swag', 'Gifts'],
    url: 'https://www.sendoso.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/sendoso/sendoso-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExApp_API_RPass',
    description:
      'This is API + RPass integration with ExApp. Test app to be only used for internal testing purposes',
    category: '',
    tags: [],
    url: 'https://www.exapp_api_rpass.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/exapp_api_rpass/exapp_api_rpass-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExApp_SAML',
    description:
      'This is SAML only integration with ExApp. To be used for internal testing purposes',
    category: '',
    tags: [],
    url: 'https://www.exapp_saml.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/exapp_saml/exapp_saml-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExApp_SAML_RPass',
    description:
      'This is SAML + RPass integration with ExApp. To be used for internal testing purposes',
    category: '',
    tags: [],
    url: 'https://www.exapp_saml_rpass.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/exapp_saml_rpass/exapp_saml_rpass-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExApp_API',
    description:
      'This is API only integration with ExApp. Test app to be only used for internal testing purposes',
    category: '',
    tags: [],
    url: 'https://www.exapp_api.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/exapp_api/exapp_api-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Benefits',
    description: 'Offer Commuter benefits, HSA, FSA to employees.',
    category: '',
    tags: [],
    url: 'https://www.benefits.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/benefits/benefits-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'MailChimp',
    description:
      'MailChimp is the world\'s largest marketing automation platform. It\'s like a second brain that helps millions of customers -- from small e-commerce shops to big online retailers -- find their audience, engage their customers, and build their brand.',
    category: 'Email',
    tags: ['Email'],
    url: 'https://www.mailchimp.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/mailchimp/mailchimp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SVB',
    description:
      'Silicon Valley Bank helps you build your business at every stage. Install this app to manage your SVB account login.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Banking'],
    url: 'https://www.svb.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/svb/svb-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'GoDaddy',
    description: 'GoDaddy is the world\'s largest domain registrar.',
    category: 'Domain Registrar',
    tags: ['Domain Registrar', 'Developer'],
    url: 'https://www.godaddy.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/godaddy/godaddy-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Amazon',
    description:
      'Create an Amazon account and share it with your team to allow them to order refills for the office.',
    category: 'Office Management',
    tags: ['Office Management'],
    url: 'https://www.amazon.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/amazon/amazon-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Stripe',
    description:
      'The new standard in online payments. Stripe is the best software platform for running an internet business. We handle billions of dollars every year for forward-thinking businesses around the world.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Payment Gateway', 'Developer', 'Banking'],
    url: 'https://www.stripe.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/stripe/stripe-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Intercom',
    description:
      'Modern messaging products for sales, marketing and support - all on the first platform made with customers in mind.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM', 'Customer Support'],
    url: 'https://www.intercom.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/intercom/intercom-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'DigitalOcean',
    description:
      'A cloud computing platform built with simplicity at the forefront, so managing infrastructure is easy, whether your business is running one virtual machine or ten thousand.',
    category: 'Cloud Hosting',
    tags: ['Cloud Hosting', 'Developer'],
    url: 'https://www.digitalocean.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/digitalocean/digitalocean-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'PayPal',
    description:
      'PayPal helps over 17 million businesses with payment and credit solutions. PayPal has everything you need to accept all kinds of payments online, across devices, around the world.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Payment Gateway', 'Developer', 'Banking'],
    url: 'https://www.paypal.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/paypal/paypal-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'HubSpot',
    description:
      'HubSpot offers a full stack of software for marketing, sales, and customer success, with a completely free CRM at its core. They\'re powerful alone - but even better when used together.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing Automation'],
    url: 'https://www.hubspot.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hubspot/hubspot-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Bill.com',
    description:
      'Bill.com is the leading digital business payments company creating magically simple AP and AR solutions.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Expense Management'],
    url: 'https://www.bill.com.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/bill.com/bill.com-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Xero',
    description:
      'Xero provides beautiful cloud accounting software for accounting professionals and small businesses, with over 600,000 global customers.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting'],
    url: 'https://www.xero.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/xero/xero-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Hightail',
    description:
      'Better, faster creative projects.  Streamline creative reviews by replacing low-res attachments and piecemeal feedback with simple, intuitive collaboration.',
    category: 'Collaboration',
    tags: ['Collaboration', 'Storage'],
    url: 'https://www.hightail.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hightail/hightail-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Pandadoc',
    description:
      'Send proposals and quotes in minutes. Streamline the way your team creates, sends, and tracks sales documents so you can close more deals, faster.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'eSignature'],
    url: 'https://www.pandadoc.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/pandadoc/pandadoc-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Facebook',
    description:
      'Make meaningful connections with people to grow your business. Millions of businesses, big and small, use Facebook\'s apps and services to connect with real people on any device.',
    category: 'Social Media',
    tags: ['Social Media'],
    url: 'https://www.facebook.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/facebook/facebook-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Instagram',
    description:
      'Tap into people\'s passions to create deeper relationships with your business online and off.',
    category: 'Social Media',
    tags: ['Social Media'],
    url: 'https://www.instagram.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/instagram/instagram-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Canva',
    description:
      'Easily create beautiful designs and documents. Use Canva\'s drag-and-drop feature and professional layouts to design consistently stunning graphics.',
    category: 'Design',
    tags: ['Design'],
    url: 'https://www.canva.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/canva/canva-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Cloudflare',
    description:
      'Cloudflare speeds up and protects millions of websites, APIs, SaaS services, and other properties connected to the Internet. Our Anycast technology enables our benefits to scale with every server we add to our growing footprint of data centers.',
    category: 'Security / Backup',
    tags: ['Security / Backup', 'Cloud Hosting', 'Developer'],
    url: 'https://www.cloudflare.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/cloudflare/cloudflare-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Consensus',
    description:
      'Discover and engage stakeholders across the buying group with personalized video demos. Experience interactive demo automation for yourself.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Sales Productivity'],
    url: 'https://www.consensus.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/consensus/consensus-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Calendly',
    description:
      'Calendly helps you schedule meetings without the back-and-forth emails.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Sales Productivity'],
    url: 'https://www.calendly.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/calendly/calendly-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'LinkedIn',
    description:
      'LinkedIn is the world\'s largest professional network with more than 546 million users in more than 200 countries and territories worldwide.',
    category: 'Prospecting',
    tags: ['Prospecting', 'Sales & Marketing', 'Social Media'],
    url: 'https://www.linkedin.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/linkedin/linkedin-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Crisp',
    description:
      'Chat with website visitors, integrate your favorite tools, and deliver a great customer experience. Crisp is the best way to interact with your website visitors.',
    category: 'Customer Support',
    tags: ['Customer Support'],
    url: 'https://www.crisp.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/crisp/crisp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'App',
    description: '',
    category: '',
    tags: [],
    url: 'https://www.app.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/app/app-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Monday',
    description:
      'Simplify the way your team works! Manage your workload, communicate with your team and celebrate success!',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.monday.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/monday/monday-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Hootsuite',
    description:
      'Hootsuite is one of the most used platforms for managing social media. You can monitor keywords, manage multiple Twitter, Facebook, LinkedIn, Foursquare, Ping.fm and WordPress profiles, schedule messages, and measure your success.',
    category: 'Social Media',
    tags: ['Social Media'],
    url: 'https://www.hootsuite.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hootsuite/hootsuite-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Dialpad',
    description:
      'Dialpad\'s mission is to make business communications great. It is business phone system with extremely simple and very elegant interface.',
    category: 'Phone systems',
    tags: ['Phone systems'],
    url: 'https://www.dialpad.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/dialpad/dialpad-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'TriNet',
    description:
      'TriNet is a strategic partner to small and medium-sized businesses for HR, payroll, employee benefits, employment law compliance and risk mitigation. TriNet provides three bundled HR products tailored to specific industries. Each bundled HR product includes their core services: payroll, benefits, risk & compliance, an HR team and cloud platform.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Payroll & Benefits'],
    url: 'https://www.trinet.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/trinet/trinet-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Ataata',
    description:
      'Developed by top leadership from the U.S. military, law enforcement and the intelligence community, Ataata is a security awareness training and cyber risk management platform that helps you combat information security breaches caused by employee mistakes.',
    category: 'Security / Backup',
    tags: ['Security / Backup', 'Human Resources', 'Training'],
    url: 'https://www.ataata.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/ataata/ataata-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Prosperworks',
    description:
      'The #1 CRM for G Suite. Copper is embedded into Gmail so you can update opportunities, add contacts, get account histories, and manage your pipeline right from your inbox.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM'],
    url: 'https://www.prosperworks.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/prosperworks/prosperworks-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SugarCRM',
    description:
      'An open-source CRM, SugarCRM enables businesses to create extraordinary customer relationships with the most innovative CRM solution in the market.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM'],
    url: 'https://www.sugarcrm.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/sugarcrm/sugarcrm-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Toggl',
    description:
      'Toggl makes time tracking so simple you’ll actually use it. But even if you forget, their tracking reminders and idle detection have your back.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Time and Attendance'],
    url: 'https://www.toggl.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/toggl/toggl-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Datadog',
    description:
      'Datadog is a monitoring service for IT, Dev and Ops teams who write and run applications at scale, and want to turn the massive amounts of data produced by their apps, tools and services into actionable insight.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.datadog.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/datadog/datadog-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'UserTesting',
    description:
      'UserTesting is a crowdsourcing service that provides usability testing through a large panel of on-demand users.',
    category: 'Developer',
    tags: ['Developer', 'Test Management'],
    url: 'https://www.usertesting.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/usertesting/usertesting-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Wistia',
    description:
      'Wistia provides video software for the video-loving business.',
    category: 'Video Software',
    tags: ['Video Software', 'Developer'],
    url: 'https://www.wistia.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/wistia/wistia-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Pingdom',
    description:
      'Website Performance Monitoring Made Easy. We help you get instant insights into your website\'s availability and performance so that you can outsmart competition with an amazing end-user experience.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.pingdom.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/pingdom/pingdom-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Moz',
    description:
      'Drive customers to your website With the all-in-one SEO tracking and research toolset built by industry experts.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'SEO'],
    url: 'https://www.moz.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/moz/moz-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Sketch',
    description:
      'With Sketch Cloud you can quickly review and comment on designs, with no hassle. If you’re a designer you can upload your documents, straight from Sketch, and share them directly with clients and colleagues.',
    category: 'Design',
    tags: ['Design', 'Collaboration'],
    url: 'https://www.sketch.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/sketch/sketch-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Pardot',
    description:
      'Powerful B2B marketing automation featuring lead scoring, nurturing, email marketing and more.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing Automation'],
    url: 'https://www.pardot.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/pardot/pardot-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Carta',
    description:
      'Carta makes it simple for companies of all stages to turn employees into owners. As an SEC-registered transfer agent, we help companies issue, value, and transfer securities.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Cap Table'],
    url: 'https://www.carta.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/carta/carta-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Browserstack',
    description:
      'The only testing infrastructure you need. Instant access to 1100+ real mobile devices and browsers. Ship mobile apps and websites that work for everyone, everytime.',
    category: 'Developer',
    tags: ['Developer', 'Test Management'],
    url: 'https://www.browserstack.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/browserstack/browserstack-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Cybrary',
    description:
      'Cybrary is a crowdsourced cyber security and IT career development platform.  Its ecosystem of people, companies, content, and technologies converge to create an ever-growing catalog of online courses and experiential tools that provide cyber security and IT learning opportunities to anyone, anywhere, anytime.',
    category: 'Security / Backup',
    tags: ['Security / Backup', 'Human Resources', 'Training'],
    url: 'https://www.cybrary.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/cybrary/cybrary-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Glassdoor',
    description:
      'Start hiring informed candidates. Get set up in minutes and drive more applicants to your jobs.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Job Boards'],
    url: 'https://www.glassdoor.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/glassdoor/glassdoor-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SumoLogic',
    description:
      'Sumo Logic, a log management and analytics service, transforms big data into sources of operations, security and compliance intelligence.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.sumologic.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/sumologic/sumologic-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Sisense',
    description:
      'Sisense is an end-to-end business analytics software that enables users to easily prepare and analyze complex data, covering the full scope of analysis from data integration to visualization.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.sisense.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/sisense/sisense-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Northpass',
    description:
      'With Northpass, you can efficiently train employees, customers, and partners with a single solution.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Training'],
    url: 'https://www.northpass.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/northpass/northpass-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Lookback',
    description:
      'Lookback is next-generation UX research platform for mobile and desktop.',
    category: 'Design',
    tags: ['Design'],
    url: 'https://www.lookback.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/lookback/lookback-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ScreenSteps',
    description:
      'ScreenSteps helps teams create better help documentation by either creating a new online knowledge base or improving the knowledge base they are already using.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Training'],
    url: 'https://www.screensteps.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/screensteps/screensteps-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Recurly',
    description:
      'Recurly is a leading SAAS based company, that provides enterprise-class recurring billing management. Recurly helps subscribers reduce unnecessary credit card declines and improve operational efficiency.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Expense Management'],
    url: 'https://www.recurly.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/recurly/recurly-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'LiquidPlanner',
    description:
      'LiquidPlanner is one of the industry’s best online project management solution. It helps teams to plan, schedule, execute the work, and deliver projects on-time without any hassle. Being the world’s one and only predictive project management solution, LiquidPlanner makes your team more effective.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.liquidplanner.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/liquidplanner/liquidplanner-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Twilio',
    description:
      'Twilio is an excellent developer platform for communications that facilitates worldwide connectivity with cloud-based communications APIs and SDKs for SMS, Voice and Messaging applications.',
    category: 'Phone systems',
    tags: ['Phone systems'],
    url: 'https://www.twilio.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/twilio/twilio-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SmallImprovements',
    description:
      'Small Improvements is one of the best solution to manage performance reviews conveniently. Small Improvements help you capture one-on-one meetings, recognize great work, and a lot more.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.smallimprovements.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/smallimprovements/smallimprovements-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ModeAnalytics',
    description:
      'Mode Analytics provides data teams with powerful tools to analyze data. It also helps people and companies to make better decisions, explore data using SQL, create interactive reports and ship them instantly throughout the business.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.modeanalytics.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/modeanalytics/modeanalytics-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Optimizely',
    description:
      'Optimizely allows businesses to experience the optimization platform. It enables personalization across the websites, mobile apps, and connected devices.',
    category: 'Optimization',
    tags: ['Optimization', 'Developer'],
    url: 'https://www.optimizely.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/optimizely/optimizely-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Freshdesk',
    description:
      'Freshdesk is one of the best solutions for customer support. It helps businesses to engage and delight with their customers as well as their employees.',
    category: 'Customer Support',
    tags: ['Customer Support'],
    url: 'https://www.freshdesk.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/freshdesk/freshdesk-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Buildkite',
    description:
      'Buildkite is the world’s best platform to automate your team’s software development processes. It takes care of everything from testing to delivery, no matter the environment, toolchain or language.',
    category: 'Test Management',
    tags: ['Test Management', 'Developer', 'Collaboration'],
    url: 'https://www.buildkite.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/buildkite/buildkite-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'NexTravel',
    description:
      'NexTravel is an excellent corporate travel management software. It helps employees to search flights, book hotels, and makes it more simpler to manage business travel.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Travel Management'],
    url: 'https://www.nextravel.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/nextravel/nextravel-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Lucidchart',
    description:
      'Lucidchart is the world’s leading solution for diagramming, visual communication, and cross-platform collaboration. It allows users to create flowcharts, UML models, organizational charts, process maps, and a lot more.',
    category: 'Design',
    tags: ['Design'],
    url: 'https://www.lucidchart.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/lucidchart/lucidchart-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Lucidpress',
    description:
      'Lucidpress is a web-based graphic design software, that enables you to create content for digital as well as print. It allows you to create brochures, newsletters, flyers, magazines, business cards, and a lot more.',
    category: 'Design',
    tags: ['Design'],
    url: 'https://www.lucidpress.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/lucidpress/lucidpress-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Lynda',
    description:
      'Lynda.com is an excellent online platform for learning software, technology, creative and business skills to achieve personal and professional goals. It offers a wide range of video courses taught by industry experts.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Training'],
    url: 'https://www.lynda.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/lynda/lynda-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Smartsheet',
    description:
      'Smartsheet is a cloud-based work management platform that empowers collaboration, drives better decision making, and accelerates innovation. Connect Smartsheet with Uelco to manage Smartsheet accounts for your employees automatically.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.smartsheet.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/smartsheet/smartsheet-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Evernote',
    description:
      'Evernote is a suite of software and services that allow users to capture, organize, and find information across multiple platforms.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.evernote.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/evernote/evernote-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'GitlabHosted',
    description:
      'Connect your hosted instance of GitLab.com with Uelco to manage GitLab access for your employees automatically. Use this to only connect with your hosted instance of Gitlab. Supports integration with Gitlab v8.x and above.',
    category: 'Developer',
    tags: ['Developer', 'Source Control'],
    url: 'https://www.gitlabhosted.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/gitlabhosted/gitlabhosted-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Interlaced',
    description:
      'Built by Apple employees, Interlaced is the industry\'s most operationally mature provider of Apple IT services. Access real-time support from an Apple-certified technical consultant with the simple click of a button.',
    category: 'IT Management',
    tags: ['IT Management'],
    url: 'https://www.interlaced.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/interlaced/interlaced-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Goodly',
    description:
      'Goodly makes it easy for companies to offer student loan repayment as an employee benefit. Connect Goodly with Uelco to provision Goodly accounts automatically for your employees and manage the plans they are enrolled in. If you have an existing Goodly account already connected with a different payroll provider, you need to notify support@goodlyapp.com before connecting to Rippling.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Payroll & Benefits'],
    url: 'https://www.goodly.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/goodly/goodly-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'DeskTime',
    description:
      'DeskTime is a real-time automatic time tracking software that tracks and analyzes your productivity by sorting the different applications that you or our employees use into the categories "productive", "unproductive" or "neutral".',
    category: 'Human Resources',
    tags: ['Human Resources', 'Time and Attendance'],
    url: 'https://www.desktime.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/desktime/desktime-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SurveyGizmo',
    description:
      'SurveyGizmo is a powerful, online survey platform for business professionals to help them design online surveys, collect data and perform analysis to make informed descisions.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Surveys'],
    url: 'https://www.surveygizmo.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/surveygizmo/surveygizmo-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'HackerRank',
    description:
      'HackerRank is a technical hiring platform and conducts programming competition for developers.',
    category: 'Developer',
    tags: ['Developer'],
    url: 'https://www.hackerrank.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hackerrank/hackerrank-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'BetterWorks',
    description:
      'BetterWorks provides a continuous performance management platform that helps employees to give ongoing feedback, set goals, and to connect every aspect of the performance process.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.betterworks.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/betterworks/betterworks-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Gong',
    description:
      'Gong is a sales conversations intelligence software that helps sales teams to sell and coach better by analyzing sales calls, transcribing and recording automatically and give demos using artificial intelligence.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Sales Productivity'],
    url: 'https://www.gong.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/gong/gong-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Blueboard',
    description:
      ' Blueboard is a rewards and recognition platform for the modern workplace. It allows companies to reward their employees through a range of activities and experiences.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Employee Recognition'],
    url: 'https://www.blueboard.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/blueboard/blueboard-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Checklist',
    description:
      'Create template "tasks" for people in your company that are triggered automatically whenever you hire or offboard an employee.',
    category: 'Onboarding',
    tags: ['Onboarding', 'Human Resources', 'Popular'],
    url: 'https://www.checklist.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/checklist/checklist-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'RingCentral',
    description:
      'RingCentral is a global distributor of cloud-unified communications and collaboration solutions. It offers high quality telephone services.',
    category: 'Phone systems',
    tags: ['Phone systems'],
    url: 'https://www.ringcentral.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/ringcentral/ringcentral-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Bluejeans',
    description:
      'BlueJeans provides interoperable cloud-based video conferencing services that connect participants across a wide range of devices and conferencing platforms. Connect BlueJeans with Uelco to manage BlueJeans access for your employees automatically.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Virtual Meetings', 'Collaboration'],
    url: 'https://www.bluejeans.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/bluejeans/bluejeans-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'OfficeSpace',
    description:
      'OfficeSpace is a simple facility management software to manage day-to-day office moves to complex relocations.',
    category: 'Office Management',
    tags: ['Office Management'],
    url: 'https://www.officespace.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/officespace/officespace-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Lessonly',
    description:
      'Lessonly is team learning software that helps businesses digitize, distribute, and measure the effectiveness of their training and accelerate productivity.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Learning Management System', 'Training'],
    url: 'https://www.lessonly.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/lessonly/lessonly-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SendGrid',
    description:
      'SendGrid is a leader in trusted email delivery. It\'s a powerful cloud-based solution that manages transactional and marketing email delivery for over 58,000 paying customers, allowing them to focus on their business, instead of their email infrastructure.',
    category: 'Email',
    tags: ['Email'],
    url: 'https://www.sendgrid.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/sendgrid/sendgrid-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Statuspage',
    description:
      'Build your own statuspage for managing downtime, incidents reporting, maintenance schedule.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.statuspage.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/statuspage/statuspage-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Namely',
    description:
      'Namely is an all-in-one platform that manages all HR, payroll, benefits and talent management data in one place, with personalized service to help companies get better and faster.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Payroll & Benefits'],
    url: 'https://www.namely.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/namely/namely-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SalesLoft',
    description:
      'SalesLoft is the Sales Engagement Platform to power your company\'s success. It helps companies believe in their product, their process, and their people.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing Automation'],
    url: 'https://www.salesloft.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/salesloft/salesloft-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'TimeDoctor',
    description:
      'Manage your employees productivity, Payments, Payroll and Timesheet.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Time and Attendance'],
    url: 'https://www.timedoctor.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/timedoctor/timedoctor-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SANS',
    description:
      'SANS Provides information security training, certification, and research.',
    category: 'Security / Backup',
    tags: ['Security / Backup'],
    url: 'https://www.sans.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/sans/sans-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'TinyPulse',
    description:
      'TINYpulse is the leading employee engagement platform, facilitating employee feedback and recognition, and keeping open lines of communication between managers and contributors to boost employee productivity and retention.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.tinypulse.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/tinypulse/tinypulse-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AdaptiveInsights',
    description:
      'Adaptive Insights is a leading provider of complete performance management solutions. Adaptive Insights is powering a new generation of business planning.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.adaptiveinsights.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/adaptiveinsights/adaptiveinsights-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Trainual',
    description:
      'Trainual is a simple system to document every policy and procedure in your business with simple step-by-step processes. Onboard new users with ease, train your team consistently, and ensure everyone in the organization is 100% up to date and up to speed on how you do what you do. Whether you use Trainual to build your operations manual, to centralize your standard operating procedures, or just to capture the institutional knowledge floating around your organization, with Uelco\'s integration your users will be up and running in minutes.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Onboarding', 'Training'],
    url: 'https://www.trainual.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/trainual/trainual-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'spoke',
    description: 'Simpler way to manage workspace requests.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Knowledge Base'],
    url: 'https://www.spoke.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/spoke/spoke-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Askspoke',
    description:
      'Simpler way to manage workspace requests. It is designed for all of the teams that respond to frequent requests and questions from employees: IT, Office Management, Operations, People Ops, HR, Facilities.',
    category: 'Knowledge Base',
    tags: ['Knowledge Base'],
    url: 'https://www.askspoke.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/askspoke/askspoke-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'certify',
    description:
      'Certify helps you manage travel, report, invoices and automate your expense report ',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting'],
    url: 'https://www.certify.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/certify/certify-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ExactTarget',
    description:
      'ExactTarget is cloud based solution for email marketing and digital communication platform',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing Automation'],
    url: 'https://www.exacttarget.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/exacttarget/exacttarget-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Quip',
    description:
      'Quip is a platform that makes things happen with less email and fewer meetings. It combines spread sheets, docs, and communication to help teams get work done smarter and faster.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.quip.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/quip/quip-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'tableauonline',
    description: 'SAAS Intelligence analytics',
    category: '',
    tags: [],
    url: 'https://www.tableauonline.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/tableauonline/tableauonline-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Harvest',
    description:
      'Harvest manages your employees time, invoices, and forecasts to schedule your team.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Time and Attendance'],
    url: 'https://www.harvest.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/harvest/harvest-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Konverse',
    description:
      'Konverse is an employee engagement and collaboration platform, that replaces internal emailing, shared drives, and intranets with a more intuitive, all-in-one communications app accessible via desktop and mobile.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Onboarding', 'Training', 'Knowledge Base'],
    url: 'https://www.konverse.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/konverse/konverse-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Zugata',
    description:
      'Zugata is a performance management system built for performance evaluation and performance development. It also enables a culture of continual feedback, development resources and lightweight evaluations.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.zugata.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/zugata/zugata-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'HackerOne',
    description:
      'HackerOne is a vulnerability coordination and bug bounty platform that help organizations reduce the risk of security incidents.',
    category: 'Security / Backup',
    tags: ['Security / Backup'],
    url: 'https://www.hackerone.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hackerone/hackerone-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Zesty',
    description:
      'Zesty provides employees with healthy delicious food, with world class service.',
    category: 'Catering',
    tags: ['Catering'],
    url: 'https://www.zesty.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/zesty/zesty-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Raiser\'sEdge',
    description:
      'Raiser\'s Edge is a non-profit fundraising tool, helps to manage donors and conduct email marketing.',
    category: 'Nonprofit Software',
    tags: ['Nonprofit Software'],
    url: 'https://www.raiser\'sedge.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/raiser\'sedge/raiser\'sedge-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'FloQast',
    description:
      'FloQast is the best close management software that simplifies your monthly close with a centralized checklist and automated tie-outs.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Expense Management'],
    url: 'https://www.floqast.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/floqast/floqast-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Fossa',
    description:
      'FOSSA is an automated open source license scanning and vulnerability management tool that help developers to analyze code automatically ',
    category: '',
    tags: [],
    url: 'https://www.fossa.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/fossa/fossa-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Concur',
    description:
      'Concur provides spend management solutions  along with managing your travel and invoice',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Expense Management'],
    url: 'https://www.concur.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/concur/concur-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ServiceNow',
    description:
      'ServiceNow is a cloud platform that manages, defines, structures and automate services for enterprise operations.',
    category: 'IT Management',
    tags: ['IT Management'],
    url: 'https://www.servicenow.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/servicenow/servicenow-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Saba',
    description:
      'Saba is a cloud-based intelligent talent management solution that helps enterprises to hire, develop, engage and inspire their people.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.saba.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/saba/saba-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'WebEx',
    description:
      'WebEx is the best company that provides on-demand applications for organizations to conduct online meetings, presentations, webinars, online courses and training.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.webex.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/webex/webex-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Qualtrics',
    description:
      'Qualtrics is a privately held experience management company that helps in managing customers, products, employees and brand experiences on a single platform.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.qualtrics.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/qualtrics/qualtrics-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Peakon',
    description:
      'Peakon measures manages and helps to improve employee productivity.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.peakon.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/peakon/peakon-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Alterdesk',
    description:
      'Alterdesk is a communication platform, offering secure messaging services, file sharing and video calling functionalities for business market.',
    category: 'Social',
    tags: ['Social'],
    url: 'https://www.alterdesk.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/alterdesk/alterdesk-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Pingboard',
    description:
      'Pingboard is a collaborative organization chart that makes it easy to organize teams, plan for growth, and keep everyone informed.',
    category: 'Org Charts',
    tags: ['Org Charts', 'Human Resources', 'Org charts / directory'],
    url: 'https://www.pingboard.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/pingboard/pingboard-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: '7Geese',
    description:
      '7Geese is a continuous performance management platform that empowers employees to achieve better goals, receive recognition, and gather continuous feedback in a cloud-based private social network environment.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management'],
    url: 'https://www.7geese.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/7geese/7geese-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Clio',
    description:
      'Easily manage a results-driven law firm with the only cloud-based legal practice management software that’s invested in your success.',
    category: 'Legal Software',
    tags: ['Legal Software'],
    url: 'https://www.clio.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/clio/clio-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Clubhouse',
    description:
      'Clubhouse is a project management platform for software teams that provides the perfect balance of simplicity and structure.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.clubhouse.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/clubhouse/clubhouse-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Fullstory',
    description:
      'FullStory records and reproduces real user experiences on your site helping you support customers, boost conversions, and debug faster.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer', 'Customer Support'],
    url: 'https://www.fullstory.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/fullstory/fullstory-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Quickbooks',
    description:
      'Quickbooks provides easy online accounting to organize your finances in one place.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Accounting'],
    url: 'https://www.quickbooks.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/quickbooks/quickbooks-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Wisestamp',
    description:
      'Wisestamp is the professional email signature to grow your business.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing', 'Design', 'Email'],
    url: 'https://www.wisestamp.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/wisestamp/wisestamp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'DataFox',
    description:
      'DataFox helps sales and marketing teams prospect smarter and have thoughtful, personalized conversations at exactly the right time.',
    category: 'Prospecting',
    tags: ['Prospecting', 'Sales & Marketing', 'Marketing'],
    url: 'https://www.datafox.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/datafox/datafox-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ZoomInfo',
    description:
      'ZoomInfo is a platform designed to get you in touch with the right people and companies. Find the prospects you’re looking for based on industry, location, company size, company revenue, job title, job function, and more. ZoomInfo’s database provides access to the most direct dials and email addresses than any other market intelligence provider.',
    category: 'Prospecting',
    tags: ['Prospecting', 'Sales & Marketing', 'Marketing'],
    url: 'https://www.zoominfo.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/zoominfo/zoominfo-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Salsa',
    description:
      'Salsa CRM is a cloud-based donor management system that provides fundraising, reporting, and management tools. Along with other tools such as database security and email marketing, Salsa CRM aims to help nonprofit organizations attract new donors, expand interaction with existing donors, and retain their constituents.',
    category: 'Nonprofit Software',
    tags: ['Nonprofit Software'],
    url: 'https://www.salsa.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/salsa/salsa-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'OneCause',
    description:
      'OneCause (formerly BidPal) provides an easy-to-use suite of fundraising solutions to help nonprofits raise more funds and reach more donors.',
    category: 'Nonprofit Software',
    tags: ['Nonprofit Software'],
    url: 'https://www.onecause.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/onecause/onecause-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'DoubleTheDonation',
    description:
      'Double the Donation is a fundraising solution to help nonprofits and schools increase fundraising from corporate matching gift and employee volunteer grant programs.',
    category: 'Nonprofit Software',
    tags: ['Nonprofit Software'],
    url: 'https://www.doublethedonation.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/doublethedonation/doublethedonation-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Snyk',
    description:
      'Snyk finds and fixes vulnerabilities in your application dependencies and help stay secure.',
    category: 'Security / Backup',
    tags: ['Security / Backup'],
    url: 'https://www.snyk.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/snyk/snyk-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Algolia',
    description:
      'Algolia\'s full suite APIs enable teams to develop unique search and discovery experiences for their customers across all platforms and devices.',
    category: 'Developer',
    tags: ['Developer', 'Search'],
    url: 'https://www.algolia.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/algolia/algolia-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Scalyr',
    description: 'Scalyr is log management platform for companies.',
    category: '',
    tags: [],
    url: 'https://www.scalyr.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/scalyr/scalyr-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Intacct',
    description:
      'Intacct is a accounting management software that is suitable for small and midsize enterprises. It provides real-time financial and operational insights.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Accounting'],
    url: 'https://www.intacct.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/intacct/intacct-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Eloqua',
    description:
      'Eloqua is an award winning marketing automation software company that helps businesses to plan and execute personalized marketing.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing Automation'],
    url: 'https://www.eloqua.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/eloqua/eloqua-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'JiveSoftware',
    description:
      'Jive Software provides communication and collaboration solutions for business. It allows companies to engage with customers, employees and the social web.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Virtual Meetings', 'Collaboration'],
    url: 'https://www.jivesoftware.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/jivesoftware/jivesoftware-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'GustLaunch',
    description:
      'Gust Launch is the world\'s first Company-as-a-Service: one platform to easily incorporate, start, and run a company like a seasoned entrepreneur, designed by experienced startup founders, investors, and lawyers to help from launch to exit. Starting with incorporation, Gust Launch takes founders through the process of setting up their company, then provides ongoing legal help, fundraising support, bookkeeping and more as the company\'s needs grow.',
    category: '',
    tags: [],
    url: 'https://www.gustlaunch.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/gustlaunch/gustlaunch-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Delighted',
    description:
      'Delighted is the all-in-one solution for gathering feedback, gaining insight, and driving action to improve your experience.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM', 'Customer Support'],
    url: 'https://www.delighted.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/delighted/delighted-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Zeplin',
    description:
      'Zeplin is the ultimate collaboration tool between designers and developers',
    category: 'Design',
    tags: ['Design', 'Collaboration'],
    url: 'https://www.zeplin.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/zeplin/zeplin-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Retool',
    description:
      'Retool helps you build and customize your internal tools such as tools for customer support, local operators etc quickly and effectively.',
    category: 'Design',
    tags: ['Design', 'Developer'],
    url: 'https://www.retool.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/retool/retool-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'HeapAnalytics',
    description:
      'Heap builds analytics infrastructure for your online business and tracks events automatically. It autocaptures a complete customer dataset and lets you flexibly model it with Virtual Events.',
    category: '',
    tags: [],
    url: 'https://www.heapanalytics.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/heapanalytics/heapanalytics-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'CoderPad',
    description:
      'CoderPad is a tool available for conducting programming phone screen interviews. Helps you hire better candidates faster, with an intuitive live programming environment.',
    category: 'Developer',
    tags: ['Developer'],
    url: 'https://www.coderpad.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/coderpad/coderpad-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Cobra',
    description: 'Offer continued insurance coverage to terminated employees.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Popular', 'Payroll & Benefits'],
    url: 'https://www.cobra.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/cobra/cobra-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Cloudability',
    description:
      'Cloudability is a financial management tool for monitoring and analyzing cloud expenses across any organization.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Financial Planning & Analysis'],
    url: 'https://www.cloudability.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/cloudability/cloudability-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Qualys',
    description:
      'Qualys helps your business automate the full spectrum of auditing, compliance and protection of your IT systems and web applications.',
    category: 'Security / Backup',
    tags: ['Security / Backup', 'IT Management'],
    url: 'https://www.qualys.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/qualys/qualys-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AppleBusiness',
    description:
      'Apple designs, manufactures, and markets consumer electronics, personal computers, and software.',
    category: 'IT Management',
    tags: ['IT Management'],
    url: 'https://www.applebusiness.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/applebusiness/applebusiness-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Ooma',
    description:
      'Ooma creates powerful connected experiences in home and small business telecommunications service, as well as affordable home security.',
    category: 'Security / Backup',
    tags: ['Security / Backup', 'Phone systems'],
    url: 'https://www.ooma.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/ooma/ooma-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'WeWork',
    description:
      'WeWork is a platform for creators that transforms buildings into dynamic environments for creativity, focus, and collaboration.',
    category: 'Office Management',
    tags: ['Office Management', 'Collaboration'],
    url: 'https://www.wework.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/wework/wework-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Vistaprint',
    description:
      'Vistaprint configures and produces small quantities of individually customized goods.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Swag', 'Gifts'],
    url: 'https://www.vistaprint.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/vistaprint/vistaprint-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Pinterest',
    description:
      'Pinterest is a visual bookmarking tool for saving and discovering creative ideas.',
    category: 'Social Media',
    tags: ['Social Media', 'Collaboration'],
    url: 'https://www.pinterest.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/pinterest/pinterest-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'iStock',
    description:
      'iStock offers royalty-free images, illustrations and videos that will make you stand out.',
    category: 'Design',
    tags: ['Design'],
    url: 'https://www.istock.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/istock/istock-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Gettyimages',
    description:
      'Getty images offers high resolution royalty-free images, editorial stock photos, vector art, video footage clips and stock music licensing.',
    category: 'Design',
    tags: ['Design'],
    url: 'https://www.gettyimages.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/gettyimages/gettyimages-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Mailgun',
    description:
      'Mailgun offers APIs that allow users to send, receive and track emails.',
    category: 'Email',
    tags: ['Email'],
    url: 'https://www.mailgun.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/mailgun/mailgun-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'WordPress',
    description:
      'WordPress offers a web publishing software that can be used to create websites or blogs.',
    category: 'Cloud Hosting',
    tags: ['Cloud Hosting', 'Design', 'Developer'],
    url: 'https://www.wordpress.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/wordpress/wordpress-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Wufoo',
    description:
      'Wufoo offers web form creator to power your contact forms, online surveys, and event registrations.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing Automation', 'Surveys'],
    url: 'https://www.wufoo.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/wufoo/wufoo-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Unbounce',
    description:
      'Unbounce builds high-converting landing pages and popups for your marketing campaigns, without the need for developers.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing', 'Design'],
    url: 'https://www.unbounce.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/unbounce/unbounce-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AdRoll',
    description:
      'AdRoll is the e-commerce growth platform for businesses to grow revenue using web, social, and email advertising.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing', 'Marketing Automation'],
    url: 'https://www.adroll.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/adroll/adroll-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Tumblr',
    description:
      'Tumblr is a microblogging platform and social networking website which enables users to post multimedia and other content to a short-form blog.',
    category: 'Social Media',
    tags: ['Social Media'],
    url: 'https://www.tumblr.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/tumblr/tumblr-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Customer.io',
    description:
      'Customer.io enables users to send newsletters to selected segments of customers using their website data.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing'],
    url: 'https://www.customer.io.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/customer.io/customer.io-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Olark',
    description:
      'Olark is a live chat platform that enables businesses to talk to customers browsing their website.',
    category: 'Customer Support',
    tags: ['Customer Support'],
    url: 'https://www.olark.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/olark/olark-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Geckoboard',
    description:
      'Geckoboard is a live TV dashboard software that focuses teams to improve key metrics.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.geckoboard.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/geckoboard/geckoboard-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Buffer',
    description:
      'Buffer helps users share social media content by scheduling online posts throughout the day.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Social Media', 'Marketing Automation'],
    url: 'https://www.buffer.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/buffer/buffer-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'FedEx',
    description:
      'FedEx provides customers and businesses worldwide with a broad portfolio of transportation, e-commerce and business services',
    category: 'Shipping',
    tags: ['Shipping'],
    url: 'https://www.fedex.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/fedex/fedex-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Voicenation',
    description:
      'VoiceNation delivers in class Live Receptionist & Live Answering services for businesses.',
    category: 'Customer Support',
    tags: ['Customer Support'],
    url: 'https://www.voicenation.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/voicenation/voicenation-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Instacart',
    description:
      'Instacart delivers groceries and home essentials from a variety of local stores.',
    category: 'Catering',
    tags: ['Catering'],
    url: 'https://www.instacart.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/instacart/instacart-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Inspectlet',
    description:
      'Inspectlet is a session recording and heatmap visualization tool for websites.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.inspectlet.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/inspectlet/inspectlet-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Codeclimate',
    description:
      'Codeclimate offers automated code review for test coverage, complexity, duplication, security, style, and more.',
    category: 'Source Control',
    tags: ['Source Control', 'Developer', 'Collaboration', 'Test Management'],
    url: 'https://www.codeclimate.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/codeclimate/codeclimate-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Segment',
    description:
      'Segment is a reliable infrastructure for your customer data. Segment helps you to collect, clean, and control your customer data.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.segment.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/segment/segment-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Rackspace',
    description:
      'Rackspace is a cloud computing company that offers web, app, and email hosting. It enables businesses to run their workload in a public or private cloud.',
    category: 'Cloud Hosting',
    tags: ['Cloud Hosting', 'Developer', 'Email'],
    url: 'https://www.rackspace.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/rackspace/rackspace-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'UserVoice',
    description:
      'UserVoice is a feedback management software that helps you listen to your customers, focus product development efforts on features that matter, and innovate efficiently.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Performance Management', 'Customer Support'],
    url: 'https://www.uservoice.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/uservoice/uservoice-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Litmus',
    description:
      'Litmus provides a suite of email design and email marketing tools, helping you build, design, test, and analyze your emails.',
    category: 'Email',
    tags: ['Email'],
    url: 'https://www.litmus.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/litmus/litmus-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'HelpScout',
    description:
      'HelpScout is a help desk software company that provides an email-based customer support platform and knowledge base tool.',
    category: 'Customer Support',
    tags: ['Customer Support'],
    url: 'https://www.helpscout.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/helpscout/helpscout-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Qualaroo',
    description:
      'Qualaroo is a survey software that help companies to understand what their customers and prospects want and how to achieve it.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Surveys'],
    url: 'https://www.qualaroo.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/qualaroo/qualaroo-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Papertrail',
    description:
      'Papertrail delivers frustration-free log management for developers, systems engineers, and entrepreneurs.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.papertrail.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/papertrail/papertrail-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Ups',
    description:
      'United Parcel Service is an American multinational package delivery and supply chain management company.',
    category: 'Shipping',
    tags: ['Shipping'],
    url: 'https://www.ups.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/ups/ups-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Tableau',
    description:
      'Tableau is a business intelligence application that helps organizations unleash the power of their most valuable assets: their data and their people.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.tableau.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/tableau/tableau-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'CustomSAMLApp',
    description:
      'Use Uelco as the SAML Identity Provider for your internal services.',
    category: '',
    tags: [],
    url: 'https://www.customsamlapp.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/customsamlapp/customsamlapp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Frameio',
    description:
      'Privately upload, review, and share video with your entire team, anywhere in the world.',
    category: 'Video Software',
    tags: ['Video Software', 'Design', 'Developer', 'Collaboration'],
    url: 'https://www.frameio.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/frameio/frameio-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Dnsimple',
    description:
      'DNSimple provides domain management automation services, through beautiful UI and powerful API.',
    category: 'Cloud Hosting',
    tags: ['Cloud Hosting', 'Developer'],
    url: 'https://www.dnsimple.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/dnsimple/dnsimple-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Ebay',
    description:
      'eBay is an online marketplace that connects a global network of buyers and sellers.',
    category: '',
    tags: [],
    url: 'https://www.ebay.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/ebay/ebay-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Airbrake',
    description:
      'Airbrake is an error tracking company that collects and aggregates errors in web apps for developers.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.airbrake.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/airbrake/airbrake-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Shutterstock',
    description:
      'Shutterstock offers an online platform that enables its users to find and buy images, vectors, videos, and music.',
    category: 'Design',
    tags: ['Design'],
    url: 'https://www.shutterstock.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/shutterstock/shutterstock-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Appannie',
    description:
      'App Annie is the standard in app analytics and app market data, giving you one easy-to-use platform for running every stage of your app business.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.appannie.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/appannie/appannie-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Kissmetrics',
    description:
      'Kissmetrics offers behavioral analytics, segmentation and email campaign automation.',
    category: 'Sales & Marketing',
    tags: [
      'Sales & Marketing',
      'Analytics & BI',
      'Marketing Automation',
      'Email',
    ],
    url: 'https://www.kissmetrics.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/kissmetrics/kissmetrics-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Shopify',
    description:
      'Shopify is an e-commerce platform that allows anyone to easily sell online from anywhere.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing', 'Cloud Hosting', 'Developer'],
    url: 'https://www.shopify.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/shopify/shopify-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Skype',
    description:
      'Skype provides a telecommunications platform that offers free video chat and voice calls to internet-connected devices.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Virtual Meetings'],
    url: 'https://www.skype.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/skype/skype-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Campaignmonitor',
    description:
      'Campaign allows websites to attract new subscribers by sending them visually rich email newsletters and reports.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing Automation', 'Email'],
    url: 'https://www.campaignmonitor.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/campaignmonitor/campaignmonitor-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Crazyegg',
    description:
      'Crazy Egg allows website owners to create tests to figure out what users are doing on their site.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.crazyegg.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/crazyegg/crazyegg-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Upwork',
    description:
      'Upwork is one of the largest freelancing websites which makes it easier to get work done—anytime, anywhere.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Freelance Platforms'],
    url: 'https://www.upwork.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/upwork/upwork-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Eventbrite',
    description: 'Eventbrite provides online event planning services.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing'],
    url: 'https://www.eventbrite.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/eventbrite/eventbrite-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Customerio',
    description:
      'Customer.io enables users to send newsletters to selected segments of customers using their website data.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing', 'Email'],
    url: 'https://www.customerio.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/customerio/customerio-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'EEOC',
    description: 'Spoke to handle collection and reporting of EEO data',
    category: 'Onboarding',
    tags: ['Onboarding', 'Human Resources'],
    url: 'https://www.eeoc.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/eeoc/eeoc-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Mavenlink',
    description:
      'Mavenlink is an online project management system that combines resource planning, advanced project management, financial tools, and collaboration.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.mavenlink.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/mavenlink/mavenlink-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Justworks',
    description:
      'Payroll, benefits, HR, and compliance -- all in one place. Justworks simplifies your business\'s back office complexities, so you can work fearlessly.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Payroll & Benefits'],
    url: 'https://www.justworks.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/justworks/justworks-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'JohnHancock',
    description:
      'John Hancock is a 401k provider. Integrate John Hancock with Uelco to sync 401k deductions automatically to Rippling\'s payroll.',
    category: 'Human Resources',
    tags: ['Human Resources', '401k', 'Payroll & Benefits'],
    url: 'https://www.johnhancock.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/johnhancock/johnhancock-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Workable',
    description:
      'Workable is flexible, global, easy-to-use hiring software. Available on desktop and mobile, and with 24/7 support worldwide, it\'s the modern way to hire.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Applicant Tracking'],
    url: 'https://www.workable.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/workable/workable-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Litmos',
    description:
      'Litmos offers a SaaS-based learning management system that enables trainers to merge media-rich content with seamless online delivery.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Learning Management System'],
    url: 'https://www.litmos.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/litmos/litmos-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Hivy',
    description:
      'Hivy is a task management tool that helps you to collect employee requests, manage projects, and create an happier office.',
    category: 'IT Management',
    tags: ['IT Management', 'Office Management'],
    url: 'https://www.hivy.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hivy/hivy-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Bluehost',
    description:
      'Bluehost is a hosting company that provides hosting solutions for websites, domain names, email, eCommerce, and SEO marketing.',
    category: 'Domain Registrar',
    tags: ['Domain Registrar', 'Developer'],
    url: 'https://www.bluehost.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/bluehost/bluehost-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Cirrus Insight',
    description:
      'Cirrus Insight is a customer relationship management application that integrates Salesforce with third party services, including Gmail and Microsoft\'s Office 365.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Sales Productivity'],
    url: 'https://www.cirrus insight.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/cirrus insight/cirrus insight-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AngelList',
    description:
      'AngelList is where the world meets startups. It helps you to find a great startup job, invest in a startup, or raise money.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Recruiting'],
    url: 'https://www.angellist.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/angellist/angellist-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Chase',
    description:
      'Chase is a multinational banking and financial service provider that offers credit cards, mortgages, auto loans, business banking, and commercial banking.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Banking'],
    url: 'https://www.chase.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/chase/chase-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Codacy',
    description:
      'Codacy is an automated quality and code analysis tool that helps developers save time in code reviews.',
    category: '',
    tags: [],
    url: 'https://www.codacy.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/codacy/codacy-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Coralogix',
    description:
      'Coralogix is a machine learning powered log analytics platform which drastically improves the delivery and maintenance process for software providers.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.coralogix.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/coralogix/coralogix-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Egencia',
    description:
      'Egencia elevates corporate travel programs and traveler satisfaction. ',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Travel Management'],
    url: 'https://www.egencia.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/egencia/egencia-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Figma',
    description:
      'Figma is the interface design tool based in the browser, making it easier for teams to create software.',
    category: 'Design',
    tags: ['Design', 'Collaboration'],
    url: 'https://www.figma.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/figma/figma-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Mural',
    description:
      'Mural is a digital workspace for visual collaboration, inspiration and innovation anytime, on any device.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.mural.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/mural/mural-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Officevibe',
    description:
      'Officevibe is a platform that designs employee pulse surveys to boost employee engagement within organizations.',
    category: 'Knowledge Base',
    tags: ['Knowledge Base'],
    url: 'https://www.officevibe.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/officevibe/officevibe-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Dashlane',
    description:
      'Dashlane Business helps you change poor password behaviour company-wide with powerful business password management. You can share password across teams.',
    category: 'Security / Backup',
    tags: ['Security / Backup'],
    url: 'https://www.dashlane.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/dashlane/dashlane-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Jira',
    description:
      'JIRA Software is built for every member of your software team to plan, track, and release great software. Quick note: Are you sure you\'re using Jira? Companies sometimes confuse Jira with Jira core. Jira is a version of Jira Core that includes a bunch of functionality for software engineers (such as agile development project tracking). Jira Core is missing the software engineering functionality and is aimed at non-engineering clients. If you\'re using Jira Core instead, you click back and install the "Jira Core" app in Uelco.',
    category: 'Collaboration',
    tags: ['Collaboration', 'Popular'],
    url: 'https://www.jira.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/jira/jira-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Rollbar',
    description: 'Error monitoring and debugging tool for developers.',
    category: 'Logs & Error Reporting',
    tags: ['Logs & Error Reporting', 'Developer'],
    url: 'https://www.rollbar.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/rollbar/rollbar-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AzureAD',
    description:
      'Azure Active Directory is a comprehensive identity and access management cloud solution that provides a robust set of capabilities to manage users and groups and help secure access to applications including Microsoft online services like Office 365 and a world of non-Microsoft SaaS applications.',
    category: 'IT Management',
    tags: ['IT Management', 'Org charts / directory', 'Email'],
    url: 'https://www.azuread.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/azuread/azuread-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ActiveDirectory',
    description:
      'Microsoft Active Directory is a collection of services that help you manage users and devices on a network. Microsoft Active Directory is used by Windows applications to manage access and enable single sign-on (SSO). <br/><br/>For example, you can manage access to Microsoft SharePoint using different Microsoft Active Directory security groups. You can create a group for administrators and another group for users with read-only access. You also can enable users to sign in to Microsoft SharePoint and other SSO-enabled Windows applications using a single set of Microsoft Active Directory credentials.',
    category: 'IT Management',
    tags: ['IT Management', 'Org charts / directory'],
    url: 'https://www.activedirectory.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/activedirectory/activedirectory-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SecurityIQ',
    description:
      'SecurityIQ combines a phishing simulator and computer-based security awareness training in one easy-to-use cloud-based service.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Training'],
    url: 'https://www.securityiq.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/securityiq/securityiq-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'GoodHire',
    description:
      'GoodHire is an employment screening provider working to ensure both companies and job candidates are treated fairly and with respect. Connect GoodHire with Uelco to run background checks with one click when hiring through Rippling.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Background Checks', 'Popular'],
    url: 'https://www.goodhire.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/goodhire/goodhire-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Bitly',
    description:
      'Bitly is a URL shortening service and a link management platform.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.bitly.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/bitly/bitly-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Tallie',
    description:
      'Tallie is cloud-based business expense report software that simplifies the entire expense management process from the point of purchase to accounting and reimbursement.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Expense Management'],
    url: 'https://www.tallie.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/tallie/tallie-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'StackOverflow',
    description:
      'Stack Overflow is a trusted online community for developers to learn, share​ ​their programming ​knowledge, and build their careers.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Learning Management System'],
    url: 'https://www.stackoverflow.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/stackoverflow/stackoverflow-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'TestRail',
    description:
      'TestRail is a test case and test management software tool that helps teams manage and track their software testing efforts.',
    category: 'Developer',
    tags: ['Developer', 'Test Management'],
    url: 'https://www.testrail.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/testrail/testrail-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Uber',
    description:
      'Uber is a ridesharing app that helps you find a better ways to move, work, and succeed.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Travel Management'],
    url: 'https://www.uber.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/uber/uber-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Camtasia',
    description:
      'Camtasia is a screen recorder and video editor. It helps you record your screen, add video effects, transitions and more.',
    category: 'Video Software',
    tags: ['Video Software', 'Developer'],
    url: 'https://www.camtasia.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/camtasia/camtasia-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Cazoomi',
    description:
      'Cazoomi helps you to Deploy, Integrate and Sync software applications for Financials, CRM and Marketing, eCommerce or Support for your company.',
    category: 'Sales & Marketing',
    tags: [
      'Sales & Marketing',
      'Automation',
      'Marketing Automation',
      'Developer',
    ],
    url: 'https://www.cazoomi.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/cazoomi/cazoomi-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'cleverbridge',
    description:
      'Cleverbridge is a ecommerce solutions company providing billing and payment solutions for monetizing digital goods and online services.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing', 'Marketing Automation'],
    url: 'https://www.cleverbridge.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/cleverbridge/cleverbridge-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'DataCamp',
    description:
      'DataCamp is a video tutorials platform that helps you to learn Data Science from the comfort of your browser. It provides videos and coding challenges on R, Python, Statistics & more.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Training', 'Learning Management System'],
    url: 'https://www.datacamp.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/datacamp/datacamp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Fastly',
    description:
      'Fastly\'s edge cloud platform helps the digital businesses keep pace with their customer expectations by delivering fast and secure.',
    category: 'Cloud Hosting',
    tags: ['Cloud Hosting', 'Developer'],
    url: 'https://www.fastly.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/fastly/fastly-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'FotorPro',
    description:
      'Fotor is a free online picture editor and graphic designer, allowing you to use online photo editing tools, such as add filters, frames, text, and stickers.',
    category: 'Design',
    tags: ['Design'],
    url: 'https://www.fotorpro.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/fotorpro/fotorpro-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Gemfury',
    description:
      'Gemfury is a hosted repository for your public and private packages, where they are safe and within reach. ',
    category: 'Developer',
    tags: ['Developer', 'Source Control'],
    url: 'https://www.gemfury.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/gemfury/gemfury-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Hotjar',
    description:
      'Hotjar helps you to see how visitors are really using your website, collect user feedback and turn more visitors into customers.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.hotjar.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hotjar/hotjar-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'IcoMoon',
    description:
      'IcoMoon provides a package of vector icons, along with a free HTML5 app for making custom icon fonts or SVG sprites.',
    category: 'Design',
    tags: ['Design'],
    url: 'https://www.icomoon.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/icomoon/icomoon-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Kastle',
    description:
      'Kastle Systems provides comprehensive, turnkey security solutions for premier sites across the United States and abroad.',
    category: 'Security / Backup',
    tags: ['Security / Backup'],
    url: 'https://www.kastle.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/kastle/kastle-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'LogiAnalytics',
    description:
      'Logi Analytics embeds business intelligence in your organization and products.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.logianalytics.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/logianalytics/logianalytics-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Nextiva',
    description:
      'Nextiva is a cloud-based business VoIP service provider that offers telephony services for small businesses with functionality that eliminates the need for a traditional phone system.',
    category: 'Phone systems',
    tags: ['Phone systems'],
    url: 'https://www.nextiva.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/nextiva/nextiva-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ngrok',
    description:
      'Ngrok is a multiplatform tunnelling, reverse proxy software that establishes secure tunnels from a public endpoint.',
    category: 'Developer',
    tags: ['Developer', 'Test Management'],
    url: 'https://www.ngrok.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/ngrok/ngrok-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'OptimalWorkshop',
    description:
      'Optimal Workshop offer a suite of tools to help design researchers and information architects improve the user experience of their products.',
    category: 'Design',
    tags: ['Design'],
    url: 'https://www.optimalworkshop.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/optimalworkshop/optimalworkshop-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'PlanningPoker',
    description:
      'Planning Poker makes agile estimating and planning fun, faster, and more efficient with our sprint planning tool.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.planningpoker.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/planningpoker/planningpoker-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'PredictiveIndex',
    description:
      'Predictive Index helps managers increase engagement through understanding their employees and learn how to refine your company, grow your team, and develop leaders.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.predictiveindex.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/predictiveindex/predictiveindex-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Infusionsoft',
    description:
      'Infusionsoft provides sales and marketing software for small businesses that combines email marketing and e-commerce.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM', 'Marketing Automation'],
    url: 'https://www.infusionsoft.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/infusionsoft/infusionsoft-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Highfive',
    description:
      'Highfive simplifies business collaboration with video conferencing technology designed to make meetings easier with no cables, dongles, or app downloads.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Virtual Meetings'],
    url: 'https://www.highfive.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/highfive/highfive-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Prismic',
    description:
      'Prismic.IO is an API-based CMS system that manages and publish content into websites.',
    category: 'Content Management',
    tags: ['Content Management', 'Cloud Hosting', 'Developer'],
    url: 'https://www.prismic.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/prismic/prismic-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Proto',
    description:
      'Using Proto.IO you can create fully-interactive high-fidelity prototypes that look real and work exactly like your app should without requiring any coding.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.proto.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/proto/proto-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SemaphoreCI',
    description:
      'Semaphore CI is a hosted continuous integration and deployment service for open source and private projects.',
    category: 'Developer',
    tags: ['Developer', 'Test Management'],
    url: 'https://www.semaphoreci.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/semaphoreci/semaphoreci-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SimilarWeb',
    description:
      'SimilarWeb provides innovative market intelligence solutions and empowers businesses to make better decisions by doing intelligent market research, suggesting better sales pitch, enhancing SEO and more. \n\n',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Sales Productivity', 'Marketing', 'SEO'],
    url: 'https://www.similarweb.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/similarweb/similarweb-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Speechpad',
    description:
      'Speechpad provides transcription services for captioning video, including subtitles and closed captions.',
    category: 'Video Software',
    tags: ['Video Software', 'Developer'],
    url: 'https://www.speechpad.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/speechpad/speechpad-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Tailwind',
    description:
      'Tailwind is a social media visual marketing tool that provides Pinterest and Instagram Analytics, Management and Intelligent Marketing.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing', 'Social Media'],
    url: 'https://www.tailwind.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/tailwind/tailwind-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Teem',
    description:
      'Teem is a cloud-based virtual meeting platform that makes it easy to meet and book conference rooms, to efficiently manage workspaces and meeting resources, and to measure and analyze your company’s meeting and collaboration behavior.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Virtual Meetings'],
    url: 'https://www.teem.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/teem/teem-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Wootric',
    description:
      'Wootric helps you can boost customer happiness by using Net Promoter Score (NPS), CSAT, and Customer Effort Score (CES) surveys. Also, you can get user feedback online, in-app, via mobile, email & SMS.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM', 'Analytics & BI', 'Surveys'],
    url: 'https://www.wootric.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/wootric/wootric-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Procore',
    description:
      'Procore is all-in-one construction management software built to help you finish quality projects —- safely, on time, and within budget.',
    category: 'Construction Software',
    tags: ['Construction Software'],
    url: 'https://www.procore.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/procore/procore-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Aircall',
    description:
      'Aircall is an advanced phone systems software to manage business phone lines and your call center.',
    category: 'Phone systems',
    tags: ['Phone systems'],
    url: 'https://www.aircall.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/aircall/aircall-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Hr360',
    description:
      'HR360 is a SaaS platform that provides comprehensive, easy-to-navigate human resource and benefits compliance, guidance, and news on both federal and state HR laws. It delivers the information, tools and forms needed to stay compliant and provides a great range of online tools including a job description developer and salary benchmarking tool to help manage HR needs.',
    category: 'Knowledge Base',
    tags: ['Knowledge Base'],
    url: 'https://www.hr360.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hr360/hr360-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'DocumentsApp',
    description: 'Track all documents status in one place.',
    category: '',
    tags: [],
    url: 'https://www.documentsapp.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/documentsapp/documentsapp-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Adobe',
    description:
      'Adobe has suite of products that makes it faster and easier than ever to create and distribute digital media.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.adobe.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/adobe/adobe-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'JazzHR',
    description:
      'JazzHR is easy, powerful and affordable recruiting software for SMBs.',
    category: 'Human Resources',
    tags: ['Human Resources', 'Applicant Tracking'],
    url: 'https://www.jazzhr.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/jazzhr/jazzhr-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ActOn',
    description:
      'Act-On is an integrated marketing automation platform designed to align inbound and outbound marketing campaigns across the customer lifecycle.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'Marketing Automation'],
    url: 'https://www.acton.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/acton/acton-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'AdamPhones ',
    description:
      'Adam Phones is one of the providers of intelligent, integrated mobile & fixed line communications.',
    category: 'Phone systems',
    tags: ['Phone systems'],
    url: 'https://www.adamphones .com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/adamphones /adamphones -ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'BankoftheWest',
    description:
      'Bank of the West is the regional financial services company that offers a variety of services including checking and savings accounts, credit cards, auto, home and personal loans.',
    category: 'Finance & Accounting',
    tags: ['Finance & Accounting', 'Banking'],
    url: 'https://www.bankofthewest.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/bankofthewest/bankofthewest-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Boxed',
    description:
      'Boxed is a online and mobile membership-free wholesale retailer that offers direct delivery of bulk-sized packages and products such as groceries, household products, and health supplies.',
    category: '',
    tags: [],
    url: 'https://www.boxed.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/boxed/boxed-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Physical Hardware',
    description: 'Hardware leasing and purchasing',
    category: '',
    tags: [],
    url: 'https://www.physical hardware.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/physical hardware/physical hardware-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'BlueStar',
    description:
      'BlueStar is a 401k provider. Integrate BlueStar with Uelco to sync 401k deductions automatically to Rippling\'s payroll.',
    category: 'Human Resources',
    tags: ['Human Resources', '401k', 'Payroll & Benefits'],
    url: 'https://www.bluestar.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/bluestar/bluestar-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Empower',
    description:
      'Empower is a 401k provider. Integrate Empower with Uelco to sync 401k deductions automatically to Rippling\'s payroll.',
    category: 'Human Resources',
    tags: ['Human Resources', '401k', 'Payroll & Benefits'],
    url: 'https://www.empower.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/empower/empower-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'CallTrackingMetrics',
    description:
      'Call Tracking Metrics is a software that provides call tracking tools and analysis to organisations. ',
    category: 'Phone systems',
    tags: ['Phone systems'],
    url: 'https://www.calltrackingmetrics.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/calltrackingmetrics/calltrackingmetrics-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'CisionPoint',
    description:
      'Cision Point is a fully integrated, on-demand PR solution. It helps to gather information about various media outlets, reporters, etc. ',
    category: '',
    tags: [],
    url: 'https://www.cisionpoint.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/cisionpoint/cisionpoint-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'CitrixRightSignature',
    description:
      'Citrix RightSignature is a web and mobile e-signature service provider. It helps you to get the e-signatures in the easiest and fastest way.',
    category: '',
    tags: [],
    url: 'https://www.citrixrightsignature.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/citrixrightsignature/citrixrightsignature-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ClipperCard',
    description:
      'Clipper card is a reloadable contactless smart card used for electronic transit fare payment.',
    category: '',
    tags: [],
    url: 'https://www.clippercard.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/clippercard/clippercard-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'CN',
    description:
      'CN Transportation Services is a North American transportation services industry focused on supply chain innovation and collaboration. ',
    category: 'Shipping',
    tags: ['Shipping'],
    url: 'https://www.cn.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/cn/cn-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'ConnectWise',
    description:
      'ConnectWise empowers technology solution providers to reach their goals faster with an award-winning business management software suite & much more.\n',
    category: 'Customer Support',
    tags: ['Customer Support'],
    url: 'https://www.connectwise.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/connectwise/connectwise-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Copper',
    description:
      'Copper is a CRM that simplifies work processes as an affordable and invaluable executive tool.',
    category: 'Sales & Marketing',
    tags: ['Sales & Marketing', 'CRM'],
    url: 'https://www.copper.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/copper/copper-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Doodle',
    description:
      'Doodle radically simplifies the process of scheduling events, meetings, appointments, etc. ',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.doodle.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/doodle/doodle-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'FengOffice ',
    description:
      'Feng Office is a commercial Open Source web based solution aimed to enhance collaboration and productivity.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.fengoffice .com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/fengoffice /fengoffice -ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Trestle',
    description: 'TODO need a description here.',
    category: 'Onboarding',
    tags: ['Onboarding', 'Human Resources'],
    url: 'https://www.trestle.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/trestle/trestle-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'BambooHR',
    description:
      'BambooHR is a company that provides human resources software as a service. BambooHR\'s services include applicant tracking system and an employee benefits tracker.',
    category: 'Applicant Tracking',
    tags: ['Applicant Tracking'],
    url: 'https://www.bamboohr.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/bamboohr/bamboohr-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Gorkana',
    description:
      'Gorkana is an integrated media planning, monitoring and analysis service provider that gives you seamless access to even richer media intelligence and insights.',
    category: 'PR Software',
    tags: ['PR Software'],
    url: 'https://www.gorkana.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/gorkana/gorkana-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'PRNewswire',
    description:
      'Cision PR Newswire is a news distribution, targeting, monitoring and marketing solution that helps you connect and engage with target audiences across the globe.',
    category: 'PR Software',
    tags: ['PR Software'],
    url: 'https://www.prnewswire.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/prnewswire/prnewswire-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Meltwater',
    description:
      'Meltwater is a media intelligence company that provides media monitoring and social media monitoring to help companies grow and build brand.',
    category: 'PR Software',
    tags: ['PR Software'],
    url: 'https://www.meltwater.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/meltwater/meltwater-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'SproutSocial',
    description:
      'Sprout Social is a social media management software that provides publishing, engagement, analytics and team collaboration tools.',
    category: 'Social Media',
    tags: ['Social Media'],
    url: 'https://www.sproutsocial.com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/sproutsocial/sproutsocial-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Broadview',
    description:
      'Broadview unifies your communications and collaboration services, employees and locations with one advanced office phone system that improves every employee\'s workflow and productivity. ',
    category: 'Phone systems',
    tags: ['Phone systems'],
    url: 'https://www.broadview.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/broadview/broadview-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Pacer',
    description:
      'Public Access to Court Electronic Records (PACER) is an electronic public access service that allows users to obtain case and docket information online from federal appellate, district, and bankruptcy courts, and the PACER Case Locator. PACER is provided by the Federal Judiciary in keeping with its commitment to providing public access to court information via a centralized service.',
    category: 'Legal Software',
    tags: ['Legal Software'],
    url: 'https://www.pacer.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/pacer/pacer-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Caviar',
    description: 'Caviar provides door step food delivery service.',
    category: 'Catering',
    tags: ['Catering'],
    url: 'https://www.caviar.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/caviar/caviar-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'FotoIN',
    description:
      'FotoIN automates business photo documentation with an Easy, Actionable and Open mobile solution and patent pending technology.',
    category: '',
    tags: [],
    url: 'https://www.fotoin.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/fotoin/fotoin-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Dashboard',
    description: 'Dashboard is a powerful lead collection and management tool.',
    category: '',
    tags: [],
    url: 'https://www.dashboard.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/dashboard/dashboard-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'D&BHoovers',
    description:
      'D&B Hoovers is a business research company that provides information on companies and industries',
    category: '',
    tags: [],
    url: 'https://www.d&bhoovers.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/d&bhoovers/d&bhoovers-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'HSBConnect',
    description:
      'HSB Connect provides inspection service customers with online access to inspection reports and management information.',
    category: '',
    tags: [],
    url: 'https://www.hsbconnect.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/hsbconnect/hsbconnect-ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'IBMConnections ',
    description:
      'IBM Connections is a collaboration platform that integrates email, activity and task management, instant messaging, file sharing, collaborative document editing and more into a unified solution.',
    category: 'Collaboration',
    tags: ['Collaboration'],
    url: 'https://www.ibmconnections .com',
    companyLink: '',
    logoUrl:
      'https://www.vectorlogo.zone/logos/ibmconnections /ibmconnections -ar21.svg',
    logoIcon: '',
  },
  {
    priority: 0,
    name: 'Amplitude',
    description:
      'Amplitude is a comprehensive product analytics software for web and mobile.',
    category: 'Analytics & BI',
    tags: ['Analytics & BI'],
    url: 'https://www.amplitude.com',
    companyLink: '',
    logoUrl: 'https://www.vectorlogo.zone/logos/amplitude/amplitude-ar21.svg',
    logoIcon: '',
  },
]
