











import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class MmebersFilter extends Vue {

  get roleFilters() {
    return [{
      role: '',
      label: 'All',
    }, {
      role: 'candidate',
      label: 'Candidates',
    }, {
      role: 'hire',
      label: 'New hires',
    }, {
      role: 'member',
      label: 'Members',
    }]
  }

  get filter() {
    return this.$store.state.team.filter
  }

  public isActive(role: string) {
    return this.filter.role === role
  }

  public setRoleFilter(role: string) {
    this.$store.commit('team/setFilter', {
      role,
    })
  }
}
