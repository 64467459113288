




































import { Component, Prop } from 'vue-property-decorator'
import { Document, Role, rolesList } from '@/models'
import ItemMenu from '@/components/common/ItemMenu.vue'
import View from '../../View'

@Component({
  components: {
    ItemMenu,
  },
})
export default class GridDocument extends View {
  @Prop({ type: Object }) public document: Document

  get rolesList() {
    return rolesList
  }

  get extraMenuItems() {
    return this.isAdmin
      ? [
        {
          action: 'download',
          label: 'Download',
        },
        {
          action: 'remove',
          label: 'Remove',
        },
      ]
      : []
  }

  public async handleAction(cmdType: string) {
    switch (cmdType) {
    case 'view':
      this.$router.push({
        name: 'document-view',
        params: { id: `${this.document.documentId}` },
      })
      break
    case 'download':
      window.open(this.document.link)
      break
    case 'remove':
      // this.$store.dispatch('document/remove', this.document)
      await Document.softDelete(
        this.document.documentId,
        `"${this.document.name}" was removed`,
      )
      break
    case 'edit':
      this.$router.push({
        name: 'document-edit',
        params: { id: `${this.document.documentId}` },
      })
      break
    }
  }

  public isVisibleTo(role: Role) {
    return this.document.visibility.indexOf(role) !== -1
  }

  public isInvisible() {
    return this.document.visibility.length === 0
  }

  public toggleVisibility(role: Role) {
    if (!this.isAdmin) {
      return false
    }

    const visibility = [ ...this.document.visibility ]
    const idx = visibility.indexOf(role)
    if (idx > -1) {
      visibility.splice(idx, 1)
    } else {
      visibility.push(role)
    }

    Document.api().$update({
      params: { id: this.document.documentId},
      data: { visibility },
    })
  }
}
