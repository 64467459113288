import { Dict } from '../models'

export const socialNetworks = [
  'linkedin',
  'instagram',
  'github',
  'angellist',
  'facebook',
  'twitter',
  'dribbble',
  'stackoverflow',
  'glassdoor',
  'medium',
]

export const companySocialNetworks = [
  'Web',
  'LinkedIn',
  'Glassdoor',
  'AngelList',
  'StackOverflow',
  'Github',
  'Instagram',
  'Dribbble',
  'Twitter',
  'Facebook',
  'Medium',
]

export const socialNetworkDomains: Dict<string> = {
  linkedin: 'https://www.linkedin.com/in/',
  instagram: 'https://www.instagram.com/',
  github: 'https://github.com/',
  angellist: 'https://angel.co/company/',
  facebook: 'https://web.facebook.com/',
  twitter: 'https://twitter.com/',
  dribbble: 'https://dribbble.com/',
  stackoverflow: 'https://stackoverflow.com/users/',
  medium: 'https://medium.com/@'
}
