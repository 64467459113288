













import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class AdminActions extends Vue {
  get isAdmin() {
    return this.$store.getters['auth/isAdmin']
  }

}
