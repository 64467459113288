












import { Component, Vue } from 'vue-property-decorator'
import { Role } from '@/models'

@Component({
  props: {
    layout: {
      type: String,
      default: '',
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
  },
})
export default class RoleSwitch extends Vue {
  public layout: string
  public allowEmpty: boolean

  get roleSwitch() {
    return this.$store.state.ui.roleSwitch
  }

  get editorActive() {
    return this.$store.state.ui.editorActive
  }

  public isInactive(role: Role) {
    return this.roleSwitch !== role
  }

  public setRole(role: Role) {
    if (this.editorActive) {
      return true
    }
    if (this.allowEmpty && this.roleSwitch === role) {
      this.$store.commit('ui/setRoleSwitch', '')
    } else {
      this.$store.commit('ui/setRoleSwitch', role)
    }
  }
}
