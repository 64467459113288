









import { Component, Vue } from 'vue-property-decorator'
import Feedback from '@/components/common/Feedback.vue'
import Help from '@/components/common/Help.vue'

@Component({
  components: {
    Feedback,
    Help,
  },
})
export default class AdminFooter extends Vue {
  public helpVisible: boolean

  get isAdmin() {
    return this.$store.getters['auth/isAdmin']
  }

  get showHelpbox() {
    return this.$store.state.ui.helpbox
  }

  public openHelpbox() {
    this.$store.commit('ui/toggleHelpbox', true)
  }

  public closeHelpbox() {
    this.$store.commit('ui/toggleHelpbox', false)
  }
}
