import { socialNetworkDomains } from '../data/social-networks'

export default function socialLink(value: string, network: string) {
  if (!value || typeof value !== 'string') {
    return value
  }

  const domain = socialNetworkDomains[network.toLowerCase()]

  if (domain && !value.match(/^http:/) && !value.includes(domain)) {
    return `${domain}${value}`
  }

  if (!value.match(/^https?:/)) {
    return `https://${value}`
  }

  return value
}
