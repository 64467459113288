
import { Component } from 'vue-property-decorator'

import View from '@/View'
import { TextBlockPageRef } from '@/models'

@Component
export default class EditableTextBlocksMixin extends View {
  protected pageRef: TextBlockPageRef = 'company'

  get textBlocks() {
    return this.textBlocksByRef(this.pageRef)
  }

  get canRemoveTextBlocks() {
    return this.textBlocks.length > 1
  }
}
