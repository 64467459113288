



















import { Component } from 'vue-property-decorator'
import { Setting } from '@/models'
import View from '@/View'
import faker from '@/faker'
import { Notification } from '../../models'

interface TodoItem {
  id: string
  title: string
}

const defaultTodoSet: TodoItem[] = [
  {
    id: faker.random.uuid(),
    title: 'Read about our company 📖',
  },
  {
    id: faker.random.uuid(),
    title: 'Meet the team 👨🏼‍💼👩🏼‍🔧',
  },
  {
    id: faker.random.uuid(),
    title: 'Introduce yourself',
  },
  {
    id: faker.random.uuid(),
    title: 'See what applications we use 💻',
  },
  {
    id: faker.random.uuid(),
    title: 'Have fun 🍩🍺👻',
  },
]

@Component({})
export default class TodoTemplateSettings extends View {
  get setting() {
    let setting = this.getSetting('todos-hire')

    if (!setting) {
      setting = new Setting({
        key: 'todos-hire',
        value: defaultTodoSet,
        companyId: this.$store.getters['auth/companyId'],
      })
    }

    return setting
  }

  get items() {
    const { setting } = this
    return setting.value
  }

  public mounted() {
    this.$store.commit('ui/setMobilePreviewPage', 'AppTodo')
  }

  public async addItem(event: any) {
    event.preventDefault = true
    this.setting.value.push({ id: faker.random.uuid(), title: 'New' })
    await this.setting.$persist()
  }

  public async removeItem(item: TodoItem) {
    const { setting } = this
    const idx = setting.value.findIndex((row: TodoItem) => row.id === item.id)

    if (idx > -1) {
      const removedRow = { ...item }

      Notification.add('warning', `${item.title} removed`, {
        title: 'Undo',
        idx,
        entity: null,
        callback: () => {
          this.setting.value.splice(idx, 0, removedRow)
          this.setting.$persist()
        },
      })

      setting.value.splice(idx, 1)
      await setting.$persist()
    }
  }

  public async syncValues() {
    await this.setting.$persist()
  }
}
